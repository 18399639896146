export const type_roles = {
    "DRIVER": "Driver",
    "ADMIN": "SUPERADMIN",
    "VIEWER": "Event official",
    "DIRECTOR": "Clerk of course",
    "EVENT_PLANNER": "Event organizer"
};

export const Flag_Codes = {
    "RED_FLAG": "800",
    "CUSTOM_FLAG": "802"
}

export const InterestPoints_Icons = {
    "hotel": "icon-hotel",
    "fire": "icon-fire-ext",
    "asterisk": "icon-medic",
    "location": "icon-location-on",
    "gasstatiom": "icon-gas-station",
    "radio":"icon-radio",
    "time": "icon-time",
    "startFlag":"icon-start",
    "finish":"icon-finish"
}

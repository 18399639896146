import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { useHistory } from "react-router-dom";
import { Utils } from "../utilities/Utils";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogNoAuthorizedSet } from "../dialogs/ErrorDialog";
import { LicenseService } from "../service/LicenseService";

export const Licensing = () => {
    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
    const [loading, setLoading] = useState(true);

    const [licenses, setLicenses] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();

    // Se ejecuta luego del render
    useEffect(() => {
        try {
            const load = async () => {
                setLoading(true);

                const licenseService = new LicenseService();
                const res = await licenseService.getAll();

                for (let i = 0; i < res.length; i++) {
                    var start = new Date(res[i].From);
                    var finish = new Date(res[i].To);
                   
                    let startDate = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate()); 
                    let finishDate = new Date(finish.getUTCFullYear(), finish.getUTCMonth(), finish.getUTCDate()); 

                    res[i].From = startDate;
                    res[i].To = finishDate;     
                }

                setLicenses(res);

                setLoading(false);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
            console.log(error);
        }
    }, []);
    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    // #region Templatedatatable
    // const onErrorFind = (e) => {
    //     if (e) return e.Description;
    //     else return "error";
    // };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title txt-bold">{rowData.Name}</span>
            </>
        );
    };

    const licensesGivenBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.Given}</span>
            </>
        );
    };

    const licensePeriodBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{Utils.dateDDMMYY(rowData.From) + " to " + Utils.dateDDMMYY(rowData.To)}</span>
            </>
        );
    };

    const eventsInPeriodBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.Events}</span>
            </>
        );
    };
    const openClub = (lic) => {
        history.push("/club/" + lic.ClubId);
    };

    // #endregion

    const header = (
        <div className="table-header p-mb-5">
            <h1 className="p-m-0 fs-x-normal">Licensing</h1>
            <div>
                <span className="p-input-icon-right p-mr-4">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search License" />
                </span>
            </div>
        </div>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-angle-right" className="p-mr-1" onClick={() => openClub(rowData)} />
            </div>
        );
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card ">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        value={licenses}
                        // selection={selectedClubs}
                        // onSelectionChange={(e) => setSelectedClubs(e.value)}
                        dataKey="Id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive table-layout-auto"
                        // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Licenses"
                        globalFilter={globalFilter}
                        emptyMessage="No Licenses found."
                        header={header}
                    >
                        {/* <Column selectionMode="multiple" className="is-first" /> */}
                        <Column field="Name" header="NAME" sortable body={nameBodyTemplate} />
                        <Column field="Given" header="LICENSES GIVEN" body={licensesGivenBodyTemplate} headerClassName="txt-center" bodyClassName="txt-right" sortable />
                        <Column field="From" header="LICENSING PERIOD" body={licensePeriodBodyTemplate} headerClassName="txt-center" bodyClassName="txt-center" sortable />
                        <Column field="To" header="EVENTS IN THIS PERIOD" body={eventsInPeriodBodyTemplate} headerClassName="txt-center" bodyClassName="txt-right" sortable />

                        <Column body={actionBodyTemplate} className="is-last" />
                    </DataTable>

                    <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
                </div>
            </div>
        </div>
    );
};

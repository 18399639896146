import { Button } from "primereact/button";

export const NewPoiMenu = (props) => {
    return (
        <div className="menu-edit-map p-jc-between">
            <div className="p-d-flex p-ai-center">
                <Button icon="pi pi-chevron-left" className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3" onClick={props.back} />
                <p className="fs-x-normal  p-mr-3"> New POI</p>
                <p className="fs-medium txt-clrLight150 p-mr-5">{props.coordatesText || "No coordinates available"}</p>

                {/*  <Button icon="pi pi-map-marker" className="p-button-rounded p-rounded-small  p-button-outlined p-button-icon-only p-mr-3" onClick={() => alert("Programar")} />
                <Button icon="pi pi-plus" className="p-button-rounded  p-rounded-small p-button-outlined p-button-icon-only" onClick={() => alert("Programar")} /> */}
            </div>

            <div className="p-d-flex p-ai-center">
                {/*<Button label="Exit" className="btn-outline-invertp-mr-2" onClick={() => alert("Back to event detail")} />*/}
                <Button label="Save changes" className="btn-primary-invert " onClick={props.saveChanges} />
            </div>
        </div>
    );
};

import React from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { Button } from "primereact/button";
import { POI_VALUES, SVG } from "../consts/icons";
import { GoogleMapTool } from "./GoogleMapTool";

var editable = true;

export class NewPoi {
    eventId;
    mapService;
    setPolylineTags;
    setInfoWindowsTags;
    setPoiTags;
    setPoiValues;
    setPoiModalState;
    poiValues;
    poiDetails;
    setInfoIndex;
    infoIndex;
    setPoiDetails;
    funBack;
    showToast;
    mapTools;

    constructor(eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues, setPoiModalState, poiValues, poiDetails, setInfoIndex, infoIndex, setPoiDetails, showToast, back) {
        this.eventId = eventId;
        this.mapService = mapService;
        this.setPolylineTags = setPolylineTags;
        this.setInfoWindowsTags = setInfoWindowsTags;
        this.setPoiTags = setPoiTags;
        this.setPoiValues = setPoiValues;
        this.setPoiModalState = setPoiModalState;
        this.poiValues = poiValues;
        this.poiDetails = poiDetails;
        this.setInfoIndex = setInfoIndex;
        this.infoIndex = infoIndex;
        this.setPoiDetails = setPoiDetails;
        this.funBack = back;
        this.showToast = showToast;
        this.mapTools = new GoogleMapTool();
    }
    poiFooter = (
        <div>
            <Button label="Cancel" className="btn-secondary " onClick={() => this.setPoiModalState(false)} />
            <Button label="Save" className="btn-primary" onClick={(e) => this.updatePoiData(e)} />
        </div>
    );

    async initializePois() {
        try {
            var response = await this.mapService.getPoisByEventId(this.eventId);
            var pois = [];
            response.data.pois.forEach((item, index) => {
                var data = {
                    lat: parseFloat(item.Lat),
                    lng: parseFloat(item.Long),
                    index,
                    isOpen: false,
                    input: React.createRef(),
                    description: item.Description,
                    id: item.Id,
                    icon: item.Icon,
                };
                pois.push(data);
            });
            this.buildPois(pois);
        } catch (error) {
            this.showToast(``, "An error has occurred trying to get the pois", "error");
            console.log(error);
        }
    }

    updatePoiData(e) {
        this.setPoiModalState(true);
        var poi = this.poiValues.find((item) => item.index == this.poiDetails.index);
        poi.lat = parseFloat(this.poiDetails.latInput.current.value);
        poi.lng = parseFloat(this.poiDetails.lngInput.current.value);
        poi.description = this.poiDetails.descriptionInput.current.value;
        poi.icon = this.poiDetails.icon;
        this.buildPois(this.poiValues);
        this.setPoiModalState(false);
    }

    handlerClickEvent(e, circuits) {
        var index = this.infoIndex + 1;
        if (editable) {
            let points = this.getTheCorrectPoints(circuits, e.latLng);
            var data = [
                ...this.poiValues,
                {
                    lat: points.lat,
                    lng: points.lng,
                    index,
                    isOpen: false,
                    input: React.createRef(),
                    description: "New/edit POI",
                    icon: null,
                },
            ];
            this.buildPois(data);
            this.setInfoIndex(this.infoIndex + 1);
        }
    }

    getTheCorrectPoints(circuits, latLng) {
        let points = { lat: latLng.lat(), lng: latLng.lng() };
        circuits.forEach((circuit) => {
            let positions = circuit.Positions;
            let firstPoint = { lat: parseFloat(positions[0].lat), lng: parseFloat(positions[0].lng) };
            let lastPoint = { lat: parseFloat(positions[positions.length - 1].lat), lng: parseFloat(positions[positions.length - 1].lng) };

            if (this.mapTools.isSamePosition(points, firstPoint)) {
                points = { lat: parseFloat(positions[0].lat), lng: parseFloat(positions[0].lng) };
            } else if (this.mapTools.isSamePosition(points, lastPoint)) {
                points = lastPoint;
            }
        });

        return points;
    }

    openModal(poi) {
        this.setPoiDetails({
            index: poi.index,
            lat: poi.lat,
            lng: poi.lng,
            description: poi.description,
            latInput: React.createRef(),
            lngInput: React.createRef(),
            descriptionInput: React.createRef(),
            icon: poi.Icon,
        });
        this.setPoiModalState(true);
    }

    async deletePoi(pois, poi) {
        var positions = [...pois];
        if (poi.id) {
            try {
                await this.mapService.deletePoiById(poi.id);
                this.showToast(`DELETED POI`, "The poi has been deleted", "success");

                positions.splice(poi.index, 1);
                this.buildPois(positions);
            } catch (error) {
                this.showToast(``, "An error has occurred trying to delete the poi", "error");
            }
        } else {
            positions.splice(poi.index, 1);
            this.buildPois(positions);
        }
    }

    insert() {
        editable = true;
        this.buildPois([...this.poiValues]);
    }

    select() {
        editable = false;
        this.buildPois([...this.poiValues]);
    }

    exit() {}

    async saveChanges() {
        var pois = [];
        this.poiValues.forEach((item) => {
            pois.push({
                lat: item.lat,
                lng: item.lng,
                id: item.id || null,
                description: item.description,
                icon: item.icon,
            });
        });

        try {
            var response = await this.mapService.savePois({ eventId: this.eventId, pois: pois });
            return { status: true, message: "Pois were saved" };
        } catch (error) {
            console.log(error);
            return { status: false, message: "An error has occurred, please try again" };
        }
    }

    getSvgPath(icon) {
        try {
            return SVG.find((poi) => poi.Description == icon).Path;
        } catch (error) {
            return SVG.find((poi) => poi.Description == POI_VALUES.LOCATION).Path;
        }
    }

    buildPois(pois) {
        const listItems = pois.map((poi, index) => {
            poi.index = index;

            const icon = {
                url: this.getSvgPath(poi.icon),
                //fillOpacity: 1,
                //strokeWeight: 0,
                //rotation: 180,
                //scale: 0.02,
            };

            return (
                <Marker
                    icon={icon}
                    position={{ lat: poi.lat, lng: poi.lng }}
                    clickable={true}
                    draggable={false}
                    key={index}
                    editable={editable}
                    draggable={editable}
                    onClick={() => {
                        poi.isOpen = !poi.isOpen;
                        this.buildPois(pois);
                    }}
                    onDblClick={() => {}}
                    onDragEnd={(e) => {
                        poi.lat = e.latLng.lat();
                        poi.lng = e.latLng.lng();
                        this.buildPois(pois);
                    }}
                >
                    {poi.isOpen && (
                        <InfoWindow
                            key={index}
                            position={{ lat: poi.lat, lng: poi.lng }}
                            onCloseClick={() => {
                                poi.isOpen = !poi.isOpen;
                                this.buildPois(pois);
                            }}
                        >
                            <div className="info-window-body">
                                {
                                    <>
                                        <div className="p-d-flex  p-jc-between p-ai-center p-mb-2">
                                            <p className="fs-normal txt-bold"> {poi.description} </p>

                                            {editable && (
                                                <div>
                                                    <Button icon="pi pi-pencil" className="info-window-edit-btn" onClick={() => this.openModal(poi)} />
                                                    <Button icon="pi pi-trash" className="info-window-edit-btn" onClick={() => this.deletePoi(pois, poi)} />
                                                </div>
                                            )}
                                        </div>
                                        <p className="fs-small">
                                            {parseFloat(poi.lat).toFixed(5)}, {parseFloat(poi.lng).toFixed(5)}
                                        </p>
                                    </>
                                }
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            );
        });
        this.setPoiTags(listItems);
        this.setPoiValues(pois);
    }
}

import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

export const DrawingMenu = (props) => {
    const importpolyline = async ({ files }) => {
        // try {
        // const eventService = new EventService();
        // var currFile = files[0];
        // await eventService.updloadPolyline(currFile, params.eveId);

        props.importpolyline(files[0]);

        // } catch (error) {
        //     let errormessage = "";
        //     if (error.response) {
        //         errormessage = error.response.data.message;
        //     }
        //     setStateErrorDialog(ErrorDialogSet(true, errormessage));
        // }
    };

    return (
        <div className="menu-edit-map  p-jc-between">
            <div className="p-d-flex p-ai-center">
                <Button icon="pi pi-chevron-left" className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3" onClick={props.backToPreviousPage} />
                <Button className="btn-outline-invert p-mr-2" icon="pi pi-plus" label="Select" onClick={props.select} />
                <Button className="btn-outline-invert p-mr-2" icon="pi pi-map-marker" label="New/edit POI" onClick={props.newpoi} />
                <Button className="btn-outline-invert p-mr-2" icon="pi pi-pencil" label="New Polyline" onClick={props.newpolyline} />
                <Button className="btn-outline-invert p-mr-2" icon="pi pi-globe" label="Save Map Position" onClick={props.setMapPosition} />
                {/* <Button className="btn-outline-invert" icon="pi pi-sign-in" label="Import polyline" onClick={props.importpolyline} /> */}
                <FileUpload
                    // onUpload={importCSV}
                    uploadHandler={importpolyline}
                    customUpload={true}
                    mode="basic"
                    auto={true}
                    label="Import polyline"
                    chooseLabel="Import polyline"
                    className="p-mr-2 p-d-inline-block btn-primary-outline "
                />
            </div>

            <div className="p-d-flex p-ai-center">{/*<Button label="Save changes" className="btn-primary-invert " onClick={() => alert("Save Changes")} />*/}</div>
        </div>
    );
};

import { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { TokenAuthService } from "../service/TokenAuthService";
import { UserService } from "../service/UserService";

import { LoginModel } from "../model/LoginModel";
import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse } from "../dialogs/ErrorDialog";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";

export const Login = (props) => {
    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const toast = useRef(null);
    const [loading, setLoading] = useState(true);

    // const [visibleLogin, setVisibleLogin] = useState(true);
    const [visibleChangePassword, setVisibleChangePassword] = useState(false);

    useEffect(() => {
        try {
            const load = async () => {
                setLoading(true);

                setVisibleChangePassword(false);

                setLoading(false);
            };
            load();
        } catch (error) {
            alert(error);
        }
    }, [props]);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    function handleLoginClick() {
        let tokenAuthService = new TokenAuthService();
        let loginModel = new LoginModel(username, password);

        tokenAuthService
            .authenticate(loginModel)
            .then((data) => {
                if (data.changepassword == true) {
                    setUserId(data.userId);
                    setVisibleChangePassword(true);
                } else {
                    hideDialog();
                }
            })
            .catch((error) => {
                let errormessage = "Invalid start session";
                if (error.response) {
                    errormessage = error.response.data.message;
                }
                setStateErrorDialog(ErrorDialogSet(true, errormessage));
            });
    }
    async function handleChangePasswordClick() {
        try {
            if (newPassword == confirmPassword) {
                let userService = new UserService();
                await userService.updatePassword(userId, newPassword).catch((res) => setStateErrorDialog(ErrorDialogSetResponse(res)));
                toast.current.show({ severity: "success", summary: "Successful", detail: "Password update", life: 3000 });
                setVisibleChangePassword(false);
            } else {
                setStateErrorDialog(ErrorDialogSet(true, "The confirm password does not match"));
            }
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, error.message));
        }
    }

    const hideDialog = () => {
        if (visibleChangePassword) {
            let tokenAuthService = new TokenAuthService();
            tokenAuthService.logout();
        }
        props.onClose();
    };

    const dialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={hideDialog} />
            {!visibleChangePassword && <Button label="Ok" className="btn-primary" onClick={handleLoginClick} />}

            {visibleChangePassword && <Button label="Ok" className="btn-primary" onClick={handleChangePasswordClick} />}
        </>
    );

    return (
        <>
            <Dialog style={{ width: "450px" }} header={visibleChangePassword ? "First Access. Change Password" : "Login"} modal className="p-fluid" footer={dialogFooter} visible={props.visible} onHide={hideDialog}>
                <Toast ref={toast} />
                {!visibleChangePassword && (
                    <div>
                        <div className="p-field">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText type="text" id="inputgroup" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    <label htmlFor="inputgroup">Email</label>
                                </span>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="p-field">
                            <span className="p-float-label">
                                <Password toggleMask="true" icon="pi pi-plus" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <label htmlFor="password">Password</label>
                            </span>
                        </div>
                    </div>
                )}

                {visibleChangePassword && (
                    <div>
                        <div className="p-field">
                            <span className="p-float-label">
                                <InputText type="password" id="newpassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                <label htmlFor="newpassword">New Password</label>
                            </span>
                        </div>
                        <br />
                        <br />
                        <div className="p-field">
                            <span className="p-float-label">
                                <InputText type="password" id="confirm" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                <label htmlFor="confirm">Confirm Password</label>
                            </span>
                        </div>
                    </div>
                )}
            </Dialog>

            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </>
    );
};

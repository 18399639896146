export const POI_VALUES = {
    LOCATION: "location",
    GAS_STATION: "gasstatiom",
    FIRE: "fire",
    RADIO: "radio",
    HOSPITAL: "asterisk",
    FLAG: "startFlag",
    TIME: "time",
    FINISH:"finish"
};

export const SVG = [
    {
        Description: POI_VALUES.GAS_STATION,
        Path: "pois/gas-station.svg",
    },
    {
        Description: POI_VALUES.LOCATION,
        Path: "pois/common.svg",
    },
    {
        Description: POI_VALUES.HOSPITAL,
        Path: "pois/medic.svg",
    },
    {
        Description: POI_VALUES.FIRE,
        Path: "pois/assistance.svg",
    },
    {
        Description: POI_VALUES.RADIO,
        Path: "pois/radio.svg",
    },
    {
        Description: POI_VALUES.FLAG,
        Path: "pois/start.svg",
    },
    {
        Description: POI_VALUES.TIME,
        Path: "pois/time.svg",
    },
    {
        Description: POI_VALUES.FINISH,
        Path: "pois/finish.svg",
    },
];

import { Marker } from "@react-google-maps/api";
import moment from 'moment'

const LIVE_CIRCLE = "circle-purple.svg";
const GRAY_CIRCLE = "circle-grey.svg";
const LIVE_IMG = "LIVE.svg";

export class StageMarker {

    setPoiTags;

    constructor(setPoiTags){
        this.setPoiTags = setPoiTags;
    }

    setStageMarker(routes,currentZoom){
        this.buildPois(routes,currentZoom);
    }
    buildPois(routes,currentZoom) {
        let key = 0; 
        const listItems = routes.map((route) => {
            let live = null;
            let markers = route.Stages.map((stage,i) => {
                let circleUrl = GRAY_CIRCLE;

                let position = this.getStagePosition(route.Positions[0], i, currentZoom);
                key++;
                
                let now = moment(new Date());
            
                if (stage.StartDate != null&& stage.StartTime != null) {

                    let date = new Date(stage.StartDate);
                    let utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());    
                    let startDate = moment(utcDate);            
                                     
                    if (startDate.isSame(now, 'days')) {
                        var startTime = moment(stage.StartTime, 'HH:mm:ss');
                        var nowTime = moment(new Date(),  'HH:mm:ss');

                        if (startTime.isSameOrBefore(nowTime, 'hours')){
                            circleUrl = LIVE_CIRCLE;
                            live = (<Marker
                                icon={{ url: LIVE_IMG }}
                                position={this.getLivePosition(route.Positions[0],currentZoom)}
                                clickable={false}
                                draggable={false}
                                key={key}
                                editable={false}
                                draggable={false}
                            >
                            </Marker>);
                        }                        
                    }
                }

                let label = {
                    text:`${stage.Name}`,
                    color: "#FFFFFF"
                };
                
                return (
                    <Marker
                        icon={{ url: circleUrl}}
                        position={position}
                        clickable={false}
                        draggable={false}
                        key={key}
                        editable={false}
                        draggable={false}
                        label={label}
                    >
                    </Marker>
                );
            });
            return [markers,live];

        })
        this.setPoiTags(listItems);
    }

    getLivePosition(position, zoom){
        
        let diffLat = 0.00005;
        let diffLng = 0;
        if (zoom >= 10) {
            let n = (zoom - 10) - 1;
            let secuencia = (Math.pow(2, n))*3; 
            diffLng = parseFloat((1/(secuencia*10)).toFixed(5));            
        }
        return { lat: parseFloat(position.lat)+(diffLat), lng: parseFloat(position.lng)+(diffLng)};
    }

    getStagePosition(position, index, zoom){
        let diffLat = 0.0001;
        let diffLng = 0.008;

        if (zoom >= 10) {
            let n = (zoom - 10) - 1;
            let secuencia = (Math.pow(2, n))*3; 
            diffLng = parseFloat((1/(secuencia*10)).toFixed(5));            
        }

        return { lat: parseFloat(position.lat)+(diffLat), lng: parseFloat(position.lng) - (diffLng*(index))};
    }

}
import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { EditMap as EditMapOption } from "../core/EditMap";
import { StageMarker } from "../core/StageMarker";
import { EventService } from "../service/EventService";
import { LicenseService } from "../service/LicenseService";
import { TokenAuthService } from "../service/TokenAuthService";
import { MapService } from "../service/MapService";
import { ClubService } from "../service/ClubService";

import CustomGoogleMap from "../components/CustomGoogleMap";

import { EventDialog } from "../dialogs/EventDialog";

import { HeaderPageDetail } from "../components/headers/HeaderPageDetail";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogNoAuthorizedSet, ErrorDialogSetResponse } from "../dialogs/ErrorDialog";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Utils } from "../utilities/Utils";
import { InterestPoints_Icons } from "../utilities/const";
// import { unstable_renderSubtreeIntoContainer } from "react-dom";

// import AppScreen  from "./AppScreen";
// import * as htmlToImage from 'html-to-image';
// import html2canvas from "html2canvas";

const LIBRARIES = ["drawing"];

const DEFAULT_ZOOM = parseInt(process.env.REACT_APP_DEFAULT_ZOOM);
const DEFAULT_MAP_POSITION = { lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT), lng: parseFloat(process.env.REACT_APP_DEFAULT_LNG) };
const STROKE_COLOR = "#d6ff22";

export const Event = () => {
    let emptyEvent = {
        eveId: null,
        stsId: null,
        eveName: "",
        eveStart: null,
        eveFinish: null,
        eveUpddate: null,
        eveDeleted: false,
        eveUpdUserId: null,
        cluId: null,
        clu: {
            cluName: null,
            cluAddress: null,
        },
    };

    let emptyLicense = {
        LicId: null,
        ClubId: null,
        Total: 0,
        Given: 0,
        From: null,
        To: null,
        Events: 0,
    };

    const [centerPositions, setCenterPositions] = useState(DEFAULT_MAP_POSITION);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [loading, setLoading] = useState(true);
    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const [eventDialog, setEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [publishEventDialog, setPublishEventDialog] = useState(false);
    const [specialStagesDialog, setSpecialStagesDialog] = useState(null);
    const [interesPointsDialog, setInteresPointsDialog] = useState(false);

    const [event, setEvent] = useState(emptyEvent);
    const [isPast, setIsPast] = useState(false);
    const [basicEditionPermited, setBasicEditionPermited] = useState(false);
    const [editMapPermited, setEditMapPermited] = useState(false);
    const [mangeCompetitorPermited, setMangeCompetitorPermited] = useState(false);
    const [viewCompetitorsPermited, setViewCompetitorsPermited] = useState(false);

    const [drivers, setDrivers] = useState(null);
    const [license, setLicense] = useState(null);
    const [alerts, setAlerts] = useState(null);
    const [activity, setActivity] = useState(null);
    const [interestPoints, setInterestPoints] = useState(null);
    const [specialStages, setSpecialStages] = useState(null);
    const [map, setMap] = useState(null);
    const [stageTags, setStageTags] = useState([]);

    const toast = useRef(null);
    const params = useParams();
    const history = useHistory();
    const dt = useRef(null);
    const dtAlerts = useRef(null);
    const dtActivity = useRef(null);
    const dtInterestPoint = useRef(null);
    const dtSpecialStages = useRef(null);

    //Event
    const [eventId, setEventId] = useState(params.id);

    //Google Maps
    const [polylineTags, setPolylineTags] = useState([]);
    const [infoWindowsTags, setInfoWindowsTags] = useState([]);
    const [poiTags, setPoiTags] = useState([]);
    const [poiValues, setPoiValues] = useState([]);

    const googleMapOptions = {
        scrollwheel: true,
        scaleControl: false,
        mapTypeControl: false,
        draggable: true,
        zoomControl: true,
        disableDefaultUI: true,
        mapId: "42e6b7328c70c8d1",
        mapTypeId: "satellite",
    };
    const drawingOptions = {
        drawingControl: false,
        polylineOptions: {
            editable: false,
            draggable: false,
            geodesic: false,
            strokeColor: "#d6ff22",
            clickable: false,
        },
    };

    const [positionTags, setPositionTags] = useState([]);
    const BLOCK_CLICK = true;
    var mapService = new MapService();
    var clubService = new ClubService();
    var initialMap = new EditMapOption(
        BLOCK_CLICK,
        eventId,
        mapService,
        setPolylineTags,
        setInfoWindowsTags,
        setPoiTags,
        setPoiValues,
        () => {},
        STROKE_COLOR,
        () => {}
    );
    var stageMarkers = new StageMarker(setStageTags);

    useEffect(async () => {
        await initializeAll();
    }, []);

    const initializeAll = async () => {
        var response = await initialMap.initializeCircuits();
        let routes = response.routes;

        let zoom = response.mapPosition?.zoom || DEFAULT_ZOOM;
        let mapPosition = DEFAULT_MAP_POSITION;

        let club = await clubService.getClubByEventId(eventId);

        if (response.mapPosition?.lat) {
            mapPosition = { lat: parseFloat(response.mapPosition.lat), lng: parseFloat(response.mapPosition.lng) };
        }else if(club.cluLatitude && club.cluLongitude){
            if (club.cluLatitude != "" && club.cluLongitude != "") {
                mapPosition = { lat: parseFloat(club.cluLongitude), lng: parseFloat(club.cluLatitude) };
            }         
        }

        setCenterPositions(mapPosition);
        setZoom(zoom);
        initialMap.initializePois();
        //stageMarkers.setStageMarker(routes,zoom);
    };

    // Se ejecuta luego del render
    useEffect(() => {
        try {
            const load = async () => {
                setLoading(true);

                const eventService = new EventService();
                const resEvent = await eventService.getEvent(params.id);

                setEvent(resEvent);
                setIsPast(resEvent.past);

                const resInterestPoints = await eventService.getInterestPoints(params.id);
                setInterestPoints(resInterestPoints);
                //console.log(resInterestPoints);

                const resSpecialStages = await eventService.getSpecialStages(params.id);
                setSpecialStages(resSpecialStages);
                //console.log(resSpecialStages);

                const auth = new TokenAuthService();
                let isAdmin = auth.isAdmin();
                let isPlanner = auth.isPlanner();
                let isViewer = auth.isViewer();
                let isDirector = auth.isDirector();

                setBasicEditionPermited((isAdmin || isPlanner) && !resEvent.past);
                setEditMapPermited((isAdmin || isPlanner || isViewer) && !resEvent.past);
                setMangeCompetitorPermited(isAdmin || isPlanner);
                setViewCompetitorsPermited(isViewer || isDirector);

                //setDisabled(resEvent.past || !auth.isAdmin());

                const licenseService = new LicenseService();
                const resLicenses = await licenseService.getCurrent(resEvent.cluId);
                if (resLicenses.length > 0) {
                    let _license = { ...resLicenses[0] };
                    _license.ClubId = params.id;
                    setLicense(_license);
                } else {
                    setLicense(emptyLicense);
                }

                //const driverAlertService = new DriverAlertService();
                //const resAlerts = await driverAlertService.getRaceAlert(params.id);
                //setAlerts(resAlerts);

                //const resActivity = await driverAlertService.getRaceActivity(params.id);
                //setActivity(resActivity);

                const resDrivers = await eventService.getDrivers(params.id);
                setDrivers(resDrivers);

                setLoading(false);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
        }
    }, []);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    const deleteEvent = () => {
        hideDeleteEventDialog();
        try {
            // let _event = { ...event };
            let eventService = new EventService();
            eventService
                .deleteEvent(event.eveId)
                .then((data) => {
                    setDeleteEventDialog(false);
                    setEvent(emptyEvent);
                    history.push("/events/" + "Event Deleted");
                    //toast.current.show({ severity: "success", summary: "Successful", detail: "Event Deleted", life: 3000 });
                })
                .catch((res) => {
                    setStateErrorDialog(ErrorDialogSetResponse(res));
                });
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };

    const publishEvent = () => {
        try {
            let eventService = new EventService();
            eventService
                .publishEvent(event.eveId)
                .then((data) => {
                    hidePublishEventDialog();
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Event Published", life: 3000 });
                })
                .catch((res) => {
                    setStateErrorDialog(ErrorDialogSetResponse(res));
                });
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };
    const showPublishEventDialog = () => {
        setPublishEventDialog(true);
    };
    const hidePublishEventDialog = () => {
        setPublishEventDialog(false);
    };
    const publishEventDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={hidePublishEventDialog} />
            <Button label="Publish" className="btn-primary" onClick={publishEvent} />
        </>
    );

    const showInteresPointsDialog = () => {
        setInteresPointsDialog(true);
    };
    const hideInteresPointsDialog = () => {
        setInteresPointsDialog(false);
    };
    const interesPointsDialogFooter = (
        <>
            <Button label="Close" className="btn-secondary" onClick={hideInteresPointsDialog} />
        </>
    );

    const showSpecialStagesDialog = () => {
        setSpecialStagesDialog(true);
    };
    const hideSpecialStagesDialog = () => {
        setSpecialStagesDialog(false);
    };
    const specialStagesDialogFooter = (
        <>
            <Button label="Close" className="btn-secondary" onClick={hideSpecialStagesDialog} />
        </>
    );

    const confirmDeleteEvent = () => {
        setDeleteEventDialog(true);
    };
    const hideDeleteEventDialog = () => {
        setDeleteEventDialog(false);
    };
    const editDialog = () => {
        setEventDialog(true);
    };

    const openManageDrivers = () => {
        history.push("/drivers/" + event.eveId + "/" + null + "/" + license.LicId);
    };

    const openManageEvent = () => {
        history.push("/drawing/" + event.eveId);
    };

    const hideDialog = (_event) => {
        if (_event != null) {
            setEvent(_event);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Event Updated", life: 3000 });
        }
        setEventDialog(false);
    };
    const downloadCompetitors = () => {
        dt.current.exportCSV();
    };
    const downloadRaceReport = () => {
        dtAlerts.current.exportCSV();
        dtActivity.current.exportCSV();
    };

    const downloadMap = () => {
        window.print();
        // var data = document.getElementById('drawing-manager-example')
        // //var data = document.getElementById('root')
        // var contents = window.document.getElementById('drawing-manager-example');
        // document.write(contents.innerHTML);
        // window.print();
        // data = map;
        // html2canvas(data,
        //     {
        //         "useCORS": true,
        //         "allowTaint": true,
        //         "logging": true
        //     }).then((canvas)=>{
        //        // document.body.appendChild(canvas)
        //     var image = canvas.toDataURL('image/png', 1.0);
        //     var fileName = 'mapa.png'
        //     saveAs(image, fileName)
        //   })
    };

    const saveAs = (blob, fileName) => {
        var elem = window.document.createElement("a");
        elem.href = blob;
        elem.download = fileName;
        elem.style = "display:none;";
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === "function") {
            elem.click();
        } else {
            elem.target = "_blank";
            elem.dispatchEvent(
                new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                })
            );
        }
        URL.revokeObjectURL(elem.href);
        elem.remove();
    };

    const importCSV = async ({ files }) => {
        try {
            toast.current.show({ severity: "success", summary: "Successful", detail: "The point were imported", life: 3000 });
        } catch (error) {
            let errormessage = "";
            if (error.response) {
                errormessage = error.response.data.message;
            }
            setStateErrorDialog(ErrorDialogSet(true, errormessage));
        }
    };

    // #region Templatedatatable

    // const descriptionBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             {/* <span className="p-column-title">Number</span> */}
    //             {rowData.Description}
    //         </>
    //     );
    // };

    const numberBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Number}</span>;
    };

    const authorizationBodyTemplate = (rowData) => {
        return (
            <span className="p-column-title">
                {rowData.Authorized === 1 ? (
                    <span className="p-column-title txt-clrSucces500">
                        <i className="pi pi-check-circle p-mr-2" style={{ fontSize: "0.75rem" }}></i>Yes
                    </span>
                ) : (
                    <span className="p-column-title txt-clrAlert500">
                        <i className="pi pi-times-circle p-mr-2" style={{ fontSize: "0.75rem" }}></i>
                        No
                    </span>
                )}
            </span>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <span className="p-column-title">
                {rowData.State == "PENDING_INVITATION" && "Pending"}
                {rowData.State == "ACEPTED_INVITATION" && "Accepted"}
                {rowData.State == "REJECTED_INVITATION" && "Rejected"}
                {rowData.State == "NOTSEND_INVITATION" && "Not Send"}
            </span>
        );
    };

    const copilotBodyTemplate = (rowData) => {
        return (
            <span className="p-column-title">
                {rowData.CodriverName} {rowData.CodriverSurname} ({rowData.CodriverGrupoSanguineo})
            </span>
        );
    };
    const categoryBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Category}</span>;
    };
    const brandBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Brand}</span>;
    };
    const modelBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Model}</span>;
    };

    const stagesBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Stages}</span>;
    };
    const circuitNameBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Name}</span>;
    };
    const kmBodyTemplate = (rowData) => {
        return <span className="p-column-title">{Math.round(rowData.Km * 100) / 100} km (Approx)</span>;
    };

    const descriptionIPBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Description}</span>;
    };
    const latIPBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Lat}</span>;
    };
    const longIPBodyTemplate = (rowData) => {
        return <span className="p-column-title">{rowData.Long}</span>;
    };

    // const carNumberBodyTemplate = (rowData) => {
    //     return <span className="p-column-title">Sent by car {rowData.Number}</span>;
    // };
    // const notifDateBodyTemplate = (rowData) => {
    //     return <span className="p-column-title">{rowData.NotifDate}</span>;
    // };

    // const specialStageNameBodyTemplate = (rowData) => {
    //     return <span className="p-column-title">{rowData.Name}</span>;
    // };

    const pilotBodyTemplate = (rowData) => {
        return (
            <span className="p-column-title">
                {rowData.FirstName} {rowData.LastName} ({rowData.DriverGrupoSanguineo})
            </span>
        );
    };

    const header = (
        <div className="table-header">
            <h2 className="p-m-0 fs-x-normal">
                Competitors ({drivers ? drivers.length : 0}){" "}
                <span className="fs-medium txt-clrLight250 txt-regular p-ml-2 txt-clrLight230">
                    {license.Given} out of {license.Total} licenses available
                </span>
            </h2>
            <div>
                {!isPast && mangeCompetitorPermited && <Button label="Manage competitors" icon="pi pi-pencil" className="btn-secondary" onClick={openManageDrivers}></Button>}
                {(isPast || (viewCompetitorsPermited && (isPast || !mangeCompetitorPermited))) && <Button label="View competitors list" className="btn-secondary  p-mr-2" onClick={openManageDrivers}></Button>}
                {isPast && <Button label="Download" icon="pi pi-download" className="btn-secondary" onClick={downloadCompetitors}></Button>}
            </div>
        </div>
    );

    const deleteEventDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={hideDeleteEventDialog} />
            <Button label="Delete" className="btn-primary" onClick={deleteEvent} />
        </>
    );

    //Header Pages Templates Parts
    const leftColumnTemplate = () => {
        return (
            <div className="p-m-0 p-col-4">
                <Button label="Back" icon="pi pi-chevron-left" onClick={() => history.goBack()} className="p-button-text btn-back txt-clrLight100" />
            </div>
        );
    };

    const rightColumnTemplate = () => {
        return (
            <div className="p-m-0 p-col-8 p-d-flex p-jc-end">
                {basicEditionPermited && <Button label="Delete event" icon="pi pi-trash" className="btn-secondary  p-mr-2" onClick={confirmDeleteEvent}></Button>}
                {basicEditionPermited && <Button label="Edit details" icon="pi pi-pencil" className="btn-secondary  p-mr-2" onClick={editDialog}></Button>}
                {basicEditionPermited && <Button label="Publish event" className="btn-primary  p-mr-2" onClick={showPublishEventDialog}></Button>}
            </div>
        );
    };

    const fullColumnTemplate = () => {
        return (
            <>
                <h1 className="p-m-0 p-col-12 fs-x-big txt-clrLight100">
                    {event.eveName} {isPast && <span className="tag-finished-event">FINISHED EVENT</span>}
                </h1>
                <div className="p-m-0 p-col-12">
                    <ul className="header-club-list">
                        <li className="list-item">
                            <i className="pi pi-calendar"></i> Start date{" "}
                            <span className="txt-bold" style={{ marginLeft: "0.25rem" }}>
                                {Utils.dateDDMMYY(event.eveStart)}
                            </span>
                        </li>
                        <li className="list-item">
                            Finish date{" "}
                            <span className="txt-bold" style={{ marginLeft: "0.25rem" }}>
                                {Utils.dateDDMMYY(event.eveFinish)}
                            </span>
                        </li>

                        <li className="list-item">
                            <i className="pi pi-map-marker"></i>
                            {event.eveCity + ", " + event.eveState + ". " + (event.cou ? event.cou.couName : "")}
                        </li>
                        <li className="list-item">
                            <i className="pi pi-users"></i> {event.clu.cluName}
                        </li>
                    </ul>
                </div>
            </>
        );
    };

    const onLoadMap = () => {
        setMap(window.google.maps);
    };

    const exportPolylines = async () => {
        const mapService = new MapService();
        let points = await mapService.getRoutesByEventId(params.id);

        const eventService = new EventService();
        let event = await eventService.getEvent(params.id);
        points.data.routes.push({ OriginalEventStart: event.eveStart });

        //Utils.downloadJson(points.data.routes, "points");
        Utils.downloadKml(points.data.routes, "points");
    };

    const exportRaceReports = async () => {
        const eventService = new EventService();

        let alertas = await eventService.getAlerts(params.id);

        //Utils.downloadJson(alertas, "Alerts");
        Utils.exportJsonToCSV(alertas, "Alertas", true);

        let flags = await eventService.getFlags(params.id);

        //Utils.downloadJson(flags, "Flags");
        Utils.exportJsonToCSV(flags, "Flags", true);
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="inner-container">
                    <Toast ref={toast} />
                    <HeaderPageDetail headerStyles="p-mb-5 header-club-detail p-ai-start" leftColumn={leftColumnTemplate()} rightColumn={rightColumnTemplate()} fullColumn={fullColumnTemplate()} />

                    {false && (
                        <div className="card">
                            <div className="p-grid p-ai-center p-mb-4">
                                <h2 className="p-m-0 p-col-6 fs-x-normal">Race reports</h2>
                                <div className="p-m-0 p-col-6 p-d-flex p-jc-end">
                                    <Button label="Download" icon="pi pi-download" className="btn-secondary" onClick={downloadRaceReport} />
                                </div>
                                <div className="p-grid map-main-container p-col-12 p-mt-4">
                                    <div className="map-box-container">
                                        {/* Race activity */}
                                        <div className="p-col-6 map-box">
                                            <div className="map-box--header p-d-flex p-ai-center p-jc-between ">
                                                <h3 className="fs-x-normal">Race activity</h3>
                                                {<Button label="View All" className="p-button-text" onClick={() => alert("View all race activity")} />}
                                            </div>

                                            <ul className="map-list">
                                                <li className="map-item border-bottom">
                                                    <p className="fs-medium txt-bold">Car 45 disconnected</p>
                                                    <p className="fs-medium txt-right">SS01</p>
                                                    <p className="fs-medium txt-right">(13-03-21) 14:02:43</p>
                                                </li>
                                                <li className="map-item border-bottom">
                                                    <p className="fs-medium txt-bold">Car 45 disconnected</p>
                                                    <p className="fs-medium txt-right">SS01</p>
                                                    <p className="fs-medium txt-right">(13-03-21) 14:02:43</p>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* Specials Stages */}
                                        <div className="p-col-6 map-box">
                                            <div className="map-box--header p-d-flex p-ai-center p-jc-between ">
                                                <h3 className="fs-x-normal">
                                                    Race Alerts
                                                    <span className="txt-regular p-ml-2">(16)</span>
                                                </h3>
                                                {<Button label="View All" className="p-button-text " onClick={() => alert("View all race alerts")} />}
                                            </div>
                                            <ul className="map-list">
                                                {/* Items Special Stages */}
                                                <li className="map-item border-bottom">
                                                    <div className="alert-tag tag-sos">SOS</div>
                                                    <p className="fs-medium ">
                                                        <span className="txt-bold">[MEDICAL]</span> Sent by Car 78
                                                    </p>
                                                    <p className="fs-medium txt-right">SS01</p>
                                                    <p className="fs-medium txt-right">(13-03-21) 14:02:43</p>
                                                </li>
                                                <li className="map-item border-bottom">
                                                    <div className="alert-tag tag-ok">ok</div>
                                                    <p className="fs-medium ">
                                                        <span className="txt-bold">[MEDICAL]</span> Sent by Car 78
                                                    </p>
                                                    <p className="fs-medium txt-right">SS01</p>
                                                    <p className="fs-medium txt-right">(13-03-21) 14:02:43</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="card">
                        <div className="p-grid p-ai-center p-mb-4">
                            <h2 className="p-m-0 p-col-6 fs-x-normal">Map</h2>
                            <div className="p-m-0 p-col-6 p-d-flex p-jc-end">
                                <Button label="export race reports" icon="pi pi-pencil" className="btn-secondary p-mr-2" onClick={exportRaceReports} />
                                {editMapPermited && <Button label="export polylines" icon="pi pi-pencil" className="btn-secondary p-mr-2" onClick={exportPolylines} />}

                                {!isPast && editMapPermited && <Button label="Edit map" icon="pi pi-pencil" className="btn-secondary " onClick={openManageEvent} />}

                                {isPast && <Button label="Download" icon="pi pi-download " className="btn-secondary " onClick={downloadMap} />}
                            </div>
                        </div>

                        {/* Mapa //! Todo esto es hardcodeado  */}

                        <div className="p-grid map-main-container">
                            <div className="p-col-12 map">
                                <CustomGoogleMap
                                    googleMapId={"idmap"}
                                    libraries={LIBRARIES}
                                    zoom={zoom}
                                    googleOptions={googleMapOptions}
                                    centerPositions={centerPositions}
                                    drawingOptions={drawingOptions}
                                    drawingMode={null}
                                    poiTags={[positionTags, poiTags, stageTags]}
                                    polylineTags={polylineTags}
                                    flagTags={[]}
                                    onLoad={(e) => {
                                        onLoadMap();
                                    }}
                                />
                            </div>

                            <div className="map-box-container">
                                {/* POIS */}
                                <div className="p-col-6 map-box">
                                    <div className="map-box--header p-d-flex p-ai-center p-jc-between ">
                                        <h3 className="fs-x-normal">
                                            Points of interest
                                            <span className="txt-regular p-ml-2 txt-clrLight250">({interestPoints.length})</span>
                                        </h3>
                                        {<Button label="View All" className="p-button-text" onClick={showInteresPointsDialog} />}
                                    </div>

                                    <ul className="map-list">
                                        {interestPoints.slice(0, 5).map((item, index) => (
                                            <li key={index} className="map-item">
                                                <p className="p-d-flex p-ai-center">
                                                    <i className={InterestPoints_Icons[item.Icon]}></i>
                                                    {item.Description}
                                                </p>
                                                <p className="latlong txt-right">
                                                    {item.Lat.slice(0, 10)}, {item.Long.slice(0, 10)}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Specials Stages */}
                                <div className="p-col-6 map-box">
                                    <div className="map-box--header p-d-flex p-ai-center p-jc-between ">
                                        <h3 className="fs-x-normal">
                                            Special Stages (SS)
                                            <span className="txt-regular p-ml-2 txt-clrLight250">({specialStages.length})</span>
                                        </h3>
                                        {<Button label="View All" className="p-button-text " onClick={showSpecialStagesDialog} />}
                                    </div>
                                    <ul className="map-list">
                                        {specialStages.slice(0, 5).map((item, index) => (
                                            <li key={index} className="map-item">
                                                {/* Numeros de Special Stage */}
                                                {item.Stages.split(",").map((ss, index) => (
                                                    <div key={index} className="map-number">
                                                        {ss}
                                                    </div>
                                                ))}
                                                {/* <div className="map-number">{[...item.Stages]}</div> */}

                                                {/* Nombre de Stage */}
                                                <p className="p-d-flex p-ai-center">{item.Name}</p>
                                                {/* KM de Stage */}
                                                <p className="txt-right">{Math.round(item.Km * 100) / 100} km (Approx)</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <DataTable
                            ref={dt}
                            value={drivers}
                            // selection={selectedDrivers}
                            // onSelectionChange={(e) => setSelectedDrivers(e.value)}
                            dataKey="Id"
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive table-layout-auto"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} drivers"
                            // globalFilter={globalFilter}
                            emptyMessage="No drivers found."
                            header={header}
                        >
                            {/* <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}/> */}
                            <Column field="Number" header="CAR NUMBER" body={numberBodyTemplate} sortable headerClassName="txt-center" bodyClassName="txt-right" />
                            <Column field="State" header="INVITATION" sortable body={statusBodyTemplate} />
                            <Column field="Authorized" header="AUTHORIZATION" headerClassName="txt-center" bodyClassName="txt-center" sortable body={authorizationBodyTemplate} />

                            <Column field="FirstName" header="DRIVER" sortable body={pilotBodyTemplate} />
                            <Column field="CodriverName" header="CODRIVER" sortable body={copilotBodyTemplate} />
                            <Column field="Category" header="CATEGORY" headerClassName="txt-center" bodyClassName="txt-center" sortable body={categoryBodyTemplate} />
                            <Column field="Brand" header="BRAND" body={brandBodyTemplate} sortable />
                            <Column field="Model" header="MODEL" body={modelBodyTemplate} sortable className="is-last" />
                            {/* <Column body={actionBodyTemplate}/> */}
                        </DataTable>
                    </div>

                    <EventDialog visibleDialog={eventDialog} event={event} onClose={hideDialog}></EventDialog>

                    <Dialog visible={deleteEventDialog} className="p-dialog-small" header="Delete event" modal footer={deleteEventDialogFooter} onHide={hideDeleteEventDialog}>
                        <div className="confirmation-content">
                            {event && (
                                <span>
                                    Are you sure you want to delete <b>{event.name}</b>? <br />
                                    They will no longer be able to access the platform
                                </span>
                            )}
                        </div>
                    </Dialog>

                    {/* PUBLISH */}
                    <Dialog visible={publishEventDialog} className="p-dialog-small" header="Publish event" modal footer={publishEventDialogFooter} onHide={hidePublishEventDialog}>
                        <div className="confirmation-content">{event && <span>Are you sure you want to make this event public? Competitors will receive an invite and be able to see the details of the race on thier apps.</span>}</div>
                    </Dialog>

                    {/* INTEREST POINTS */}
                    <Dialog style={{ width: "850px" }} visible={interesPointsDialog} className="p-dialog-small" header="Interest Points" modal footer={interesPointsDialogFooter} onHide={hideInteresPointsDialog}>
                        <DataTable ref={dtInterestPoint} value={interestPoints}>
                            <Column field="Description" body={descriptionIPBodyTemplate}></Column>
                            <Column field="Lat" body={latIPBodyTemplate}></Column>
                            <Column field="Long" body={longIPBodyTemplate} className="is-last">
                                {" "}
                            </Column>
                        </DataTable>
                    </Dialog>

                    {/* SPECIAL STAGES */}
                    <Dialog style={{ width: "850px" }} visible={specialStagesDialog} className="p-dialog-small" header="Special stages" modal footer={specialStagesDialogFooter} onHide={hideSpecialStagesDialog}>
                        <DataTable ref={dtSpecialStages} value={specialStages}>
                            <Column field="Stages" body={stagesBodyTemplate}></Column>
                            <Column field="Name" body={circuitNameBodyTemplate}></Column>
                            <Column field="Km" body={kmBodyTemplate} className="is-last"></Column>
                        </DataTable>
                    </Dialog>

                    <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
                </div>
            </div>
        </div>
    );
};

import {ALERT_TYPES} from '../consts/alertTypes';
import {FLAGS} from '../consts/flags';
import { GoogleMapTool } from "./GoogleMapTool";

const RED_CIRCLE_SOS = "circle-red-black.svg";
const BLACK_CIRCLE = "circle-black.svg";
//const GRAY_CIRCLE = "circle-grey.svg";
const RED_CIRCLE =  "circle-red.svg";
const YELLOW_CIRCLE = "yellowCircle.svg";
const WHITE_CIRCLE = "whiteCircle.svg";
const GRAY_CIRCLE = WHITE_CIRCLE;
const GREEN_CIRCLE = "green-circle.svg";
const OFFLINE_CAR =  "offline-circle.svg";
const TOLERANCE_LEVEL = 20e-5;

const WAIT_TIME= 12;    
export class DriverPositionTracker {

    mapService;
    eveId;
    setDriverPositions;
    setPositionTags;
    selectedIcon;
    iconAnimation;
    selectedRoute;
    mapTools;
    positionTags;
    positionTagsRef;
    driverPositionsRef;
    circuitsRef;
    setInfoDriverWindowsTags;
    infoDriverWindowsTagsRef;

    polylineOptions = {
        strokeColor: "#d6ff22",
        strokeWeight: 4
    };

    constructor(mapService,eveId,setDriverPositions,setPositionTags, positionTags,selectedIcon, iconAnimation, positionTagsRef, driverPositionsRef, circuitsRef, setInfoDriverWindowsTags, infoDriverWindowsTagsRef){
        this.mapService = mapService;
        this.eveId = eveId;
        this.setDriverPositions = setDriverPositions;
        this.setPositionTags = setPositionTags;
        this.selectedIcon = selectedIcon;
        this.iconAnimation = iconAnimation;
        this.mapTools = new GoogleMapTool();
        this.positionTags = positionTags;
        this.positionTagsRef = positionTagsRef;
        this.driverPositionsRef = driverPositionsRef;
        this.circuitsRef = circuitsRef;
        this.setInfoDriverWindowsTags = setInfoDriverWindowsTags;
        this.infoDriverWindowsTagsRef = infoDriverWindowsTagsRef;
    }

    setSelectedRoute(route){
        this.selectedRoute = route;
    }

    async initializeDriverTraker(map) {
        try {
            var positions = [];
            var driverPositions = await this.mapService.getLastDriverPositionsByEventId(this.eveId);
            driverPositions.forEach(async (item) => {
                positions.push({
                    name: item.name,
                    surname: item.surname,
                    userId: item.userId,
                    carnumber: item.carnumber,
                    icon: null,
                    animation: null,
                    position: { lat: parseFloat(item.position.lat), lng: parseFloat(item.position.lng), addDate: item.position.addDate },
                    positionId: item.position.id,
                    driverAlertIds: item.driverAlertIds,
                    oldPosition: null,
                    lastUpdate: null,
                    stoped: false
                });
            });
            await this.buildPositionTags(positions, map);
        } catch (error) {
            console.log(error);
            console.log("An error has occurred trying to get the driver position.");
        }
    }

    async setDriverAlert(newDriverPosition,positions, selectedCircuit, driverAlertId, map){
        this.setSelectedRoute(selectedCircuit);
        var oldDriver = positions.find(x => x.userId == newDriverPosition.driver.User.Id);
        
        if (oldDriver) {
            var oldIndex = positions.indexOf(oldDriver);
            var alertIds = oldDriver.driverAlertIds == null ? oldDriver.driverAlertIds.push(driverAlertId) : [driverAlertId];
            var newDriver = {
                ...oldDriver,
                driverAlertIds : alertIds,
                offlineCar: false
            };

            positions.splice(oldIndex,1);

            positions.push(newDriver);

            await this.buildPositionTags(positions, map, true);
        }
    }

    async setOfflineColor(map, offlineDrivers, driverPositions){

        var newDrivers = [];
        driverPositions.forEach(position => {
            var offlineDriver = offlineDrivers.find(x => x.userId == position.userId);
            let offlineCar = false;

            if (offlineDriver) {
                offlineCar = true;
            }
            var newDriver = {
                ...position,
                offlineCar : offlineCar
            };


            newDrivers.push(newDriver);
        });

        await this.buildPositionTags(newDrivers, map, true);
    };

    async removeDrivesFromMap(map, offlineDrivers, driverPositions){
        var positions = [];
        driverPositions.forEach(async (item) => {
            var driver = offlineDrivers.find(driver => driver.userId == item.userId);
            if (!driver) {
                positions.push(item);               
            };
        });
        
        this.clearDrivesFromMap(offlineDrivers, this.positionTagsRef.current);
        await this.buildPositionTags(positions,map); 
    };

    async setDriverTraker(newDriverPosition, driverPositions, map) {
        var positions = [...driverPositions];
        var oldDriver = positions.find(x => x.userId == newDriverPosition.userId);
        !oldDriver && console.log("Driver not found.")
        oldDriver && console.log("Driver found.")
        if (oldDriver) {
            var oldIndex = positions.indexOf(oldDriver);
            var position = {
                lat: parseFloat(newDriverPosition.position.lat),
                lng: parseFloat(newDriverPosition.position.lng),
                addDate: newDriverPosition.position.addDate
            };

            var stoped = false;
            var lastUpdate = oldDriver.lastUpdate;
            var oldPosition = oldDriver.oldPosition;

            if (oldDriver.oldPosition != null) {

                if (this.mapTools.checkTimeHasPassed(oldDriver.lastUpdate, WAIT_TIME)) {

                    var isSamePosition = this.mapTools.isSamePosition(position, oldDriver.oldPosition);
                    if (!isSamePosition) {
                        lastUpdate = new Date(position.addDate);
                        oldPosition = position;
                        stoped = false;
                    } else {
                        stoped = true;
                    }
                }
            }

            var newDriver = {
                ...oldDriver,
                position: position,
                positionId: newDriverPosition.position.id,
                oldPosition: oldPosition || position,
                lastUpdate: lastUpdate || new Date(),
                stoped: stoped,
                offlineCar: false
            };

            positions.splice(oldIndex, 1);
            positions.push(newDriver);

            await this.buildPositionTags(positions, map);
        } else {
            var driverPosition = await this.mapService.getLastDriverPositionByEventId(this.eveId, newDriverPosition.userId);
            if (driverPosition.length > 0) {
                var driver = {
                    name: driverPosition[0].name,
                    surname: driverPosition[0].surname,
                    userId: driverPosition[0].userId,
                    carnumber: driverPosition[0].carnumber,
                    icon: null,
                    animation: null,
                    position: { lat: parseFloat(driverPosition[0].position.lat), lng: parseFloat(driverPosition[0].position.lng), addDate: driverPosition[0].position.addDate },
                    positionId: driverPosition[0].position.id,
                    driverAlertIds: driverPosition[0].driverAlertIds,
                    oldPosition: null,
                    lastUpdate: null,
                    stoped: false
                };

                positions.push(driver);
                await this.buildPositionTags(positions, map);
            }
        };
    }

    async showRedCircleOnDrivers(driverPositions, selectedCircuit, selectedDrivers, messageTypeId){
        if (messageTypeId == FLAGS.RED) {
            this.setSelectedRoute(selectedCircuit);
            driverPositions.forEach(async (item) => {
                var selectedDriver = selectedDrivers.find(x => x.userId == item.userId);
                if (selectedDriver) {
                    if (!item.driverAlertIds) item.driverAlertIds = [];
                        item.driverAlertIds.push(parseInt(messageTypeId));                     
                }
                item.animation = null;
                item.icon = null;
            })
            await this.buildPositionTags(driverPositions, null, true);           
        }
    }

    async clearSelectedDrivers(driverPositions, selectedCircuit){
        this.setSelectedRoute(selectedCircuit);
        driverPositions.forEach(async (driver) => {
            driver.animation = null;
            driver.icon = null;
        })
        await this.buildPositionTags(driverPositions, null, true);
    }

    clearDriverTraker(){
        this.positionTagsRef.current.forEach(tag => {
            tag.marker.setMap(null);
        });
        //this.positionTagsRef.current = [];
        this.setDriverPositions([]);
        this.setPositionTags([]);
    }

    async cleanFlags(driverPositions, selectedCircuit){

        await this.mapService.cleanFlags(this.eveId);

        this.setSelectedRoute(selectedCircuit);
        driverPositions.forEach(async (item) => {
            item.driverAlertIds = [];
        })
        await this.buildPositionTags(driverPositions, null, true);
    }

    setContentOfInfoDriverWindows(driver, infoDriverWindowsTagsRef){
        const driverWindow = infoDriverWindowsTagsRef.current.find(x => x.user.userId == driver.userId);
        if (driverWindow) {
            
            let lng = parseFloat(driver.position.lng).toFixed(5);
            let lat =  parseFloat(driver.position.lat).toFixed(5);

            let content = `<div className="info-window-body" style="color:black">
            <div>CAR ${driverWindow.user.carNumber || "Not informed"}</div>
            <div>PILOT ${driverWindow.user.pilot || "Not informed"}</div>
            <div>COPILOT ${driverWindow.user.copilot || "Not informed"}</div>
            <div>LAST KNOWN LOCATION ${lat};${lng}</div>
            </div>`;

            driverWindow.tag.setContent(content);
        }
    };   

    async toggleBounce(user,positions, onlySelected = false) {
        var itemList = positions.map((pos) => {
            if (pos.userId === user.userId) {
                if (pos.animation !== this.iconAnimation || onlySelected) {
                    pos.animation = this.iconAnimation;
                    pos.icon = { url: this.selectedIcon};
                    pos.driverInfo = user.driverInfo;
                    pos.isOpen = user.isOpen;
                } else {
                    pos.animation = null;
                    pos.icon = null;
                }
            }
            return {...pos};
        });
        await this.buildPositionTags(itemList, null, true);
    }

    checkDriverPosition(driver){
        if (!window.google)
            return;
        
        if (!window.google.maps)
            return;
        var circuits = this.circuitsRef.current;
        let isInside = false;

        circuits.forEach(circuit => {
            if (isInside) 
                return;
            var path = circuit.Positions.map((pos, i) => {
                return new window.google.maps.LatLng(parseFloat(pos.lat), parseFloat(pos.lng));
            });

            const poly = new window.google.maps.Polyline({
                path: path
            });
            const point = new window.google.maps.LatLng(parseFloat(driver.position.lat), parseFloat(driver.position.lng));
            isInside = window.google.maps.geometry.poly.isLocationOnEdge(point,  poly, TOLERANCE_LEVEL);         
        });
        return isInside;
    }

    async getInfoDriver(userId){
        try {
            var driver = await this.mapService.getInfoDriver(this.eveId,userId);
            return driver[0];

        } catch (error) {
            console.log(error)
            console.log("An error has occurred trying to get the driver position.");
        }
    }

    getIconUrl(pos){
        
        var iconUrl = pos.icon;

        if (iconUrl == null) {
            var redFlag = 0;
            var greenFlag = false;

  
            if (pos.driverAlertIds) {
                if (pos.driverAlertIds?.length != 0){
                    if (pos.driverAlertIds.includes(FLAGS.RED))
                    {
                        redFlag = 1;
                    }
                    else if(pos.driverAlertIds.includes(ALERT_TYPES.SOS) || pos.driverAlertIds.includes(ALERT_TYPES.MEDICAL) || pos.driverAlertIds.includes(ALERT_TYPES.FIRE))
                    {
                        redFlag = 2;  
                    }
                    else if (pos.driverAlertIds.includes(ALERT_TYPES.RC) || pos.driverAlertIds.includes(ALERT_TYPES.ROAD_BLOCKED)|| pos.driverAlertIds.includes(ALERT_TYPES.OK)) 
                    {
                        greenFlag = true;
                    }                
                }                
            }


            if (redFlag != 0 || greenFlag) {
                if (redFlag == 1)
                {
                    iconUrl =  RED_CIRCLE;

                }else if (redFlag == 2){
                    iconUrl =  RED_CIRCLE_SOS;
                }
                else if (greenFlag)
                {
                    iconUrl =  GREEN_CIRCLE;
                }

            }else{
                if (pos.offlineCar) {
                    iconUrl =  OFFLINE_CAR;
                }else{
                    if (this.checkDriverPosition(pos)) {
                        if (pos.stoped) {
                            iconUrl =  YELLOW_CIRCLE;
                        }else{
                            iconUrl =  BLACK_CIRCLE;
                        }
                    }else{
                        if (pos.stoped) {
                            iconUrl =  GRAY_CIRCLE;
                        }else{
                            iconUrl =  WHITE_CIRCLE;
                        }
                    }                    
                }
            }
        }
       
        return iconUrl;
    }

    getTextColorOfCircle(url){
    
        var color= "";

        switch (url) {
            case WHITE_CIRCLE:
                color= "#000000";
                break;
            case BLACK_CIRCLE:
                color= "#FFFFFF";
                break;
            case RED_CIRCLE:
                color= "#FFFFFF";
                break;
            case YELLOW_CIRCLE:
                color= "#000000";
                break;
            case GRAY_CIRCLE:
                color= "#AAAAAA";
                break;
            case OFFLINE_CAR:
                color= "#AAAAAA";
                break;
            case GREEN_CIRCLE:
                color= "#000000";
                break;
            default:
                color= "#FFFFFF";
                break;
        }
        return color;
    }

    async buildPositionTags(positions,map, notPosition){
        var newPositionTags = [];        
        this.setDriverPositions(positions);
        for await (let pos of positions){
           

            var tag = this.positionTagsRef.current.find(x => x.id == pos.userId);
            
            var iconUrl =  this.getIconUrl(pos);
            var color = this.getTextColorOfCircle(iconUrl);

            var label = {
                        text:`${pos.carnumber}`,
                        color: color
                    };

            if (tag) {
                this.updateMarker(tag,pos, iconUrl, notPosition, label);
            } else {
                var newMarker = await this.createMarker(pos,map,iconUrl,label);
                newPositionTags.push(newMarker);
            }
        }

        if (newPositionTags.length >0)
            this.setPositionTags([...this.positionTagsRef.current, ...newPositionTags]);
        
    }

    updateMarker = (tag,pos, iconUrl, notPosition, label) => {  
        if (!notPosition)
            tag.marker.setPosition(pos.position);

        tag.marker.setLabel(label);   
        tag.marker.setIcon(iconUrl);

        if (pos.animation == this.iconAnimation) {
            tag.marker.setAnimation(this.iconAnimation);
        }else{
            tag.marker.setAnimation(null);
        }
        
    }
    
    clearDrivesFromMap(offlineDrivers, positionTags){
        let newPositionTags = [];
        positionTags.forEach((tag, index) => {
            let  driver = offlineDrivers.find(item => item.userId == tag.id);
            if (driver) {
                tag.marker.setMap(null);
            }else{
                newPositionTags.push(tag);
            };
        });

        this.setPositionTags(newPositionTags);
    }
    createMarker = async (pos,map, iconUrl, label)=>{

        let marker = new window.google.maps.Marker({
            position: pos.position,
            map: map,
            id:pos.userId,
            animation:pos.animation,
            icon:iconUrl,
            label:label
        });


        var driverInfo = await this.getInfoDriver(pos.userId);
        pos.driverInfo = {...driverInfo, LastPosition: pos.position};
        pos.isOpen = true;

        let lng= (pos.driverInfo?.LastPosition?.lng) ? parseFloat(pos.driverInfo.LastPosition.lng).toFixed(5) :  "Not informed" ;
        let lat=  (pos.driverInfo?.LastPosition?.lat) ? parseFloat(pos.driverInfo.LastPosition.lat).toFixed(5) :  "Not informed" ;

        const infowindow = new window.google.maps.InfoWindow({
            content:`<div className="info-window-body" style="color:black">
            <div>CAR ${pos.driverInfo?.CarNumber || "Not informed"}</div>
            <div>PILOT ${pos.driverInfo?.Pilot || "Not informed"}</div>
            <div>COPILOT ${pos.driverInfo?.Copilot || "Not informed"}</div>
            <div>LAST KNOWN LOCATION ${lat};${lng}</div>
            </div>`,
        });


        this.setInfoDriverWindowsTags([...this.infoDriverWindowsTagsRef.current, { tag: infowindow, user : {
            userId: pos.userId,
            carNumber: pos.driverInfo?.CarNumber,
            pilot: pos.driverInfo?.Pilot,
            copilot: pos.driverInfo?.Copilot,
        }}]);
   
        marker.addListener("click", async () => {

            
            this.toggleBounce(pos,this.driverPositionsRef.current);

            infowindow.open({
                anchor: marker,
                map,
                title: "Rally"
            });
            //this.buildPositionTags(positions, map);
        });

        return {marker,id:pos.userId};
    }
}

import React, { useEffect, useState, useRef } from "react";
import { EditMap as EditMapOption } from "../core/EditMap";
import { MapService } from "../service/MapService";
import CustomGoogleMap from "../components/CustomGoogleMap";
import { SendMessageDriverModal } from "../components/modals/SendMessageDriverModal";
import { RedFlagConfirmModal } from "../components/modals/RedFlagConfirmModal";
import { MapMenu } from "../components/menus/MapMenu";
import { TrackerMenu } from "../components/menus/TrackerMenu";
import { DriverPositionTracker } from "../core/DriverPositionTracker";
import { DrawRectangle } from "../core/DrawRectangle";
import socket from "./Socket";
import { Toast } from "primereact/toast";
import { StageMarker } from "../core/StageMarker";
import { Flag_Codes } from "../utilities/const";
//import { FlagMarker } from "../core/FlagMarker";
import moment from "moment";
import { ALERT_TYPES } from "../consts/alertTypes";
import audio from "../audios/alarm.redflag.mp3";
import { TokenAuthService } from "../service/TokenAuthService";
import { ClubService } from "../service/ClubService";
import { OfflineDrivers } from "./OfflineDrivers";
import { MapTypes } from "./MapTypes";
import { Utils } from "../utilities/Utils";

const LIBRARIES = ["drawing"];
const google = (window.google = window.google ? window.google : {});
const HIDE_TEXT = "Hide POIs";
const SHOW_TEXT = "Show POIs";
const SELECTED_ICON = "selectedIcon.svg";
const RED_FLAG_CODE = Flag_Codes["RED_FLAG"];
const SATELLITE_MAP = "satellite";
const BLOCK_CLICK = false;
var map = null;
const INTERVAL_TIME_TO_GET_OFFLINE_CARS = 2000;

const DEFAULT_ZOOM = parseInt(process.env.REACT_APP_DEFAULT_ZOOM);
const DEFAULT_MAP_POSITION = { lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT), lng: parseFloat(process.env.REACT_APP_DEFAULT_LNG) };
const STROKE_COLOR = "#d6ff22";
const OFFLINE_CAR_TIME = process.env.OFFLINE_CAR_TIME_IN_SECONDS || 180;

export const DriverTracker = (props) => {
    let format = Utils.getFormDate();
    var mapService = new MapService();
    var clubService = new ClubService();

    const toast = useRef(null);

    var bounceAnimation = google.maps?.animation?.BOUNCE || 1;

    const googleMapOptions = {
        scrollwheel: true,
        scaleControl: false,
        mapTypeControl: false,
        draggable: true,
        zoomControl: true,
        disableDefaultUI: true,
        mapId: "42e6b7328c70c8d1",
        mapTypeId: "satellite",
    };

    const drawingOptions = {
        drawingControl: false,
        polylineOptions: {
            editable: false,
            draggable: false,
            geodesic: false,
            strokeColor: "#d6ff22",
            clickable: false,
        },
    };

    const [defaultCenterPositions, setDefaultCenterPositions] = useState(DEFAULT_MAP_POSITION);
    const [defaultZoom, setDefaultZoom] = useState(DEFAULT_ZOOM);
    const [centerPositions, setCenterPositions] = useState(DEFAULT_MAP_POSITION);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [mapTypeId, setMapTypeId] = useState(SATELLITE_MAP);
    const [currentZoom, setCurrentZoom] = useState(DEFAULT_ZOOM);
    const [strokeColor, setStrokeColor] = useState(STROKE_COLOR);
    const [drawingMode, setDrawingMode] = useState(null);
    const [driverPositions, setDriverPositions] = useState([]);
    const [positionTags, setPositionTags] = useState([]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [selectedCircuit, setSelectedCircuit] = useState({});
    const [modalState, setModalState] = useState(false);
    const [modalConfirRedFlag, setModalConfirRedFlag] = useState(false);
    const [polylineTags, setPolylineTags] = useState([]);
    const [infoWindowsTags, setInfoWindowsTags] = useState([]);
    const [stageTags, setStageTags] = useState([]);
    const [flagTags, setFlagTags] = useState([]);
    const [gmt, setGmt] = useState(null);
    const [poiHideShowText, setPoiHideShowText] = useState(HIDE_TEXT);
    const [circuits, setCircuits] = useState([]);
    const [infoDriverWindowsTags, setInfoDriverWindowsTags] = useState([]);

    //ref
    const positionTagsRef = useRef(positionTags);
    const driverPositionsRef = useRef(driverPositions);
    const [showTrackerMenu, setShowTrackerMenu] = useState(true);
    const showTrackerMenuRef = useRef(showTrackerMenu);
    const currentZoomRef = useRef(currentZoom);
    const circuitsRef = useRef(circuits);
    const infoDriverWindowsTagsRef = useRef(infoDriverWindowsTags);
    const strokeColorRef = useRef(strokeColor);

    //POI
    const [poiValues, setPoiValues] = useState([]);
    const [poiTags, setPoiTags] = useState([]);

    //Event
    const [eventId, setEventId] = useState(props.match.params.id);

    const [offlineDriversObj, setOfflineDriversObj] = useState();

    var driverTracker = new DriverPositionTracker(
        mapService,
        eventId,
        (data) => {
            setDriverPositions(data);
            !offlineDriversObj &&
                setOfflineDriversObj(
                    data.reduce((acc, driver) => {
                        acc[driver.userId] = true;
                        return acc;
                    }, {})
                );
        },
        setPositionTags,
        positionTags,
        SELECTED_ICON,
        bounceAnimation,
        positionTagsRef,
        driverPositionsRef,
        circuitsRef,
        setInfoDriverWindowsTags,
        infoDriverWindowsTagsRef
    );
    var drawRectangle = new DrawRectangle(setDrawingMode);
    var initialMap = new EditMapOption(
        BLOCK_CLICK,
        eventId,
        mapService,
        setPolylineTags,
        setInfoWindowsTags,
        setPoiTags,
        setPoiValues,
        (title, message, type) => showToast(title, message, type),
        strokeColorRef,
        (route, routes) => selectedRoute(route, routes)
    );
    //var stageMarkers = new StageMarker(setStageTags);
    //var flagMarkers = new FlagMarker(setFlagTags);

    //Menu
    const [showSendMessageMenu, setShowSendMessageMenu] = useState(false);

    const [isDirector, setIsDirector] = useState(false);

    //Offline cars
    const [offlineCollapsed, setOfflineCollapsed] = useState(true);
    const [offlineCars, setOfflineCars] = useState([]);
    const [carsTotal, setCarsTotal] = useState({ total: 0, offlineCars: 0 });

    //Interval
    const [startInterval, setStartInterval] = useState(false);

    useEffect(async () => {
        let tokenAuthService = new TokenAuthService();
        let isDirector = tokenAuthService.isDirector();
        setIsDirector(isDirector);
    }, []);

    useEffect(() => {
        positionTagsRef.current = positionTags;
    }, [positionTags]);

    useEffect(() => {
        driverPositionsRef.current = driverPositions;
    }, [driverPositions]);

    useEffect(() => {
        showTrackerMenuRef.current = showTrackerMenu;
    }, [showTrackerMenu]);

    useEffect(() => {
        currentZoomRef.current = currentZoom;
    }, [currentZoomRef]);

    useEffect(() => {
        circuitsRef.current = circuits;
    }, [circuits]);

    useEffect(() => {
        infoDriverWindowsTagsRef.current = infoDriverWindowsTags;
    }, [infoDriverWindowsTags]);

    useEffect(() => {
        strokeColorRef.current = strokeColor;
    }, [strokeColor]);

    console.log(offlineDriversObj)

    useEffect(() => {
        socket.on("BACKOFFICE", async (result) => {
            // eslint-disable-next-line default-case
            switch (result.type) {
                case "CHANGE_POSITION":
                    if (result.data.eventId == eventId) {
                        console.log("CHANGE_POSITION", result.data);
                        driverTracker.setSelectedRoute(selectedCircuit);
                        setOfflineDriversObj((prev = {}) => ({ ...prev, [result.data.userId]: false }))
                        driverTracker.setDriverTraker(result.data, driverPositions, map);
                        driverTracker.setContentOfInfoDriverWindows(result.data, infoDriverWindowsTagsRef);
                    }
                    break;
                    case "DRIVER_ALERT":
                    if (result.data.eventId == eventId) {
                        var date = moment(new Date(result.data.date)).local().format(`${format} HH:mm:ss`);
                        var driver = `${result.data.driver.User.Name} ${result.data.driver.User.Surname}<br/> ${date}`;
                        var playAlarm = false;
                        var driverInfo = {};
                        var carAlertInfo = `alert`;

                        if (driverPositionsRef.current) {
                            driverInfo = driverPositionsRef.current.find((elem) => elem.userId == result.data.driver.User.Id).driverInfo;
                            carAlertInfo = `- ${driverInfo.Car} (${driverInfo.CarNumber})`;
                        }
                        switch (result.data.alertType) {
                            case ALERT_TYPES.MEDICAL:
                                showToast(`${driver}`, `Medical Alert ${carAlertInfo}`, "error", true);
                                playAlarm = true;
                                break;
                            case ALERT_TYPES.ROAD_BLOCKED:
                                showToast(`${driver}`, `Road Blocked Alert ${carAlertInfo}`, "success", true);
                                playAlarm = true;
                                break;
                            case ALERT_TYPES.FIRE:
                                showToast(`${driver}`, `Fire Alert ${carAlertInfo}`, "error", true);
                                playAlarm = true;
                                break;
                            case ALERT_TYPES.RC:
                                showToast(`${driver}`, `Road Clear Alert ${carAlertInfo}`, "success", true);
                                playAlarm = true;
                                break;
                            case ALERT_TYPES.SOS:
                                showToast(`${driver}`, `S.O.S Alert ${carAlertInfo}`, "error", true);
                                playAlarm = true;
                                break;
                            case ALERT_TYPES.REDFLAGACK:
                                showToast(`${driver}`, `Red Flag akc Alert ${carAlertInfo}`, "error", true);
                                break;
                            case ALERT_TYPES.OK:
                                playAlarm = true;
                                showToast(`${driver}`, `Ok Alert ${carAlertInfo}`, "success", true);
                                break;
                            default:
                                showToast(`${driver}`, `CUSTOM MESSAGE`, "error", true);
                                break;
                        }

                        if (result.data.alertType != ALERT_TYPES.REDFLAGACK) {
                            await driverTracker.setDriverAlert(result.data, driverPositions, selectedCircuit, result.data.alertType, map);
                        }

                        if (playAlarm) {
                            var aud = new Audio(audio);
                            aud.play();
                        }
                    }
                break;
                    case "FLAG_CONFIRMED":
                        if(result.data.type === "REDFLAG"){
                            const messageId = RED_FLAG_CODE;
                            driverTracker.showRedCircleOnDrivers(driverPositions, selectedCircuit,selectedDrivers, messageId);
                        }
                    break;
            };

        });
        return () => {
            socket.off();
        };
    }, [driverPositions]);

    useEffect(() => {
        socket.on("connect_error", (e) => {
            setTimeout(() => {
                socket.connect();
                console.log("", "Server connection error, connecting...");
            }, 3000);
        });
    });

    useEffect(() => {
        var positions = driverPositions == null ? [] : driverPositions.filter((x) => x.animation != null);
        setSelectedDrivers([...positions]);
        if (positions.length > 0) {
            setShowSendMessageMenu(true);
        } else {
            setShowSendMessageMenu(false);
        }
    }, [driverPositions, setSelectedDrivers, setShowSendMessageMenu]);

    const removeDrivesFromMap = async (offlineDrivers) => {
        await driverTracker.removeDrivesFromMap(map, offlineDrivers, driverPositionsRef.current);
    };

    const setOfflineColor = async (offlineDrivers) => {
        await driverTracker.setOfflineColor(map, offlineDrivers, driverPositionsRef.current);
    };

    const initializeAll = async () => {
        let response = await initialMap.initializeCircuits();

        let zoom = response.mapPosition?.zoom || DEFAULT_ZOOM;
        let mapPosition = DEFAULT_MAP_POSITION;

        let club = await clubService.getClubByEventId(eventId);

        if (response.mapPosition?.lat) {
            mapPosition = { lat: parseFloat(response.mapPosition.lat), lng: parseFloat(response.mapPosition.lng) };
        } else if (club.cluLatitude && club.cluLongitude) {
            if (club.cluLatitude != "" && club.cluLongitude != "") {
                mapPosition = { lat: parseFloat(club.cluLongitude), lng: parseFloat(club.cluLatitude) };
            }
        }

        setCenterPositions(mapPosition);
        setDefaultCenterPositions(mapPosition);
        setDefaultZoom(zoom);
        setZoom(zoom);

        let routes = response.routes;
        setCircuits(routes);
        setGmt(response.gmt);
        //stageMarkers.setStageMarker(circuitsRef.current,zoom);
        if (poiHideShowText == HIDE_TEXT) {
            initialMap.initializePois();
        }
        setFlagTags([]);
        //var offlineDrivers = await mapService.getOfflineDrivers(eventId)
        //setOfflineCars(offlineDrivers.data.drivers);
        //setCarsTotal({total:offlineDrivers.data.total, offlineCars: offlineDrivers.data.drivers.length});
        setShowTrackerMenu(true);

        await driverTracker.initializeDriverTraker(map);
    };

    const selectedRoute = async (route, routes) => {
        var positions = route.Positions;
        var position = positions[Math.round(positions.length / 2)];
        driverTracker.setSelectedRoute(route);
        setZoom(16);
        setZoom(15);
        setCenterPositions({ lat: parseFloat(position.lat), lng: parseFloat(position.lng) });
        setSelectedCircuit(route);
        setShowTrackerMenu(true);

        //await driverTracker.initializeDriverTraker(map);
        //await initialMap.initializeCircuit(route.CircuitId);
        //setStageTags([]);
        //flagMarkers.buildPois(route);
    };

    const showToast = (message, driver, type, multiline = false) => {
        let toastOptions = {
            severity: type,
            summary: driver,
            detail: multiline ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message,
            life: 100000000,
        };
        toast.current.show(toastOptions);
    };

    const sendMessage = async (messageId, message) => {
        if (selectedDrivers.length == 0) {
            showToast(`You must choose at least one driver to send the message or a red flag.`, "", "warn");
            return;
        }

        console.log(messageId, " ", message);

        if (messageId == "802") {
            if (message == "") {
                showToast("You must enter a message.", "", "warn");
                return;
            }
        }

        try {
            var driverIdList = [];
            selectedDrivers.forEach((driver) => {
                driverIdList.push(driver.userId);
            });
            const payload = {
                StateId: messageId,
                Drivers: driverIdList,
                EventId: eventId,
                Message:message
            }

            await mapService.sendMessageToDrivers(payload);

            setSelectedDrivers([]);
            setShowSendMessageMenu(false);
            driverTracker.clearSelectedDrivers(driverPositions, selectedCircuit);
            setModalState(false);
        } catch (error) {
            console.log(error);
            showToast(``, "An error has occurred while trying to send the message.", "error");
        }
    };

    const hidePois = () => {
        if (poiTags.length == 0) {
            initialMap.initializePois();
            setPoiHideShowText(HIDE_TEXT);
        } else {
            setPoiTags([]);
            setPoiHideShowText(SHOW_TEXT);
        }
    };

    const enableRectangle = () => {
        drawRectangle.setRectangleMode();
    };

    const clearSelectedDrivers = () => {
        setSelectedDrivers([]);
        driverTracker.clearSelectedDrivers(driverPositions, selectedCircuit);
        setShowSendMessageMenu(false);
    };

    const backTrackerMenu = async () => {
        //await initializeAll();
        //setZoom(defaultZoom);
        //setCenterPositions(defaultCenterPositions);
        //driverTracker.clearDriverTraker();
        //setDriverPositions([]);
        //setShowTrackerMenu(false);
        return props.history.push("/events");
    };

    const sendRedFlag = async () => {
        sendMessage(RED_FLAG_CODE, "");
        setModalConfirRedFlag(false);
    };

    const drawingOverlayComplete = async (e) => {
        if (e.type != window.google.maps.drawing.OverlayType.RECTANGLE) return;
        var onlySelect = true;
        drawRectangle.drawingOverlayComplete(e, driverPositions, async (userId, driverPositions) => {
            driverTracker.setSelectedRoute(selectedCircuit);
            await driverTracker.toggleBounce(userId, driverPositions, onlySelect);
        });
        setDrawingMode(null);
    };

    const cleanFlags = async () => {
        await driverTracker.cleanFlags(driverPositions, selectedCircuit);
    };

    const backToPreviousPage = async () => {
        //window.history.back();
        return props.history.push("/events");
    };

    const handleLoad = React.useCallback(async function callback(newMap) {
        map = newMap;
        await initializeAll();
        setStartInterval(true);
    }, []);

    const onZoomChanged = () => {
        if (map) {
            //stageMarkers.setStageMarker(circuitsRef.current,map.getZoom());
        }
    };

    useEffect(() => {
        if (startInterval) {
            var idInterval = setInterval(async () => {
                let offlineDrivers = [];
                driverPositions.map((driver) => {
                    const momentAddDate = moment(driver.position.addDate);
                    const fechaActual = moment();
                    const diffInSeconds = fechaActual.diff(momentAddDate, "seconds");
                    if (diffInSeconds > OFFLINE_CAR_TIME) {
                        offlineDrivers.push(driver);

                    }
                });
                setOfflineDriversObj((prevState = {}) => 
                    offlineDrivers.reduce((acc, driver) => {
                        acc[driver.userId] = true;
                        return acc;
                    }, {...prevState})
                );
                setOfflineCars(offlineDrivers);
                setCarsTotal({ total: driverPositions.length, offlineCars: offlineDrivers.length });
                await setOfflineColor(offlineDrivers);
                // await removeDrivesFromMap(offlineDrivers.data.drivers);
            }, INTERVAL_TIME_TO_GET_OFFLINE_CARS);
        }
        return () => clearInterval(idInterval);
    }, [driverPositions, setOfflineColor, startInterval]);

    const refresh = async () => {
        await initializeAll();
    };

    return (
        <>
            <MapMenu
                hidePois={() => {
                    hidePois();
                }}
                poiHideShowText={poiHideShowText}
                showTrackerMenu={showTrackerMenu}
                gmt={gmt}
                backToPreviousPage={backToPreviousPage}
            />
            <TrackerMenu
                hidePois={() => {
                    hidePois();
                }}
                poiHideShowText={poiHideShowText}
                sendMessage={() => {
                    setModalState(true);
                }}
                sendRedFlag={() => {
                    setModalConfirRedFlag(true);
                }}
                // sendRedFlag={() => {sendRedFlag()}}
                showMenu={showSendMessageMenu}
                isDirector={isDirector}
                showTrackerMenu={showTrackerMenu}
                back={() => backTrackerMenu()}
                route={selectedCircuit}
                enableRectangle={enableRectangle}
                clearSelectedDrivers={clearSelectedDrivers}
                gmt={gmt}
                cleanFlags={cleanFlags}
            />
            <CustomGoogleMap
                googleMapId={"drawing-manager-example"}
                libraries={LIBRARIES}
                zoom={zoom}
                googleOptions={{ ...googleMapOptions, mapTypeId: mapTypeId }}
                centerPositions={centerPositions}
                drawingOptions={drawingOptions}
                drawingMode={drawingMode}
                poiTags={[poiTags, stageTags]}
                polylineTags={polylineTags}
                flagTags={flagTags}
                onLoad={handleLoad}
                drawingOverlayComplete={drawingOverlayComplete}
                infoWindowsTags={infoWindowsTags}
                onZoomChanged={onZoomChanged}
            />
            <SendMessageDriverModal modalState={modalState} setModalState={setModalState} sendMessage={(messageId, message) => sendMessage(messageId, message)} />
            <RedFlagConfirmModal modalState={modalConfirRedFlag} setModalState={setModalConfirRedFlag} sendRedFlag={() => sendRedFlag()} />
            <Toast ref={toast} />
            <OfflineDrivers
                offlineCollapsed={offlineCollapsed}
                setOfflineCollapsed={setOfflineCollapsed}
                offlineCars={offlineCars}
                carsTotal={{ offlineCars: Object.values(offlineDriversObj || {}).filter((el) => el).length, total: driverPositions.length }}
                //showTrackerMenu={showTrackerMenu}
            ></OfflineDrivers>
            <MapTypes setMapTypeId={setMapTypeId} setStrokeColor={setStrokeColor} refresh={refresh}></MapTypes>
        </>
    );
};

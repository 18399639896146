import { Button } from "primereact/button";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const MapMenu = (props) => {
    //const [timer, setTimer] = useState(0);
    const [timer, setTimer] = useState(moment().format("HH:mm:ss"));

    useEffect(async () => {
        // let gmt = props.gmt;
        // if(!gmt)
        //     return;
        // var id = setInterval(() => {setTimer(moment().utc().add(gmt.GMT, 'hours').format('HH:mm:ss'))},1000);
        var id = setInterval(() => {
            setTimer(moment().format("HH:mm:ss"));
        }, 1000);
        return () => {
            clearInterval(id);
        };
    }, [setTimer, props.gmt]);

    return (
        <>
            {!props.showTrackerMenu && (
                <div className="menu-edit-map p-jc-between">
                    <div className="p-d-flex p-ai-center">
                        <Button icon="pi pi-chevron-left" className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3" onClick={props.backToPreviousPage} />
                        <Button label={props.poiHideShowText} className="btn-outline-invert  p-mr-2" onClick={() => props.hidePois()} />
                    </div>
                    {timer}
                </div>
            )}
        </>
    );
};

import React, { useRef, useEffect, useState } from "react";
import { GoogleMap, LoadScript, DrawingManager, Marker, Polyline, InfoWindow } from "@react-google-maps/api";

const containerStyle = {
    // width: "100%",

    width: "calc(100% + 2rem)",
    height: "calc(100vh - 76px)",
    left: "-1rem",
};

const CustomGoogleMap = (props) => {
    return (
        <>
            {/* googleMapsApiKey="YOUR_API_KEY"  googleMapsApiKey="AIzaSyD9MzORO1NUALLUYVbLPeYsg2JQZX_XM2U"*/}
            <LoadScript 
                libraries={props.libraries}
                googleMapsApiKey={"AIzaSyDVcEEsTDL9NS9wuTHGC-4mL3homv_PTy4"}
                >
                <GoogleMap 
                    id={props.googleMapId}
                    onLoad={props.onLoad}
                    mapContainerStyle={containerStyle} 
                    center={props.centerPositions} 
                    zoom={props.zoom} 
                    onClick={props.handlerClickEvent} 
                    options={props.googleOptions}
                    onZoomChanged={props.onZoomChanged}>
                    <DrawingManager 
                        drawingMode={props.drawingMode} 
                        onOverlayComplete={props.drawingOverlayComplete} 
                        options={props.drawingOptions} 
                    />
                    {props.polylineTags}
                    {props.infoWindowsTags}
                    {props.poiTags}
                    {props.flagTags}
                </GoogleMap>
            </LoadScript>
        </>
    );
};

export default CustomGoogleMap;

import axios from "../utilities/api-axios"
import { Utils } from "../utilities/Utils"

const apiUrl = process.env.REACT_APP_API_URL;


export class EventService {
    // getEventsSmall() {
    //     return axios.get("assets/demo/data/products-small.json").then((res) => res.data.data);
    // }

    // getEventsWithOrdersSmall() {
    //     return axios.get("assets/demo/data/products-orders-small.json").then((res) => res.data.data);
    // }

    getEvents() {
        return axios.get(`${apiUrl}/events/`).then((res) => res.data);
    }

    getByClub(clubId) {
        return axios.get(`${apiUrl}/events/byClub/`+ clubId).then((res) => res.data);
    }

    async getLiveNow(clubId) {
        const res = await axios.get(`${apiUrl}/events/liveNow/`+ clubId)
        return res.data;
    }

    updateEvent(event) {


        // let eveStart = Utils.setOutGMT(event.eveStart);
        // let eveFinish = Utils.setOutGMT(event.eveFinish);

        const updEvent = {
                "EplId" : event.eplId,
                "VieId" : event.vieId,
                "StsId" : event.stsId,
                "Name" : event.eveName,
                "Start" : event.eveStart,
                "Finish" : event.eveFinish,
                "CluId": event.cluId,
                "CouId": event.couId,
                "State": event.eveState,
                "City": event.eveCity,
        }
        return axios.put(`${apiUrl}/events/` + event.eveId, updEvent).then((res) => res.data);
    }

    createEvent(event) {

        const newEvent = {
            "EplId" : event.eplId,
            "VieId" : event.vieId,
            "StsId" : event.stsId,
            "Name" : event.eveName,
            "Start" : event.eveStart,
            "Finish" : event.eveFinish,
            "CluId": event.cluId,
            "CouId": event.couId,
            "State": event.eveState,
            "City": event.eveCity,
    }

        return axios.post(`${apiUrl}/events/`, newEvent).then((res) => {
            return res.data;
        });
    }

    deleteEvent(id) {
        return axios.delete(`${apiUrl}/events/` + id, { _method: "DELETE" }).then((res) => {
            return res.data;
        });
    }

    publishEvent(id) {
        return axios.put(`${apiUrl}/events/publish/` + id).then((res) => {
            return res.data;
        });
    }

    getEvent(id) {
        return axios.get(`${apiUrl}/events/withClub/` + id).then((res) => {
            return res.data;
        });
    }

    getInterestPoints(id) {
        return axios.get(`${apiUrl}/events/interestPoints/` + id).then((res) => {
            return res.data;
        });
    }

    getSpecialStages(id) {
        return axios.get(`${apiUrl}/events/specialstages/` + id).then((res) => {
            return res.data;
        });
    }

    getDrivers(id) {
        return axios.get(`${apiUrl}/events/drivers/` + id).then((res) => res.data);
    }

    getFlags(eveId) {
        return axios.get(`${apiUrl}/events/flags/` + eveId).then((res) => res.data);
    }

    getAlerts(eveId) {
        return axios.get(`${apiUrl}/events/alerts/` + eveId).then((res) => res.data);
    }

    async updloadPolyline(file, eventId) {
        let formData = new FormData();
        formData.append('EventId', eventId);
        formData.append('file', file);
        return await axios.post(`${apiUrl}/commonServices/uploadPolylines`, formData);
    }
}

import axios from "axios";
import Cookies from "universal-cookie";
import { UserService } from "../service/UserService";
import jwt_decode from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;

export class TokenAuthService {

    async refreshToken() {
        const _refreshToken = this.getRefreshToken();
        const request = {
            refreshToken: _refreshToken
        };
        const res = await axios.post(`${apiUrl}/auth/refresh`, request);
        return res;
    }

    async authenticate(loginModel) {
        const res = await axios.post(`${apiUrl}/auth/login`, loginModel);

        const jdecode = jwt_decode(res.data.accessToken);
        // let current_time = Date.now() / 1000;
        let rol = jdecode.Roles.find((x) => x.Id === "3");

        if (rol) {
            throw new Error("Rol not allowed");
        }

        const cookies = new Cookies();
        cookies.set("gonni.token", res.data.accessToken, { path: "/" });
        cookies.set("gonni.refreshtoken", res.data.refreshToken, { path: "/" });
        let user = {
            id: res.data.id,
            name: res.data.name + " " + res.data.surname,
            roles: res.data.roles,
            avatar: jdecode.Avatar,
        };
        cookies.set("gonni.user", user, { path: "/" });
        /*validar primer uso*/
        let FirstAccess = jdecode.FirstAccess;
        let Userid = jdecode.Id;
        const userService = new UserService();

        // const userLoggued = await userService.get(Userid);
        if (FirstAccess) {
            return { changepassword: true, userId: Userid };
        } else {
            /* Pedir el club */
            if (this.isAdmin()) {
                cookies.remove("gonni.club");
            } else {
                const club = await userService.getMyClub();
                if (club) cookies.set("gonni.club", club, { path: "/" });
            }
        }

        return res.data;
        // });
    }
    updateAvatar(fileName){
        let _cookieeUser = this.getUser();
        const cookies = new Cookies();
        _cookieeUser.avatar = fileName;     
        cookies.set("gonni.user", _cookieeUser, { path: "/" });
    }


    logout() {
        const cookies = new Cookies();
        cookies.remove("gonni.token");
        cookies.remove("gonni.refreshtoken");
        cookies.remove("gonni.user");
        cookies.remove("gonni.club");
    }

    getToken() {
        const cookies = new Cookies();
        return cookies.get("gonni.token");
    }

    getRefreshToken() {
        const cookies = new Cookies();
        return cookies.get("gonni.refreshtoken");
    }

    getUser() {
        const cookies = new Cookies();
        return cookies.get("gonni.user");
    }

    getClub() {
        const cookies = new Cookies();
        return cookies.get("gonni.club");
    }

    isAdmin() {
        const cookies = new Cookies();
        const user = cookies.get("gonni.user");
        if (!(user && user.roles)) return false;
        return user.roles.find((el) => el.id === "1") ? true : false;
    }
    isPlanner() {
        const cookies = new Cookies();
        const user = cookies.get("gonni.user");
        if (!(user && user.roles)) return false;
        return user.roles.find((el) => el.id === "2") ? true : false;
    }
    isDriver() {
        const cookies = new Cookies();
        const user = cookies.get("gonni.user");
        if (!(user && user.roles)) return false;
        return user.roles.find((el) => el.id === "3") ? true : false;
    }
    isViewer() {
        const cookies = new Cookies();
        const user = cookies.get("gonni.user");
        if (!(user && user.roles)) return false;
        return user.roles.find((el) => el.id === "5") ? true : false;
    }
    isDirector() {
        const cookies = new Cookies();
        const user = cookies.get("gonni.user");
        if (!(user && user.roles)) return false;
        return user.roles.find((el) => el.id === "4") ? true : false;
    }
}

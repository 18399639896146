import { Dialog } from 'primereact/dialog';
import React,{createRef,useEffect} from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Button } from "primereact/button";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var refStage =  createRef();

export const NewpolylineModal = (props) => {

    var language = navigator.language || navigator.userLanguage;
    let dateFormat = "mm/dd/yy";

    if (language.toLowerCase().indexOf("es") != -1) {
        dateFormat = "dd/mm/yy";
    };

    useEffect(async () => {
        props.setPolylineStages([]);
    }, []);

    const formatDate = (d) => {
        var utcDate = null;
        if (d)
        {
            var date = new Date(d);
            var utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        }
        return utcDate;
    };


    function InputList(props) {
        const stages = props.polylineStages;
        const listItems = stages.map((stage, index) =>{
            var startDate = null;
            var hours = (stage.StartTime) ? stage.StartTime.split(":"): null;
            if (hours) {
                var startDate = new Date(2000,11,11,parseInt(hours[0]),parseInt(hours[1]),0);
            }

            return(<div key={index} className="p-grid">
                    {index == 0 &&
                        <>
                            <div className="p-col-2">
                                <label className="fs-medium">Stage</label>
                            </div>
                            <div className="p-col-4">
                                <label className="fs-medium">Start date</label>
                            </div>
                            <div className="p-col-4">
                                <label className="fs-medium">Start time</label>
                            </div>
                            <div className="p-col-2"></div>
                        </>
                    }
                    <div className="p-col-2">
                        {stage.Name}
                    </div>
                    <div key={"date"+index} className="p-field p-col-4">
                        <Calendar id="startDate" value={stage.StartDate ? new Date(stage.StartDate) : new Date()} dateFormat={dateFormat} onChange={(e) => changeStartDate(e.value, props.polylineStages, props.setPolylineStages, stage.Name)} required showIcon={true} />
                    </div>
                    <div key={"time"+index} className="p-field p-col-4">
                            <DatePicker
                                  selected={startDate}
                                  onChange={(e) =>changeStartTime(e, props.polylineStages, props.setPolylineStages, stage.Name)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  className="p-inputtext p-component"
                            />
                    </div>
                    <div className="p-col-2">
                        <Button label="Delete" className="btn-secondary " onClick={() => deleteStage(stage.Name)} />
                    </div>
                </div>)
        });
        return (<>{listItems}</>);
    }

    const changeStartTime = (value, polylineStages, setPolylineStages, stageName) => {
        var newStagesArray = [...polylineStages]
        var stage = newStagesArray.find(x => x.Name == stageName)
        stage.StartTime = moment(value).format('HH:mm:ss');
        setPolylineStages([...newStagesArray]);
    }

    const changeStartDate = (value, polylineStages, setPolylineStages, stageName) => {
        var newStagesArray = [...polylineStages];
        var stage = newStagesArray.find(x => x.Name == stageName)
        stage.StartDate = new Date(value).toISOString()
        setPolylineStages([...newStagesArray]);
    }

    const changePosition = (value) => {
        props.setDetailsNewPolyline({...props.detailsNewPolyline, flagPosition:value});
    };

    const deleteStage = (stageName) => {
        var setPolylineStages = props.setPolylineStages;
        var polylineStages = props.polylineStages;
        var stage = polylineStages.find(x => x.Name.toString() == stageName.toString());
        var stageIndex = polylineStages.indexOf(stage);
        var stages = [...polylineStages];
        stages.splice(stageIndex,1);
        setPolylineStages([...stages]);
    }

    const addInput = () => {

        props.setErrorMessage("");
        if (!refStage.current)
            return;

        if (!refStage.current.value)
            return;

        if (isNaN(refStage.current.value)){
            props.setErrorMessage("The stage must a number not a caracter");
            return;
        }

        var setPolylineStages = props.setPolylineStages;
        var polylineStages = props.polylineStages;

        var stage = polylineStages.find(x => x.Name.toString() == refStage.current.value.toString());

        if (stage){
            props.setErrorMessage("The stage number that you have entered already exists.");
            return;
        }

        setPolylineStages([...polylineStages,
            {
                Name : refStage.current.value,
                StartDate: new Date().toISOString(),
                StartTime: null
            }])
    };
    return(<Dialog
                header="Polyline details"
                visible={props.newPolylineModal}
                style={{ width: '70vw' }}
                className="p-fluid"
                footer={props.newPolyline.polylineFooter}
                contentClassName="overflow-y-visible"
                onHide={() => props.newPolyline.setNewPolylineModal(false)}>
                <div className="p-grid" style={{paddingBottom:50}}>
                    <div className="p-field p-col-6">
                        <label htmlFor="segment" className="p-d-block">
                            Segment name
                        </label>
                        <input type="text" placeholder="Circuit Name" defaultValue={props.detailsNewPolyline.circuitName} ref={props.detailsNewPolyline.inputPolylineName} className="p-inputtext p-component" />
                    </div>

                    {/* <div className="p-field p-col-6">
                        <label htmlFor="segment" className="p-d-block">
                            Select point
                        </label>

                        <Dropdown
                            options={flagOptions}
                            value={setPositionInitialValue()}
                            onChange={(e) => {
                                changePosition(e.value);
                            }}
                            optionLabel="label"
                            optionValue="value"
                        />
                    </div>*/}
                    <div className="p-field p-col-6">
                        <label htmlFor="stage" className="p-d-block">
                            Stage number
                        </label>
                        <div className="p-inputgroup">
                            <input className="p-inputtext p-component" type="text" ref={refStage} />
                            <Button
                                onClick={(e) => {
                                    addInput();
                                }}
                                className="p-button-icon-only p-mr-3 plus-icon-width"
                                style={{ backgroundColor: "#181f26" }}
                            >
                                <span className="pi pi-plus p-c"></span>&nbsp;Add Stage
                            </Button>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <InputList polylineStages={props.polylineStages} setPolylineStages={props.setPolylineStages}/>
                    </div>
                    {(props.erroMessage != "") &&
                        <div className="p-col-12">
                            {props.erroMessage}
                        </div>
                    }
                </div>
        </Dialog>)
}

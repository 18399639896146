import { Dialog } from "primereact/dialog";
import React, { createRef, useEffect, useState } from "react";
import moment from "moment";
import { Button } from "primereact/button";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

var refStage = createRef();

export const SelectPolylineModal = (props) => {

    var language = navigator.language || navigator.userLanguage;
    let dateFormat = "mm/dd/yy";

    if (language.toLowerCase().indexOf("es") != -1) {
        dateFormat = "dd/mm/yy";
    };

    const changeStartTime = (value, detailsPolyline, setDetailsPolyline, stageName) => {
        var newStagesArray = [...detailsPolyline.stages];
        var stage = newStagesArray.find((x) => x.Name == stageName);
        stage.StartTime = moment(value).format('HH:mm:ss');
        setDetailsPolyline({ ...detailsPolyline, stages: [...newStagesArray] });
    };

    const changeStartDate = (value, detailsPolyline, setDetailsPolyline, stageName) => {
        var newStagesArray = [...detailsPolyline.stages];
        var stage = newStagesArray.find((x) => x.Name == stageName);
        stage.StartDate = new Date(value).toISOString()
        setDetailsPolyline({ ...detailsPolyline, stages: [...newStagesArray] });
    };

    const changePosition = (value, detailsPolyline, setDetailsPolyline) => {
        setDetailsPolyline({ ...detailsPolyline, flagPosition: value });
    };

    const formatDate = (d) => {
        var utcDate = null;
        if (d)
        {
            var date = new Date(d);
            var utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        }
        return utcDate;
    };

    /*const formatDate = (d) => {

        console.log(d)
        var utcDate = null;
        if (d)
        {
            var dateArray = d.split("/");

            if (dateArray.length == 3) {  
                if (dateFormat == "mm/dd/yy") {
                    utcDate = new Date(dateArray[2], parseInt(dateArray[1]), parseInt(dateArray[0]) - 1);
                }else{
                    utcDate = new Date(dateArray[2], parseInt(dateArray[0]) - 1, parseInt(dateArray[1]));
                }
                
            }else{
                var date = new Date(d);
                utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());                
            }

        }
        return utcDate;
    };*/

    function InputList(props) {
        const stages = props.detailsPolyline.stages;
        const listItems = stages.map((stage, index) => {
            console.log(stage.StartDate, formatDate(stage.StartDate))
            var startDate = null;
            var hours = (stage.StartTime) ? stage.StartTime.split(":"): null;
            if (hours) {
                var startDate = new Date(2000,11,11,parseInt(hours[0]),parseInt(hours[1]),0);
            }
            return (
                <div key={index} className="p-grid">
                    {index == 0 && (
                        <>
                            <div className="p-col-2">
                                <label className="fs-medium">Stage</label>
                            </div>
                            <div className="p-col-4">
                                <label className="fs-medium">Start date</label>
                            </div>
                            <div className="p-col-4">
                                <label className="fs-medium">Start time</label>
                            </div>
                            <div className="p-col-2"></div>
                        </>
                    )}
                    <div className="p-col-2">{stage.Name}</div>
                    <div className="p-field  p-col-4">
                        <Calendar
                            id="startDate"
                            value={new Date(stage.StartDate)}
                            dateFormat={dateFormat}
                            onChange={(e) => changeStartDate(e.value, props.detailsPolyline, props.setDetailsPolyline, stage.Name)}
                            required showIcon={true} />
                    </div>
                    <div className="p-field  p-col-4">
                            {/*<Select
                            options={timeOptions}
                            value={timeOptions.find((x) => x.value == stage.StartTime)}
                            onChange={(e) => {
                                changeStartTime(e.value, props.detailsPolyline, props.setDetailsPolyline, stage.Name);
                            }}/>

                            <Dropdown
                                options={timeOptions}
                                value={stage.StartTime}
                                onChange={(e) => {
                                    changeStartTime(e.value, props.detailsPolyline, props.setDetailsPolyline, stage.Name);
                                }}
                                optionLabel="label"
                                optionValue="value"
                            />*/}

                            <DatePicker
                                  selected={startDate}
                                  onChange={(e) =>changeStartTime(e, props.detailsPolyline, props.setDetailsPolyline, stage.Name)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="HH:mm"
                                  timeFormat="HH:mm"
                                  className="p-inputtext p-component"
                            />

                        {/* //*Agreggo componente de tiempo * /}
                        <Calendar id="startTime" value={stage.StartTime} onChange={(e) =>changeStartTime(e.value, props.detailsPolyline, props.setDetailsPolyline, stage.Name)} timeOnly hourFormat="24" />
                        */}
                    </div>
                    <div className="p-col-2">
                        <Button label="Delete" className="btn-secondary" onClick={() => deleteStage(stage.Name)} />
                    </div>
                </div>
            );
        });
        return <>{listItems}</>;
    }

    const addInput = () => {
        props.setSelectErrorMessage("");
        if (!refStage.current) return;

        if (!refStage.current.value) return;

        if (isNaN(refStage.current.value)) {
            props.setSelectErrorMessage("The stage must be a number not a caracter");
            return;
        }

        var stages = [...props.detailsPolyline.stages];

        var existStage = stages.filter((x) => x.Name == refStage.current.value);

        if (existStage.length != 0) {
            props.setSelectErrorMessage("The stage must be different from the existing ones.");
            return;
        }

        stages.push({ Name: parseInt(refStage.current.value) });

        var detail = { ...props.detailsPolyline, stages: stages };
        props.setDetailsPolyline(detail);
    };

    const deleteStage = (stageName) => {
        props.setSelectErrorMessage("");
        var setDetailsPolyline = props.setDetailsPolyline;
        var detailsPolyline = props.detailsPolyline;

        var oldStages = [...detailsPolyline.stages];

        var stage = oldStages.find((x) => x.Name.toString() == stageName.toString());
        var stageIndex = oldStages.indexOf(stage);
        oldStages.splice(stageIndex, 1);

        if (oldStages.length == 0) {
            props.setSelectErrorMessage("Must have at least one stage");
            return;
        }
        var detail = { ...detailsPolyline, stages: oldStages };
        setDetailsPolyline(detail);
    };

    const setPositionInitialValue = () => {
        var position = props.detailsPolyline.flagPosition ? props.detailsPolyline.flagPosition : "F";
        return position;
    };

    return (
        <Dialog header="Polilyne details" visible={props.polylineModal} style={{ width: "70vw" }} className="p-fluid" footer={props.selectPolyline.polylineFooter} onHide={() => props.setPolylineModal(false)} contentClassName="overflow-y-visible">
            <div className="p-grid" style={{ paddingBottom: 50 }}>
                <div className="p-field p-col-6">
                    <label htmlFor="segment" className="p-d-block">
                        Segment name
                    </label>
                    <input type="text" placeholder="Circuit Name" defaultValue={props.detailsPolyline.circuitName} ref={props.detailsPolyline.inputPolylineName} className="p-inputtext p-component" />
                </div>

                {/* <div className="p-field p-col-6">
                    <label htmlFor="segment" className="p-d-block">
                        Select point
                    </label>

                    <Dropdown
                        options={flagOptions}
                        value={setPositionInitialValue()}
                        onChange={(e) => {
                            changePosition(e.value, props.detailsPolyline, props.setDetailsPolyline);
                        }}
                        // id="flag"
                        optionLabel="label"
                        optionValue="value"
                    />
                </div>*/}

                <div className="p-field p-col-6">
                    <label htmlFor="stage" className="p-d-block">
                        Stage number
                    </label>

                    <div className="p-inputgroup">
                        <input className="p-inputtext p-component" type="text" ref={refStage} />
                        <Button
                            onClick={(e) => {
                                addInput(props.detailsPolyline, props.setDetailsPolyline);
                            }}
                            className=" p-button-icon-only p-mr-3 plus-icon-width"
                            style={{ backgroundColor: "#181f26" }}
                        >
                            <span class="pi pi-plus p-c"></span>&nbsp;Add Stage
                        </Button>
                    </div>
                </div>
                <div className="p-col-12">
                    <InputList detailsPolyline={props.detailsPolyline} setDetailsPolyline={props.setDetailsPolyline} />
                </div>
                {props.selectErrorMessage != "" && <div className="p-col-12">{props.selectErrorMessage}</div>}
            </div>
        </Dialog>
    );
};

import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// import { Calendar } from "primereact/calendar";
// import { InputNumber } from "primereact/inputnumber";

import { $ } from "react-jquery-plugin";

// import { Utils } from "../utilities/Utils";
import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse, ErrorDialogNoAuthorizedSet } from "../../dialogs/ErrorDialog";
import { UserService } from "../../service/UserService";

export const NamePersonalEditDialog = (props) => {
    let emptyUser = {
        useId: null,
        useName: "",
        useSurname: "",
        useEmail: "",
        usePhone: "",
        RolesId: [],
    };

    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [user, setUser] = useState(emptyUser);

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            setSubmitted(false);

            setVisible(props.visibleDialog);

            setUser(props.user);

            setLoading(false);
        };
        load();
    }, [props.visibleDialog, props.user]);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    // const onInputChange = (e, name) => {
    //     const val = (e && e.value) || "";
    //     let _item = { ...user };
    //     _item[`${name}`] = val;

    //     setUser(_item);
    // };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _user = { ...user };
        _user[`${name}`] = val;

        setUser(_user);
    };

    const validateRequired = () => {
        for (let k = 0; k < $("[Required]").length; k++) {
            if (!$("[Required]")[k].value) return false;
        }
        return true;
    };

    const closeCancel = () => {
        props.onClose(null);
    };
    const closeOk = (_item) => {
        setSubmitted(false);
        props.onClose(_item);
    };
    const cancel = () => {
        closeCancel();
    };

    const saveItem = async () => {
        setSubmitted(true);

        try {
            if (validateRequired()) {
                let _item = { ...user };

                if (_item.useId) {
                    let userService = new UserService();
                    userService
                        .updateName(_item)
                        .then(() => {
                            closeOk(_item);
                        })
                        .catch((res) => {
                            setStateErrorDialog(ErrorDialogSetResponse(res));
                        });
                }
                // else {
                //     let userService = new userService();
                //     userService
                //         .createLicense(_item)
                //         .then((data) => {
                //             _license["LicId"] = data.licId;
                //             setLicense(_license);
                //             closeOk(_license);
                //         })
                //         .catch((res) => {
                //             setStateErrorDialog(ErrorDialogSetResponse(res));
                //         });
                // }
            }
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };

    const eventDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={cancel} />
            <Button label="Save changes" className="btn-primary" onClick={saveItem} />
        </>
    );
    return (
        <>
            <Dialog visible={visible} style={{ width: "650px" }} header="Edit personal details" modal className="p-fluid" footer={eventDialogFooter} contentClassName="overflow-y-visible" onHide={closeCancel}>
                <div className="p-field">
                    <label htmlFor="firstname">First Name</label>
                    <InputText id="firstname" maxLength="100" value={user.useName} onChange={(e) => onInputChange(e, "useName")} required="true" autoFocus className={classNames({ "p-invalid": submitted && !user.useName })} />
                    {submitted && !user.useName && <small className="p-invalid">First Name is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="lastname">Last Name</label>
                    <InputText id="lastname" maxLength="100" value={user.useSurname} onChange={(e) => onInputChange(e, "useSurname")} required className={classNames({ "p-invalid": submitted && !user.useSurname })} />
                    {submitted && !user.useSurname && <small className="p-invalid">Last Name is required.</small>}
                </div>
            </Dialog>

            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </>
    );
};

import { Button } from "primereact/button";

export const SelectPolylineMenu = (props) => {
    function StagesList(props) {
        var stages = props.stages;
        const listItems = stages.map((stage, index) => <span key={index}>{stage.Name} </span>);
        return <>{listItems}</>;
    }
    return (
        <div className="menu-edit-map p-jc-between">
            <Button icon="pi pi-chevron-left" className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3" onClick={props.backButton} />
            {props.show && (
                <>
                    <div className="p-d-flex p-ai-center p-mr-auto">
                        <p className="fs-x-normal ">
                            {props.circuitName} SS <StagesList stages={props.stages} />
                        </p>
                        <Button label="Edit details" icon="pi pi-pencil" className="btn-outline-invert p-mx-5 " onClick={props.editDetails} />
                        {/* <Button icon="pi pi-pencil" className="p-button-rounded p-rounded-small  p-button-outlined p-button-icon-only p-mr-3" onClick={() => alert("Programar")} />
                        <Button icon="pi pi-plus" className="p-button-rounded  p-rounded-small p-button-outlined p-button-icon-only" onClick={() => alert("Programar")} /> */}
                        <input type="text" placeholder="Latitude" ref={props.referencePoint.latInput} className="p-inputtext p-component p-mr-2" />
                        <input type="text" placeholder="Longitude" ref={props.referencePoint.lngInput} className="p-inputtext p-component p-mr-2" />
                        <div className={"pi pi-fw pi-search p-mr-2"} style={{ cursor: "pointer" }} onClick={props.findPoint}></div>
                    </div>

                    <div className="p-d-flex p-ai-center">
                        <Button label="Undo" className="btn-outline-invert  p-mr-2" onClick={props.selectPolyline} />
                        <Button label="Save changes" className="btn-primary-invert " onClick={props.saveChanges} />
                    </div>
                </>
            )}
        </div>
    );
};

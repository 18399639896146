import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { $ } from "react-jquery-plugin";

import { ClubService } from "../service/ClubService";
import { EventService } from "../service/EventService";
import { TokenAuthService } from "../service/TokenAuthService";
import { CountryService } from "../service/CountryService";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse } from "../dialogs/ErrorDialog";

export const EventDialog = (props) => {
    let emptyEvent = {
        eveId: null,
        stsId: null,
        eveName: "",
        eveStart: null,
        eveFinish: null,
        eveUpddate: null,
        eveDeleted: false,
        eveUpdUserId: null,
        cluId: null,
        couId: null,
        eveState: "",
        eveCity: "",
    };

    var language = navigator.language || navigator.userLanguage;
    let dateFormat = "mm/dd/yy";

    if (language.toLowerCase().indexOf("es") != -1) {
        dateFormat = "dd/mm/yy";
    };

    const [visible, setVisible] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const [event, setEvent] = useState(emptyEvent);
    const [clubs, setClubs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState(null);

    const auth = new TokenAuthService();

    useEffect(() => {
        try {
            const load = async () => {
                setLoading(true);
                setVisible(props.visibleDialog);
                setSubmitted(false);

                const countryService = new CountryService();
                const resCountries = await countryService.getCountries();
                setCountries(resCountries);

                const clubService = new ClubService();
                const resClubs = await clubService.getClubsIdDescription();
                setClubs(resClubs);

                let _event = { ...props.event };
                setEvent(_event);
                setLoading(false);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    }, [props]);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    const saveItem = () => {
        setSubmitted(true);

        if (!validateRequired()) {
            return;
        }

        try {
            let _event = { ...event };
            if (!auth.isAdmin()) {
                let club = auth.getClub();
                _event.cluId = club.Id;
            }

            if (event.eveId) {
                let eventService = new EventService();
                eventService
                    .updateEvent(_event)
                    .then((data) => {
                        closeOk(_event);
                    })
                    .catch((res) => {
                        setStateErrorDialog(ErrorDialogSetResponse(res));
                    });
            } else {
                let eventService = new EventService();
                eventService
                    .createEvent(_event)
                    .then((data) => {
                        _event.eveId = data.eveId;
                        _event.stsId = data.stsId;
                        closeOk(_event);
                    })
                    .catch((res) => {
                        setStateErrorDialog(ErrorDialogSetResponse(res));
                    });
            }
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _event = { ...event };
        _event[`${name}`] = val;

        setEvent(_event);
    };
    const validateRequired = () => {
        for (let k = 0; k < $("[Required]").length; k++) {
            if (!$("[Required]")[k].value) return false;
        }
        return true;
    };
    const formatDate = (d) => {
        if (d) d = new Date(d);
        return d;
    };

    const closeCancel = () => {
        props.onClose(null);
    };
    const closeOk = (_item) => {
        //alert("Hide");
        setSubmitted(false);
        props.onClose(_item);
    };
    const cancel = () => {
        setEvent(emptyEvent);
        closeCancel();
    };

    const dialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={cancel} />
            <Button label="Save" className="btn-primary " onClick={saveItem} />
        </>
    );

    return (
        <>
            <Dialog visible={visible} style={{ width: "450px" }} header={event.eveId ? "Event Details" : "Create new event"} modal className="p-fluid" footer={dialogFooter} onHide={closeCancel}>
                <div className="p-field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" maxLength="100" value={event.eveName} onChange={(e) => onInputChange(e, "eveName")} required className={classNames({ "p-invalid": submitted && !event.eveName })} />
                    {submitted && !event.eveName && <small className="p-invalid">Name is required.</small>}
                </div>
                {auth.isAdmin() && (
                    <div className="p-field">
                        <label htmlFor="club">Institution</label>
                        <Dropdown id="club" optionLabel="Description" optionValue="Id" value={event.cluId} options={clubs} onChange={(e) => onInputChange(e, "cluId")} required className={classNames({ "p-invalid": submitted && !event.cluId })} />
                        {submitted && !event.cluId && <small className="p-invalid">Institution is required.</small>}
                    </div>
                )}

                <hr />
                <p className="fs-m-normal p-mb-4">Location</p>

                <div className="p-grid">
                    <div className="p-field p-col-6">
                        <label htmlFor="country">Country</label>
                        <Dropdown id="country" optionLabel="couName" optionValue="couId" value={event.couId} options={countries} onChange={(e) => onInputChange(e, "couId")} required className={classNames({ "p-invalid": submitted && !event.couId })} />
                        {submitted && !event.couId && <small className="p-invalid">Country is required.</small>}
                    </div>
                    <div className="p-field p-col-6">
                        <label htmlFor="name">State</label>
                        <InputText id="state" maxLength="100" value={event.eveState} onChange={(e) => onInputChange(e, "eveState")} required className={classNames({ "p-invalid": submitted && !event.eveState })} />
                        {submitted && !event.eveState && <small className="p-invalid">State/Province is required.</small>}
                    </div>
                </div>

                <div className="p-field">
                    <label htmlFor="name">City</label>
                    <InputText id="city" maxLength="100" value={event.eveCity} onChange={(e) => onInputChange(e, "eveCity")} required className={classNames({ "p-invalid": submitted && !event.eveCity })} />
                    {submitted && !event.eveCity && <small className="p-invalid">City is required.</small>}
                </div>

                <hr />
                <p className="fs-m-normal p-mb-4">Date</p>

                <div className="p-grid">
                    <div className="p-field p-col-6">
                        <label htmlFor="start">Start</label>
                        <Calendar id="start" value={formatDate(event.eveStart)} dateFormat={dateFormat} onChange={(e) => onInputChange(e, "eveStart")} required showIcon={true} className={classNames({ "p-invalid": submitted && !event.eveStart })} />
                        {submitted && !event.eveStart && <small className="p-invalid">Start is required.</small>}
                    </div>
                    <div className="p-field p-col-6">
                        <label htmlFor="finish">Finish</label>
                        <Calendar id="finish" value={formatDate(event.eveFinish)} dateFormat={dateFormat} onChange={(e) => onInputChange(e, "eveFinish")} required showIcon={true} className={classNames({ "p-invalid": submitted && !event.eveFinish })} />
                        {submitted && !event.eveFinish && <small className="p-invalid">Finish is required.</small>}
                    </div>
                </div>
            </Dialog>

            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </>
    );
};

import React, { useState, useEffect, useRef } from "react";

const STROKE_COLOR = "#d6ff22";
export const MapTypes = (props) => {
    const [color, setColor] = useState(STROKE_COLOR); 

    const changeMapId = (mapId) => {
        props.setMapTypeId(mapId);
        if (mapId == "satellite") {
            props.setStrokeColor(STROKE_COLOR);
            setColor(STROKE_COLOR);
            
        } else {
            props.setStrokeColor("#000000");
            setColor("#000000");
        }
        props.refresh();
    }

    return (<>
        {
            <div className="container-map-types"  >
                <img alt="Satellite" src={"../../satellite.jpg"} style={{border: `1px solid ${color}`}} onClick={() => changeMapId("satellite")}/>
                <img alt="Roadmap" src={"../../roadmap.png"}  style={{border: `1px solid ${color}`}} onClick={() => changeMapId("roadmap")}/>
                <img alt="Terrain" src={"../../terrain.jpg"}  style={{border: `1px solid ${color}`}} onClick={() => changeMapId("terrain")}/>
            </div>        
        }
</>);
}
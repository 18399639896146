import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

export const NewPolylineMenu = (props) => {

    const importPolilyne = async ({ files }) => {
        props.importPolyline(files);
    };


    return (
        <div className="menu-edit-map p-jc-between">
            <div className="p-d-flex p-ai-center">
                <Button icon="pi pi-chevron-left" className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3" onClick={props.back} />
                <p className="fs-x-normal p-mr-2">New polyline</p>
                <input type="text" placeholder="Latitude" ref={props.referencePoint.latInput} className="p-inputtext p-component p-mr-2" />  
                <input type="text" placeholder="Longitude"  ref={props.referencePoint.lngInput} className="p-inputtext p-component p-mr-2" /> 
                <div className={"pi pi-fw pi-search"} style={{ cursor: "pointer"}} onClick={props.findPoint}></div>
                {/* <Button label="Import polyline" icon="pi pi-sign-in" className="btn-outline-invert p-mx-5" onClick={props.insert} /> */}

                {/* <FileUpload
                                    // onUpload={importCSV}
                                    uploadHandler={importPolilyne}
                                    customUpload={true}
                                    mode="basic"
                                    auto={true}
                                    chooseLabel="Import polyline"
                                    icon="pi pi-sign-in"
                                    label="Import polyline"
                                    className="btn-outline-invert p-mx-5"
                                /> */}

                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-rounded-small  p-button-outlined p-button-icon-only p-mr-3" onClick={() => alert("Programar")} />
                <Button icon="pi pi-plus" className="p-button-rounded  p-rounded-small p-button-outlined p-button-icon-only" onClick={() => alert("Programar")} /> */}
            </div>
            <div className="p-d-flex p-ai-center">
                <Button label="Cancel" className="btn-outline-invert  p-mr-2" onClick={props.cancel} />
                <Button label="Continue" className="btn-primary-invert" onClick={props.savePolyline} />

            </div>
        </div>
    );
};

import { EditMap as EditMapOption } from "../core/EditMap";
import { ReferencePoint as ReferencePointMarker } from "../core/ReferencePoint";
import React, { useEffect, useState, createRef, useRef } from "react";
import { DrawingMenu } from "../components/menus/DrawingMenu";
import { NewPolylineMenu } from "../components/menus/NewPolylineMenu";
import { NewPoiMenu } from "../components/menus/NewPoiMenu";
import { SelectPolyline } from "../core/SelectPolyline";
import { NewPoiModal } from "../components/modals/NewPoiModal";
import { NewpolylineModal } from "../components/modals/NewpolylineModal";
import { SelectPolylineModal } from "../components/modals/SelectPolylineModal";
import { SelectPolylineMenu } from "../components/menus/SelectPolylineMenu";
import { NewPoi } from "../core/NewPoi";
import { NewPolyline } from "../core/NewPolyline";
import CustomGoogleMap from "../components/CustomGoogleMap";
import { MapService } from "../service/MapService";
import { ClubService } from "../service/ClubService";
import { EventService } from "../service/EventService";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

const LIBRARIES = ["drawing", "geometry"];
const DEFAULT_ZOOM = parseInt(process.env.REACT_APP_DEFAULT_ZOOM);
const DEFAULT_MAP_POSITION = { lat: parseFloat(process.env.REACT_APP_DEFAULT_LAT), lng: parseFloat(process.env.REACT_APP_DEFAULT_LNG) };
const STROKE_COLOR = "#d6ff22"; 
export const EditMap = (props) => {
    var mapService = new MapService();
    var clubService = new ClubService();
    const drawingOptions = {
        drawingControl: false,
        polylineOptions: {
            editable: true,
            draggable: true,
            geodesic: true,
            clickable: true,
            strokeColor: "#d6ff22",
            strokeWeight: 4
        },
    };

    //Map
    const [centerPositions, setCenterPositions] = useState(DEFAULT_MAP_POSITION);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [drawingMode, setDrawingMode] = useState(null);
    const [polylineStages, setPolylineStages] = useState([]);
    const toast = useRef(null);
    const [googleMapOptions, setGoogleMapOptions] = useState({
        scrollwheel: true,
        scaleControl: false,
        mapTypeControl: false,
        draggable: true,
        zoomControl: true,
        disableDefaultUI: true,
        mapId: "26e5544cff477d51",
        mapTypeId:'satellite',
        draggableCursor:null,
    });

    const [circuits, setCircuits] = useState([]);
    const [polylineTags, setPolylineTags] = useState([]);
    const [infoWindowsTags, setInfoWindowsTags] = useState([]);
    const [poiTags, setPoiTags] = useState([]);
    const [poiValues, setPoiValues] = useState([]);
    const [polylineModal, setPolylineModal] = useState(false);
    const [poiModalState, setPoiModalState] = useState(false);
    const [newPolylineModal, setNewPolylineModal] = useState(false);
    const [selectedPolyline, setSelectedPolyline] = useState({});
    const [showMenu, setShowMenu] = useState(false);
    const [poiDetails, setPoiDetails] = useState({});
    const [infoIndex, setInfoIndex] = useState(0);
    const [deleteCircuitDialog, setDeleteCircuitDialog] = useState(false);
    const [deleteCircuit, setDeleteCircuit] = useState(0);

    const [detailsPolyline, setDetailsPolyline] = useState({
        circuitName: "",
        inputPolylineName: createRef(),
        estimated: "",
        stages: [],
        flagPosition:""
    });

    const [detailsNewPolyline, setDetailsNewPolyline] = useState({
        circuitName: "",
        inputPolylineName: createRef(),
        estimated: "",
    });

    const [referencePoint, setReferencePoint] = useState({
        latInput: createRef(),
        lngInput: createRef(),
        tags: []
    });

    //Map
    const [map, setMap] = useState(null);
    
    //Menu
    const [enableEditMap, setEnableEditMap] = useState(true);
    const [enableSelect, setEnableSelect] = useState(false);
    const [enableNewpoi, setEnableNewpoi] = useState(false);
    const [enableNewpolyline, setEnableNewpolyline] = useState(false);
    const [shape, setShape] = useState(undefined);
    const [polylineErrorMessage, setPolylineErrorMessage] = useState("");
    const [selectErrorMessage, setSelectErrorMessage] = useState("");

    //Event
    const [eventId, setEventId] = useState(props.match.params.id);

    //ref
    const mapRef = useRef(map);

    const BLOCK_CLICK = false;
    var initialMap = new EditMapOption(BLOCK_CLICK,eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues,(title,message,type) => showToast(title, message, type), STROKE_COLOR, () => {});

    var selectPolyline = new SelectPolyline(eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues, setPolylineModal, setSelectedPolyline, setShowMenu, setDetailsPolyline, setDrawingMode, selectedPolyline, showMenu, detailsPolyline, setSelectErrorMessage,
        (title,message,type) => showToast(title, message, type),
        () => {
            back();
        },
        setDeleteCircuitDialog,
        deleteCircuit,
        setDeleteCircuit);

    var newPolyline = new NewPolyline(eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues, setNewPolylineModal, setDetailsNewPolyline, detailsNewPolyline, setDrawingMode, setShape, shape, polylineStages,setPolylineErrorMessage,
        (title,message,type) => showToast(title, message, type),
        () => {
        back();
    });

    var newPoi = new NewPoi(eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues, setPoiModalState, poiValues, poiDetails, setInfoIndex, infoIndex, setPoiDetails,
            (title,message,type) => showToast(title, message, type),
            () => {
            back();
        });

    var ReferenceMarker = new ReferencePointMarker(referencePoint, setReferencePoint);

    useEffect(() => {
        mapRef.current = map;
    }, [map]);

    useEffect(async () => {
        let response = await initialMap.initializeCircuits();
        let zoom = response.mapPosition.zoom || DEFAULT_ZOOM;
        let mapPosition = DEFAULT_MAP_POSITION;

        let club = await clubService.getClubByEventId(eventId);

        if (response.mapPosition?.lat) {
            mapPosition = { lat: parseFloat(response.mapPosition.lat), lng: parseFloat(response.mapPosition.lng) };
        }else if(club.cluLatitude && club.cluLongitude){
            if (club.cluLatitude != "" && club.cluLongitude != "") {
                mapPosition = { lat: parseFloat(club.cluLongitude), lng: parseFloat(club.cluLatitude) };
            }         
        }

        setCenterPositions(mapPosition);
        setZoom(zoom);
        initialMap.initializePois();
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            newPolyline.handleEscapeKeyDown(e);
        });
        return () => {
            window.removeEventListener("keydown", (e) => {
                newPolyline.handleEscapeKeyDown(e);
            });
        };
    }, []);

    const select = () => {

        closeAll();
        setEnableSelect(true);
        setPolylineTags([]);
        setInfoWindowsTags([]);
        setPoiTags([]);
        setShowMenu(false);
        selectPolyline.setGoogle(window.google);
        selectPolyline.initializeCircuits();
    };

    const newpoi = async () => {
        closeAll();
        setEnableNewpoi(true);
        setPolylineTags([]);
        setInfoWindowsTags([]);
        setPoiTags([]);
        var response = await initialMap.initializeCircuits();
        setCircuits(response.routes);
        newPoi.initializePois();
        selectPolyline.setGoogle(window.google);
        setGoogleMapOptions({...googleMapOptions, draggableCursor:"crosshair"});
    };

    const newpolyline = () => {
        closeAll();
        setEnableNewpolyline(true);
        setPolylineTags([]);
        setInfoWindowsTags([]);
        setPoiTags([]);

        newPolyline.initializeNewPolyline();
        newPolyline.setGoogle(window.google);
    };

    const importpolyline = async(file) => {
        try{
            const eventService = new EventService();
            var response = await eventService.updloadPolyline(file, eventId);
            showToast(``, 'Polyline was loaded', "success" );

            newpolyline();
           
            let coordinates = response.data.coordinates.map(item => item.split(","));

            setCenterPositions({ lat: parseFloat(coordinates[0][1]), lng: parseFloat(coordinates[0][0]) });
            
            newPolyline.drawPolyline(coordinates, response.data.name, response.data.stages, mapRef.current, setPolylineStages)

            //let response = await initialMap.initializeCircuits();
            
        }catch(res){
            console.log(res);
            let message = "An error occurred trying to decode the file";
            if (res.response && res.response.data && res.response.data.statusCode == 418) message = res.response.data.message;
            showToast(``, message, "error" );
        }
    };

    const closeAll = () => {
        setEnableEditMap(false);
        setEnableSelect(false);
        setEnableNewpoi(false);
        setEnableNewpolyline(false);
        setDrawingMode(null);
        setGoogleMapOptions({...googleMapOptions, draggableCursor:null});
        setReferencePoint({...referencePoint, tags: []});
    };

    const back = async () => {
        closeAll();
        setEnableEditMap(true);
        var response = await initialMap.initializeCircuits();
        setCircuits(response.routes);
        initialMap.initializePois();
    };

    const drawingOverlayComplete = (e) => {
        if (!enableNewpolyline) return;
        if(e.type != window.google.maps.drawing.OverlayType.POLYLINE) return;
        newPolyline.drawingOverlayComplete(e);
    };

    const handlerClickEvent = (e) => {
        if (enableNewpoi) {
            newPoi.handlerClickEvent(e, circuits);
        }
    };

    const showToast = (message, summary, type) => {
        toast.current.show({ severity: type, summary: summary, detail: message, life: 10000});
    };

    const backToPreviousPage = async () => {
        window.history.back();
    }

    const handleLoad = React.useCallback(function callback(newMap) {
        setMap(newMap);
    }, [])

    const setMapPosition = async() => {
        let zoom = mapRef.current.getZoom();
        let mapPosition = mapRef.current.getCenter()
        await mapService.SetMapByEventId(
            {
                eventId,
                position: {lat:mapPosition.lat(),lng:mapPosition.lng()},
                zoom
            });

        showToast(`MAP POSITION UPDATED`, "", "success" );
    };

    const findReferencePoint = () => {
        let lat = referencePoint.latInput.current.value;
        let lng = referencePoint.lngInput.current.value;
        console.log((validateDecimal(lat)));

        if (validateDecimal(lat) && validateDecimal(lat)) {
            showToast(``, "The coordinates entered are incorrect", "error" );
        }else{
            ReferenceMarker.drawPointOnMap(lat, lng);
            let mapPosition = {lat: parseFloat(lat), lng: parseFloat(lng)};
            setCenterPositions(mapPosition);            
        }
    }

    function validateDecimal(valor) {
        var RE = /^\d*\.?\d*$/;
        if (RE.test(valor)) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {enableEditMap && (
                <>
                    <DrawingMenu
                        select={select}
                        newpoi={newpoi}
                        newpolyline={newpolyline}
                        importpolyline={importpolyline}
                        backToPreviousPage={backToPreviousPage}
                        setMapPosition={setMapPosition}
                    />
                </>
            )}
            {enableSelect && (
                <>
                    <SelectPolylineMenu
                        backButton={(e) => {
                            back();
                        }}
                        circuitName={detailsPolyline.circuitName}
                        editDetails={(e) => {
                            selectPolyline.editDetails();
                        }}
                        selectPolyline={(e) => {
                            selectPolyline.selectPolyline();
                        }}
                        editRoute={(e) => {
                            selectPolyline.editRoute();
                        }}
                        saveChanges={async (e) => {
                            var response = await selectPolyline.saveChanges(e);
                            if (response.status) {
                                showToast(`UPDATED CIRCUIT`, response.message, "success" );
                            }else{
                                showToast(``, response.message, "error" );
                            };
                        }}
                        show={showMenu}
                        stages={detailsPolyline.stages}
                        referencePoint = {referencePoint} 
                        findPoint = {findReferencePoint} 
                    ></SelectPolylineMenu>
                    <SelectPolylineModal
                        selectErrorMessage={selectErrorMessage}
                        setSelectErrorMessage={setSelectErrorMessage}
                        selectPolyline={selectPolyline}
                        polylineModal={polylineModal}
                        setPolylineModal={setPolylineModal}
                        detailsPolyline={detailsPolyline}
                        setDetailsPolyline={setDetailsPolyline}
                    ></SelectPolylineModal>
                </>
            )}
            {enableNewpoi && (
                <>
                    <NewPoiMenu
                        back={(e) => {
                            back();
                        }}
                        insert={(e) => {
                            newPoi.insert();
                        }}
                        select={(e) => {
                            newPoi.select();
                        }}
                        exit={(e) => {
                            newPoi.exit();
                        }}
                        saveChanges={async (e) => {
                            var response = await newPoi.saveChanges();
                            if (response.status) {
                                showToast(``, response.message, "success" );
                                back();
                            }else{
                                showToast(``, response.message, "error" );
                            };
                        }}
                    ></NewPoiMenu>
                    <NewPoiModal 
                        poiModalState={poiModalState} 
                        newPoi={newPoi} 
                        setPoiModalState={setPoiModalState} 
                        poiDetails={poiDetails} 
                        setPoiDetails={setPoiDetails}>
                    </NewPoiModal>
                </>
            )}
            {enableNewpolyline && (
                <>
                    <NewPolylineMenu
                        back={(e) => {
                            newPolyline.back();
                            back();
                        }}
                        importPolyline={(files) => {
                            newPolyline.importPolyline(files);
                        }}
                        insert={() => {
                            newPolyline.insert();
                        }}
                        select={() => {
                            newPolyline.select();
                        }}
                        cancel={() => {
                            newPolyline.cancel();
                        }}
                        savePolyline={() => {
                            newPolyline.openModal();
                        }}
                        referencePoint = {referencePoint} 
                        findPoint = {findReferencePoint} 
                    ></NewPolylineMenu>
                    <NewpolylineModal
                        erroMessage={polylineErrorMessage}
                        setErrorMessage={setPolylineErrorMessage}
                        newPolyline={newPolyline}
                        newPolylineModal={newPolylineModal}
                        detailsNewPolyline={detailsNewPolyline}
                        setDetailsNewPolyline={setDetailsNewPolyline}
                        polylineStages={polylineStages}
                        setPolylineStages={setPolylineStages}>
                    </NewpolylineModal>
                </>
            )}
            <CustomGoogleMap
                googleMapId={"drawing-manager-example"}
                libraries={LIBRARIES}
                zoom={zoom}
                googleOptions={googleMapOptions}
                centerPositions={centerPositions}
                drawingOptions={drawingOptions}
                drawingMode={drawingMode}
                polylineTags={polylineTags}
                infoWindowsTags={infoWindowsTags}
                poiTags={[...poiTags, ...referencePoint.tags]}
                drawingOverlayComplete={drawingOverlayComplete}
                handlerClickEvent={handlerClickEvent}
                onLoad={handleLoad}
            ></CustomGoogleMap>
            <Toast ref={toast} />
            <Dialog
                    visible={deleteCircuitDialog}
                    className="p-dialog-small"
                    header="Delete event"
                    modal
                    footer={selectPolyline.deleteCircuitFooter}
                    onHide={setDeleteCircuitDialog}>
                        <div className="confirmation-content">
                            <span>
                                Are you sure you want to delete the circuit?
                            </span>
                        </div>
            </Dialog>
        </>
    );
};

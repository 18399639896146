import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppMenu } from "./AppMenu";
import { AppProfile } from "./AppProfile";

import { Dashboard } from "./components/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";

import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { TreeDemo } from "./components/TreeDemo";

import { DriverTracker } from "./pages/DriverTracker";
import { Events } from "./pages/Events";
import { Event } from "./pages/Event";
import { Drivers } from "./pages/Drivers";
import { Clubs } from "./pages/Clubs";
import { Club } from "./pages/Club";
import { Licensing } from "./pages/Licensing";
import { Login } from "./pages/Login";
import { LoginScreen } from "./pages/LoginScreen";
import { TokenAuthService } from "./service/TokenAuthService";
import { EditMap } from "./pages/EditMap";
import { PersonalDetails } from "./pages/PersonalDetails";

import { Crud } from "./pages/Crud";
import { Calendar } from "./pages/Calendar";
import { EmptyPage } from "./pages/EmptyPage";

import { DisplayDemo } from "./utilities/DisplayDemo";
import { ElevationDemo } from "./utilities/ElevationDemo";
import { GridDemo } from "./utilities/GridDemo";
import { TextDemo } from "./utilities/TextDemo";
import { TypographyDemo } from "./utilities/TypographyDemo";

import PrimeReact from "primereact/utils";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { type_roles } from "./utilities/const";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("dark");
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(false);

    const [logged, setLogged] = useState(false);
    const [userName, setUserName] = useState("");
    const [avatar, setAvatar] = useState("");
    const [dialogLogin, setDialogLogin] = useState(false);
    const [rol, setRol] = useState("");
    const [rolDescription, setRolDescription] = useState("");
    const [clubId, setClubId] = useState(null);
    const [clubName, setClubName] = useState("");

    const sidebar = useRef();
    let menuClick = false;

    // const type_roles = {
    //     "DRIVER": "Driver",
    //     "ADMIN": "SUPERADMIN",
    //     "VIEWER": "Event official",
    //     "DIRECTOR": "Clerk of course",
    //     "EVENT_PLANNER": "Event organizer"
    // };

    useEffect(() => {
        try {
            if (mobileMenuActive) {
                addClass(document.body, "body-overflow-hidden");
            } else {
                removeClass(document.body, "body-overflow-hidden");
            }
            let auth = new TokenAuthService();
            let user = auth.getUser();
            if (user) {
                setRol(user.roles[0].name);
                setRolDescription(type_roles[user.roles[0].name]);
                setUserName(user.name);
                setAvatar(user.avatar);
                setLogged(true);
                if (user.roles[0].name !== "ADMIN") {
                    let club = auth.getClub();
                    if (club) {
                        setClubId(club.Id);
                        setClubName(club.Name);
                    }
                } else {
                    setClubId(null);
                    setClubName("");
                }
            }
        } catch (error) {
            setLogged(false);
            alert(error.message);
            //alert("Application Error. Check with the administrator");
        }
    }, [mobileMenuActive]);

    const isAdmin = () => {};

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                setOverlayMenuActive((prevState) => !prevState);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };

    const menu = [
        { label: "Institutions", icon: "icon-users", to: "/clubs", disabled: !logged || rol !== "ADMIN" },
        { label: "Institution setting", icon: "icon-users", to: "/club/" + clubId, disabled: !logged || rol !== "EVENT_PLANNER" },
        { label: "Licensing", icon: "icon-licensing", to: "/licensing", disabled: !logged || rol !== "ADMIN" },
        { label: "Events", icon: "icon-calendar", to: "/events", disabled: !logged || rol === "DRIVER" },
        // { label: "Drivers", icon: "pi pi-fw pi-globe", to: "/drivers" },
        // { label: "Map", icon: "pi pi-fw pi-globe", to: `/map/${process.env.REACT_APP_TEST_EVENT_ID}`, disabled: !logged },
        {
            label: "Logout",
            icon: "pi pi-fw pi-external-link",
            command: () => {
                handleLogoutClick();
            },
        },
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === "static") return !staticMenuInactive;
            else if (layoutMode === "overlay") return overlayMenuActive;
            else return true;
        }

        return true;
    };

    const logo = layoutColorMode === "dark" ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
    });

    const sidebarClassName = classNames("layout-sidebar", {
        "layout-sidebar-dark": layoutColorMode === "dark",
        "layout-sidebar-light": layoutColorMode === "light",
    });

    const login = () => {
        setDialogLogin(true);
    };

    const handleLoginClick = () => {
        let userName = "";
        setDialogLogin(false);
        let auth = new TokenAuthService();
        let user = auth.getUser();
        if (user) {
            userName = user.name;
        }
        setUserName(userName);
        setLogged(true);
        document.location.href = "/";
    };

    const handleLogoutClick = () => {
        new TokenAuthService().logout();
        setLogged(false);
        setUserName("");
        document.location.href = "/";
    };

    if (!logged) {
        return <LoginScreen onLoggedIn={handleLoginClick}></LoginScreen>;
    }

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {/* <AppTopbar onToggleMenu={onToggleMenu} /> */}

            {/* logged deberia estar en True para mostrar Sidebar */}

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    {/* //*Comento logo de app
                        <div className="layout-logo">
                            <img alt="Logo" src={logo} />
                        </div>  */}
                    <div onClick={login}>
                        <AppProfile userName={userName} rolDescription={rolDescription} clubName={clubName} avatar={avatar} />
                    </div>

                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                    <div className="layout-sidebar-footer">
                        <div className="forgot-btn">
                            <a target="_blank" rel="noopener" href={"https://alder-alibi-ada.notion.site/Instructivos-de-uso-User-manual-19c54ea5ccdb44c59ba58edcbc5b7e05"}>
                                Help/Online Manual
                            </a>
                        </div>
                    </div>

                    {/* Footer Sidebar */}
                    <div className="layout-sidebar-footer">
                        <img src="assets/rally/footer-logo.png" className="logo" alt="Sirius Rally" />
                        <p className="fs-x-small">Powered by SIRIUS</p>
                        <p className="fs-x-small year">2021</p>
                    </div>
                </div>
            </CSSTransition>

            {/*  <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

            {/* <Route path="/" exact component={LoginScreen} /> */}
            <div className="layout-main">
                <Route path="/login" exact component={LoginScreen} />
                <Route path="/" exact component={Events} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/formlayout" component={FormLayoutDemo} />
                <Route path="/input" component={InputDemo} />
                <Route path="/floatlabel" component={FloatLabelDemo} />
                <Route path="/button" component={ButtonDemo} />
                <Route path="/table" component={TableDemo} />
                <Route path="/list" component={ListDemo} />
                <Route path="/tree" component={TreeDemo} />
                <Route path="/panel" component={PanelDemo} />
                <Route path="/overlay" component={OverlayDemo} />
                <Route path="/menu" component={MenuDemo} />
                <Route path="/messages" component={MessagesDemo} />
                <Route path="/file" component={FileDemo} />
                <Route path="/chart" component={ChartDemo} />
                <Route path="/misc" component={MiscDemo} />
                <Route path="/display" component={DisplayDemo} />
                <Route path="/elevation" component={ElevationDemo} />
                <Route path="/grid" component={GridDemo} />
                <Route path="/typography" component={TypographyDemo} />
                <Route path="/text" component={TextDemo} />
                <Route path="/calendar" component={Calendar} />
                <Route path="/crud" component={Crud} />
                <Route path="/empty" component={EmptyPage} />
                <Route path="/map/:id" component={DriverTracker} />
                <Route path="/events/:message?" component={Events} />
                <Route path="/event/:id" component={Event} />
                <Route path="/clubs/:message?" component={Clubs} />
                <Route path="/club/:id" component={Club} />
                <Route path="/licensing" component={Licensing} />
                <Route path="/drivers/:eveId/:isPast/:licenseId" component={Drivers} />
                <Route path="/login" component={Login} />
                <Route path="/personaldetails" component={PersonalDetails} />
                {/*<Route path="/selectpolyline/:id" component={SelectPolyline} />
                    <Route path="/newpoi/:id" component={NewPoi} />
                    <Route path="/newpolyline/:id" component={NewPolyline} />*/}
                <Route path="/drawing/:id" component={EditMap} />
            </div>

            {/* <Login visible={dialogLogin} onClose={closeLogin}></Login> */}

            {/* <AppFooter /> */}
        </div>
    );
};

export default App;

import moment from "moment";
const MIN_DISTANCE = 30; 

export class GoogleMapTool {

    isSamePosition(newPosition, oldPosition){
        const oldPoint = new window.google.maps.LatLng(oldPosition.lat, oldPosition.lng);
        const newPpoint = new window.google.maps.LatLng(newPosition.lat, newPosition.lng);

        var distance = window.google.maps.geometry.spherical.computeDistanceBetween(oldPoint, newPpoint);
 
        if (distance < MIN_DISTANCE) {
            return true
        }
        return false;
    }

    checkTimeHasPassed(lastUpdate, waitTime){
        var diffMinu = moment(new Date()).diff(moment(lastUpdate), 'seconds');
        if (diffMinu >= waitTime) {
            return true;
        }
        return false;
    }
}
import { Polyline, InfoWindow } from "@react-google-maps/api";
import React, { createRef } from "react";
import { Button } from "primereact/button";
import moment from "moment";
import { Utils } from "../utilities/Utils";

export class SelectPolyline {
    eventId;
    mapService;
    setPolylineTags;
    setInfoWindowsTags;
    setPoiTags;
    setPoiValues;
    setPolylineModal;
    setDetailsPolyline;
    selectedPolyline;
    setShowMenu;
    showMenu;
    detailsPolyline;
    funBack;
    setDrawingMode;
    setSelectErrorMessage;
    google;
    showToast;
    deleteCircuit;
    setDeleteCircuit;
    format;


    polylineOptions = {
        strokeColor: "#d6ff22",
        strokeWeight: 4
    };

    polylineFooter = (
        <div>
            <Button label="Cancel" className="btn-secondary" onClick={() => this.setPolylineModal(false)} />
            <Button label="Save" className="btn-primary" onClick={() => this.updateDeatilsPolyline()} />
        </div>
    );

    deleteCircuitFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={() =>  this.hideDeleteCircuitDialog(false)} />
            <Button label="Delete" className="btn-primary" onClick={async() =>  await this.deleteCircuitById()} />
        </>
    );

    constructor(eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues, setPolylineModal, setSelectedPolyline, setShowMenu, setDetailsPolyline, setDrawingMode, selectedPolyline, showMenu, detailsPolyline, setSelectErrorMessage, showToast,back, hideDeleteCircuitDialog,deleteCircuit,setDeleteCircuit) {
        this.eventId = eventId;
        this.mapService = mapService;
        this.setPolylineTags = setPolylineTags;
        this.setInfoWindowsTags = setInfoWindowsTags;
        this.setPoiTags = setPoiTags;
        this.setPoiValues = setPoiValues;
        this.setPolylineModal = setPolylineModal;
        this.selectedPolyline = selectedPolyline;
        this.setSelectedPolyline = setSelectedPolyline;
        this.setShowMenu = setShowMenu;
        this.showMenu = showMenu;
        this.setDetailsPolyline = setDetailsPolyline;
        this.detailsPolyline = detailsPolyline;
        this.funBack = back;
        this.setDrawingMode = setDrawingMode;
        this.setSelectErrorMessage = setSelectErrorMessage;
        this.showToast = showToast;
        this.hideDeleteCircuitDialog = hideDeleteCircuitDialog;
        this.setDeleteCircuit = setDeleteCircuit;
        this.deleteCircuit = deleteCircuit;
        this.format = Utils.getFormDate();
    }

    setGoogle(google) {
        this.google = google;
    }

    async initializeCircuits() {
        try {
            this.setSelectErrorMessage("");
            this.setDrawingMode(null);
            var response = await this.mapService.getRoutesByEventId(this.eventId);
            var routes = [];
            response.data.routes.forEach((route) => {
                routes.push({ ...route, openInfoWindow: false, editPolyline: false, ref: createRef(), showRoute: true });
            });
            this.buildPolyline(routes);
            this.buildInfoWindows(routes);
        } catch (error) {
            this.showToast(``, "An error has occurred trying to get  circuits", "error" );
            console.log(error);
        }
    }

    editRoute() {
        this.selectedPolyline.editPolyline = true;
        this.buildPolyline([this.selectedPolyline]);
    }

    editDetails() {
        this.setPolylineModal(true);
        this.setSelectErrorMessage("");
    }

    selectPolyline() {
        this.selectedPolyline.editPolyline = false;
        this.selectedPolyline.openInfoWindow = false;
        this.setSelectedPolyline(this.selectedPolyline);
        this.buildPolyline([this.selectedPolyline]);
        this.editRoute();
    }

    async saveChanges(e) {
        const paths = this.selectedPolyline.ref.current
            .getPath()
            .getArray()
            .map((latLng) => {
                return { lat: latLng.lat(), lng: latLng.lng() };
            });


        const poly = new window.google.maps.Polyline({
            path: paths,
        });
        var lengthInKm = window.google.maps.geometry.spherical.computeLength(poly.getPath()) / 1000;
        var kmWithTwoDecimals = Math.round(lengthInKm * 100) / 100;

        var request = {
            points: paths,
            circuitName: this.selectedPolyline.CircuitName,
            circuitId: this.selectedPolyline.CircuitId,
            stages: this.detailsPolyline.stages,
            eventId: this.eventId,
            routeId: this.selectedPolyline.RouteId,
            flagPosition: this.selectedPolyline.FlagPosition,
            kms: kmWithTwoDecimals,
        };

        try {
            var res = await this.mapService.updateByIdRoutes(request);
            this.setSelectErrorMessage("");
            this.setPolylineModal(false);
            this.funBack();
            return {status: true, message:"The circuit has been updated"};
                
        } catch (error) {
            console.log(error);
            var message = error.response?.data?.message || "A error has occured";
            this.setSelectErrorMessage(message);
            this.setPolylineModal(true);
            return {status: false, message:  message};
        }
    }

    handlerClickEvent(e) {}

    drawingOverlayComplete(e) {}

    showWindow(routes, route, state) {
        route.openInfoWindow = state;
        this.buildInfoWindows(routes);
    }

    async deletePolyline(routes, route) {
        try {
            this.setDeleteCircuit({circuitId:route.CircuitId, eventId: this.eventId});
            this.hideDeleteCircuitDialog(true);
        } catch (error) {
            this.showToast(``, "An error has occurred trying to delete the circuit", "error" );
        }
    }

    async deleteCircuitById() {
        try {
            this.setSelectErrorMessage("");
            await this.mapService.deleteCircuitById(this.deleteCircuit.circuitId, this.deleteCircuit.eventId);
            this.showToast(`DELETED CIRCUIT`, "The circuit has been deleted", "success" );
            this.hideDeleteCircuitDialog(false);
            this.funBack();
        } catch (error) {
            this.showToast(``, "An error has occurred trying to delete the circuit", "error" );
        }
    }

    removePoint(e, route) {
        if (!route.editPolyline) return;
        if (e.vertex !== undefined) {
            var path = route.ref.current.getPath();
            if (path.length > 2) {
                path.removeAt(e.vertex);
            }
        }
    }

    editPolyline(routes, route) {
        routes.forEach((route) => {
            route.showRoute = false;
            route.openInfoWindow = false;
        });
        route.showRoute = true;
        route.editPolyline = true;
        route.routeId = route.routeId;
        this.buildPolyline(routes);
        this.buildInfoWindows(routes);
        this.setShowMenu(true);
        this.setDetailsPolyline({
            ...this.detailsPolyline,
            circuitName: route.CircuitName,
            stages: route.Stages,
            routeId: route.routeId,
            flagPosition: route.FlagPosition,
        });
        this.setSelectedPolyline(route);
        this.selectedPolyline = route;
    }

    buildPolyline(routes) {
        const listItems = routes.map((route, index) => {
            var positions = route.Positions.map((route) => {
                return { lat: parseFloat(route.lat), lng: parseFloat(route.lng) };
            });

            return (
                route.showRoute && (
                    <Polyline
                        options={this.polylineOptions}
                        ref={route.ref}
                        key={index}
                        path={positions}
                        geodesic={route.editPolyline}
                        editable={route.editPolyline}
                        draggable={route.editPolyline}
                        clickable={route.editPolyline}
                        onMouseOver={() => {
                            this.showWindow(routes, route, true);
                        }}
                        onClick={(e) => {
                            this.showWindow(routes, route, true);
                            this.removePoint(e, route);
                        }}
                        onLoad={(e) => {
                            this.onLoad(e, route);
                        }}
                    />
                )
            );
        });
        this.setPolylineTags(listItems);
    }

    onLoad(polygon, route) {
        route.ref.current = polygon;
    }

    stagesList(stages) {
        const listItems = stages.map((stage, index) => (
            <span key={index} className="ss-tag">
                {stage.Name}
            </span>
        ));
        return <>{listItems}</>;
    }
    buildInfoWindows(routes) {
        const listItems = routes.map((route, index) => {
            var firstPosition;

            var positions = route.Positions.map((pos, i) => {
                if (i == 0) {
                    firstPosition = { lat: parseFloat(pos.lat), lng: parseFloat(pos.lng) };
                }
                return new window.google.maps.LatLng(parseFloat(pos.lat), parseFloat(pos.lng));
            });

            const poly = new window.google.maps.Polyline({
                path: positions,
            });

            var lengthInKm = window.google.maps.geometry.spherical.computeLength(poly.getPath()) / 1000;
            var kmWithTwoDecimals = Math.round(lengthInKm * 100) / 100;

            route.Kms = kmWithTwoDecimals;

            var fecha = "";
            if (route.Stages.length > 0) {
                if (route.Stages[0].StartDate) {
                    var date = new Date(route.Stages[0].StartDate);
                    fecha = moment(date).format(this.format);            
                }
            }



            return (
                !route.editPolyline &&
                route.openInfoWindow && (
                    <InfoWindow
                        key={index}
                        position={firstPosition}
                        onCloseClick={() => {
                            this.showWindow(routes, route, false);
                        }}
                    >
                        {
                            <div className="info-window-body">
                                <div className="p-d-flex">
                                    <div className="p-d-flex fs-small p-mr-2">SS {this.stagesList(route.Stages)}</div>
                                </div>

                                <div className="p-d-flex  p-jc-between p-ai-center p-mb-2">
                                    <p className="fs-normal txt-bold"> {route.CircuitName} </p>

                                    <div className="info-window-edit-container">
                                        <Button
                                            icon="pi pi-pencil"
                                            className="info-window-edit-btn"
                                            onClick={() => {
                                                this.editPolyline(routes, route);
                                            }}
                                        />

                                        <Button
                                            icon="pi pi-trash"
                                            className="info-window-edit-btn"
                                            onClick={() => {
                                                this.deletePolyline(routes, route);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="p-d-flex  p-ai-center p-mb-2">
                                    <div className="p-d-flex fs-small p-mr-2">{kmWithTwoDecimals} km (Approx)</div>
                                    <div className="p-d-flex fs-small">{fecha}</div>
                                </div>
                            </div>
                        }
                    </InfoWindow>
                )
            );
        });
        this.setInfoWindowsTags(listItems);
    }

    updateDeatilsPolyline() {
        this.setDetailsPolyline({ ...this.detailsPolyline, circuitName: this.detailsPolyline.inputPolylineName.current.value });
        this.setSelectedPolyline({ ...this.selectedPolyline, CircuitName: this.detailsPolyline.inputPolylineName.current.value, FlagPosition: this.detailsPolyline.flagPosition });
        this.setPolylineModal(false);
    }
}

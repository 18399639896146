import { Panel } from 'primereact/panel';

export const OfflineDrivers = (props) => {
    return (<>
            {(!props.showTrackerMenu) &&
                <div className="container-offline-driver">
                    <Panel header={`Offline cars ${props.carsTotal.offlineCars}/${props.carsTotal.total}`} toggleable collapsed={props.offlineCollapsed} onToggle={(e) => props.setOfflineCollapsed(e.value)}>
                        <ul className={"car-list"}>
                            {props.offlineCars.map((car,index) =>{
                                return <li className="map-item border-bottom" key={index}>CAR {car.carnumber} | {car.name} {car.surname}</li>
                            })}
                        </ul>     
                    </Panel>
                </div>        
            }
    </>);
}
import axios from "../utilities/api-axios"

const apiUrl = process.env.REACT_APP_API_URL;



export class DriverCategoriesService {
    async getDriverCategories() {
        const res = await axios.get(`${apiUrl}/driverCategories/`);
        return res.data;
    }

    async getDriverCategoriesIdDescription() {
        const res = await axios.get(`${apiUrl}/driverCategories/IdDescription`);
        return res.data;
    }

    getDriverCategory(id) {
        return axios.get(`${apiUrl}/products/`, id).then((res) => res.data.result);
    }
}

import React, { createRef, useRef, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Select from "react-select";
import { Flag_Codes } from "../../utilities/const";

var messageRef = createRef();

export const RedFlagConfirmModal = (props) => {

    const RED_FLAG = Flag_Codes["RED_FLAG"];

    var footer = (
        <div>
            <Button label="Cancel" className="btn-secondary" onClick={() => props.setModalState(false)} />
            <Button label="Send" className="btn-primary " onClick={(e) => sendMessage()} />
        </div>
    );

    const sendMessage = () => {

        props.sendRedFlag(RED_FLAG);
    };

    return (
        <Dialog header="Red Flag Confirm" className="p-fluid" visible={props.modalState} contentClassName="overflow-y-visible" footer={footer} onHide={() => props.setModalState(false)}>
            <span>
                Are you sure you want to send a red flag?
            </span>
        </Dialog>
    );
};

import axios from "../utilities/api-axios"

const apiUrl = process.env.REACT_APP_API_URL;



export class ClubService {
    async getClubs() {
        return axios.get(`${apiUrl}/clubs/`).then((res) => res.data);
    }
    
    async getClubByEventId(eventId) {
        return axios.get(`${apiUrl}/clubs/event/${eventId}`).then((res) => res.data);
    }

    async getClubsIdDescription() {
        return axios.get(`${apiUrl}/clubs/IdDescription/`).then((res) => res.data);
    }

    async updateClub(club) {
        const updClub = {
            CouId: club.couId,
            Name: club.cluName,
            Address: club.cluAddress,
            ContactName: club.cluContactName,
            ContactPhone: club.cluContactPhone,
            Mail: club.cluMail,
            Web: club.cluWeb,
            SocialNetwork: club.cluSocialNetwork,
            State: club.cluState,
            City: club.cluCity,
            Facebook: club.cluFacebook,
            Instagram: club.cluInstagram,
            Latitude: club.cluLatitude,
            Longitude: club.cluLongitude
        };
        const res = await axios.put(`${apiUrl}/clubs/` + club.cluId, updClub);
        return res.data;
    }

    async createClub(club, planner) {
        const newClub = {
            CouId: club.couId,
            Name: club.cluName,
            Address: club.cluAddress,
            ContactName: club.cluContactName,
            ContactPhone: club.cluContactPhone,
            Mail: club.cluMail,
            Web: club.cluWeb,
            SocialNetwork: club.cluSocialNetwork,
            State: club.cluState,
            City: club.cluCity,
            Facebook: club.cluFacebook,
            Instagram: club.cluInstagram,
            Latitude: club.cluLatitude,
            Longitude: club.cluLongitude
        };
        const newPlanner = {
            Email: planner.Email,
            Name: planner.FirstName,
            Surname: planner.LastName,
            Direction: "",
            Phone: planner.Phone,
        };
        const newClubPlanner = {
            club: newClub,
            user: newPlanner,
        };
        const res = await axios.post(`${apiUrl}/clubs/`, newClubPlanner);
        return res.data;
    }

    async deleteClub(id) {
        const res = await axios.delete(`${apiUrl}/clubs/` + id, { _method: "DELETE" });
        return res.data;
    }

    async getClub(id) {
        const res = await axios.get(`${apiUrl}/clubs/` + id);
        return res.data;
    }

    async getClubWithDrivers(id) {
        const res = await axios.get(`${apiUrl}/clubs/withdrivers/` + id);
        return res.data;
    }
}

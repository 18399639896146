import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { UserService } from "../../service/UserService";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse } from "../../dialogs/ErrorDialog";
import { Password } from "./Password";

export const CreateNewPassword = (props) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const isValid = () => {
        if (confirmPassword.length < 8 || confirmPassword.length > 20 || newPassword.length < 8 || newPassword.length > 20) {
            return false;
        }

        const validNewPassword = newPassword.match(/^(?=.*[A-Z].*)(?=.*[0-9].*)/);

        const validConfirmPassword = confirmPassword.match(/^(?=.*[A-Z].*)(?=.*[0-9].*)/);

        if (!validNewPassword || !validConfirmPassword) return false;

        return true;
    };

    const isMatch = () => {
        if (newPassword !== confirmPassword) {
            return false;
        }
        return true;
    };

    const onClickUpdatePassword = () => {
        let userService = new UserService();

        if (!(confirmPassword && newPassword && isValid() && isMatch())) {
            setSubmitted(true);
            return;
        }

        userService
            .updatePasswordByEmail(props.mail, newPassword)
            .then(() => {
                setSubmitted(true);
                props.onHandleUpdatePass();
                // if (confirmPassword && newPassword && isValid() && isMatch()) {
                //     props.onHandleUpdatePass();
                // }
            })
            .catch((error) => {
                let errormessage = "Invalid update password";
                if (error.response) {
                    errormessage = error.response.data.message;
                }
                setStateErrorDialog(ErrorDialogSet(true, errormessage));
            });
    };

    return (
        <div className="login-page">
            <div className="p-m-0 p-col-12 p-md-6 p-d-flex p-jc-center p-ai-center bg-clrDark800">
                <div className="form-login">
                    <h2 className="fs-big p-mb-3">Create new password</h2>
                    <p className=" p-mb-4 fs-medium">For security purposes, we need you to change the password to log into the app. Your password must be between 8 and 20 characters long, including a number and a capital letter </p>

                    <div className="p-field">
                        <label htmlFor="newpassword" className="p-d-block">
                            New password
                        </label>

                        <Password value={newPassword} onChange={setNewPassword} placeholder="New password" required={submitted} messageRequired="Password is required."></Password>

                        {/* <InputText
                            type="password"
                            id="newpassword"
                            value={newPassword}
                            placeholder="New password"
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                            maxLength="20"
                            // className={validationPassInput()}
                        /> */}
                    </div>

                    <div className="p-field p-mb-2">
                        <label htmlFor="confirm" className="p-d-block">
                            Repeat password
                        </label>
                        {/* <InputText type="password" id="confirm" maxLength="20" value={confirmPassword} placeholder="Repeat password" onChange={(e) => setConfirmPassword(e.target.value)} /> */}
                        <Password value={confirmPassword} onChange={setConfirmPassword} placeholder="Repeat password" required={submitted} messageRequired="Repeat password is required."></Password>
                    </div>
                    {submitted && !isValid() && <p className="p-invalid fs-x-small txt-clrAlert500">Your password must be between 8 and 20 characters long, including a number and a capital letter.</p>}
                    {!isMatch() && <p className="p-invalid fs-x-small txt-clrAlert500">Passwords do not match please check</p>}

                    <Button label="Update password and login" className="btn-primary  login-btn p-mt-4" onClick={onClickUpdatePassword} />
                    <Button label="Cancel" className="btn-secondary login-btn p-mt-3" onClick={() => props.onHandleBackToCodePass()} />
                </div>
            </div>
            <div className="p-m-0 p-col-12 p-md-6 bg-clrDark800 bg-login">
                <img src="assets/rally/login-bg.png" className="bg-login-img" alt="Sirius Rally" />
            </div>
            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
    );
};

import moment from "moment";

export class Utils {
    static getFormDate() {
        let language = navigator.language || navigator.userLanguage;
        let dateFormat = "MM/DD/YYYY";

        if (language.toLowerCase().indexOf("es") != -1) {
            dateFormat = "DD/MM/YYYY";
        }

        return dateFormat;
    }

    static dateMMMMDoYYYY(val) {
        return val != null ? moment(val).format("MMMM Do, YYYY") : "-";
    }
    static dateDDMMYY(val) {
        return val != null ? moment(val).format(this.getFormDate()) : "/";
    }
    static formatDate(d) {
        if (d) d = new Date(d);
        return d;
    }
    static now() {
        return new Date();
    }
    static validPassword(password) {
        const validPassword = password.match(/^(?=.*[A-Z].*)(?=.*[0-9].*)/);

        if (validPassword) return true;
        return false;
    }
    static getCurrentTimeUTC() {
        var tmLoc = new Date();
        return new Date(tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000);
    }
    // static setLocalTimeFromUTC(date)
    // {
    //     var tmLoc = new Date();
    //     let offs = tmLoc.getTimezoneOffset();
    //     offs += offs * 60000;
    //     return new Date(date - offs);
    // }
    static setLocalTimeFromUTC(date) {
        var tmLoc = new Date();
        let offs = tmLoc.getTimezoneOffset();

        const d = date;
        let h = d.split(":");
        h[0] = "0" + (h[0] - offs);
        h[0] = h[0].substr(h[0].length - 2, 2);
        let t = h.join(":");
        return t;
    }
    static setOutGMT(date) {
        let res = new Date(date);
        res.setHours(res.getHours() - res.getTimezoneOffset() / 60);
        return res.toISOString();
    }

    static exportJsonToCSV = async (JSONData, ReportTitle, ShowLabel) => {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

        var CSV = "";

        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = "";

            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                row += index + ",";
            }

            row = row.slice(0, -1);

            //append Label row with line break
            CSV += row + "\r\n";
        }

        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + "\r\n";
        }

        if (CSV === "") {
            alert("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = "MyReport_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    static downloadJson = async (objectData, ReportTitle) => {
        let filename = ReportTitle + ".json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var a = document.createElement("a");
            a.download = filename;
            a.href = "data:" + contentType + "," + encodeURIComponent(JSON.stringify(objectData));
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    static downloadKml = async (circuits, ReportTitle) => {
        circuits.forEach((circuit) => {
            if (!circuit.CircuitName) return;
            let positions = circuit.Positions.map((item) => `${item.lng},${item.lat}`);
            let stages = circuit.Stages.map((item) => `${item.Name}`);

            let filename = `P.E.${stages.join("_")}.kml`;

            let contentType = "application/xml;";
            let text = `<?xml version="1.0" encoding="utf-8" ?><kml xmlns="http://www.opengis.net/kml/2.2"><Document id="root_doc"><Schema name=""><SimpleField name="tessellate" type="int">1</SimpleField></Schema><Folder><name>P_E_${stages.join(
                "_"
            )}</name><Placemark><Style><LineStyle><color>ff0000ff</color></LineStyle><PolyStyle><fill>0</fill></PolyStyle></Style><name>${circuit.CircuitName}</name><ExtendedData><SchemaData schemaUrl="#P_E_${stages.join(
                "_"
            )}"><SimpleData name="tessellate">1</SimpleData></SchemaData></ExtendedData><LineString><coordinates>${positions.join(" ")}</coordinates></LineString></Placemark></Folder></Document></kml>`;

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(text)))], { type: contentType });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                let element = document.createElement("a");
                element.setAttribute("href", "data:text/xml;charset=utf-8," + encodeURIComponent(text));
                element.setAttribute("download", filename);

                element.style.display = "none";
                document.body.appendChild(element);

                setTimeout(() => {
                    element.click();
                    document.body.removeChild(element);
                }, 500);
            }
        });
    };
}

import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

import classNames from "classnames";
import { $ } from "react-jquery-plugin";

import { UserService } from "../service/UserService";
import { RolesService } from "../service/RolesService";
import { StateService } from "../service/StateService";
import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse } from "../dialogs/ErrorDialog";
import { type_roles } from "../utilities/const";

export const UsersByClub = (props) => {
    let emptyUser = {
        useId: null,
        useName: "",
        useSurname: "",
        useEmail: "",
        usePhone: "",
        //rolId: null,
        rolUseId: null,
        clubId: null,
        stsId: null,

        isPlanner: false,
        isViewer: false,
        isDirector: false,
        //State:"",
        RolesId: [],
    };

    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState(false);
    const [user, setUser] = useState(false);
    const [roles, setRoles] = useState(false);
    const [clubStates, setClubStates] = useState(null);

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
    const [userDialogVisible, setUserDialogVisible] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        const loadAsync = () => {
            try {
                const load = async () => {
                    setLoading(true);

                    const rolesService = new RolesService();
                    const resRoles = await rolesService.getRolesIdDescription();
                    setRoles(resRoles);

                    const stateService = new StateService();
                    const resState = await stateService.getStatesIdDescription(200);
                    setClubStates(resState);

                    const userService = new UserService();
                    const resUsers = await userService.getUsersByClub(props.clubId);
                    setUsers(resUsers);

                    setLoading(false);
                };
                load();
            } catch (error) {
                setStateErrorDialog(ErrorDialogSet(true, ""));
            }
        };
        loadAsync();
    }, []);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    const saveUser = () => {
        setSubmitted(true);

        try {
            if (validateRequired()) {
                let _users = [...users];
                let _user = { ...user };
                _user.clubId = props.clubId;
                if (user.useId) {
                    let userService = new UserService();
                    userService
                        .updateUser(_user)
                        .then((data) => {
                            let index = findIndexById(user.useId);

                            _users[index] = _user;
                            toast.current.show({ severity: "success", summary: "Successful", detail: "User Updated", life: 3000 });
                            closeDialog(_users);
                        })
                        .catch((res) => {
                            var message = "";
                            if (res.response && res.response.data && res.response.data.statusCode === 418) message = res.response.data.message;

                            setStateErrorDialog(ErrorDialogSet(true, message));
                        });
                } else {
                    let userService = new UserService();
                    userService
                        .createUser(_user)
                        .then((data) => {
                            _user.useId = data.useId;
                            _user.stsId = data.stsId;

                            _users.push(_user);
                            toast.current.show({ severity: "success", summary: "Successful", detail: "User Created", life: 3000 });
                            closeDialog(_users);
                        })
                        .catch((res) => {
                            setStateErrorDialog(ErrorDialogSetResponse(res));
                        });
                }
            }
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
            console.log(error.message);
        }
    };
    const deleteUser = () => {
        hideDeleteDialog();
        try {
            let _users = users.filter((val) => val.useId !== user.useId);

            let userService = new UserService();
            userService
                .deleteRoleUser(user.useId)
                .then((data) => {
                    setUsers(_users);
                    setDeleteDialog(false);
                    setUser(emptyUser);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "User Deleted", life: 3000 });
                })
                .catch((res) => {
                    setStateErrorDialog(ErrorDialogSetResponse(res));
                });
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
            console.log(error.message);
        }
    };

    //FUNCIONES DE DIALOGOS
    const openNewUser = () => {
        setUser(emptyUser);
        setUserDialogVisible(true);
    };

    const editUser = (user) => {
        setUser({ ...user });
        setUserDialogVisible(true);
    };
    const userDialogHide = () => {
        setSubmitted(false);
        setUserDialogVisible(false);
    };
    const closeDialog = (_users) => {
        setUsers(_users);
        console.log(_users);
        setUserDialogVisible(false);
        setUser(emptyUser);
    };
    const hideDeleteDialog = () => {
        setDeleteDialog(false);
    };
    const confirmDelete = (user) => {
        setUser(user);
        setDeleteDialog(true);
    };

    // #region AUXILIARES

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    };

    const onCheckboxChange = (e, name) => {
        let _user = { ...user };
        _user[`${name}`] = e.checked;

        // if (e.checked) {
        //     _user[`${name}`] = true;
        // } else _user[`${name}`] = false;

        setUser(_user);
    };

    const validateRequired = () => {
        if (!user.isPlanner && !user.isViewer && !user.isDirector) return false;

        for (let k = 0; k < $("[Required]").length; k++) {
            if (!$("[Required]")[k].value) return false;
        }
        return true;
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].useId === id) {
                index = i;
                break;
            }
        }

        return index;
    };
    const checkedVal = (val) => {
        //var r = val != "0";
        //return r;
        return val;
    };
    // #endregion

    // #region Templatedatatable
    const completeRoles = (eplId, vieId, dirId) => {
        try {
            var retVal = "";
            if (eplId) {
                retVal += type_roles[roles.filter((e) => e.Id === "2")[0].Description] + " ";
            }
            if (vieId) {
                retVal += type_roles[roles.filter((e) => e.Id === "5")[0].Description] + " ";
            }
            if (dirId) {
                retVal += type_roles[roles.filter((e) => e.Id === "4")[0].Description] + " ";
            }

            return retVal;
        } catch (e) {
            return "error";
        }
    };

    const onErrorFind = (e) => {
        if (e) return e.Description;
        else return "error";
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> {rowData.useName + " " + rowData.useSurname}</span>
            </>
        );
    };

    const rolBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> {completeRoles(rowData.isPlanner, rowData.isViewer, rowData.isDirector)}</span>
            </>
        );
    };

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> {rowData.useEmail}</span>
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        let result = onErrorFind(clubStates.find((e) => e.Id === rowData.stsId.toString()));

        if (result === "ENABLE_USER") result = "Enabled";

        return (
            <>
                <span className="p-column-title"> {result}</span>
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-mr-1" onClick={() => editUser(rowData)} />
                <Button icon="pi pi-trash" className="btn-alert" onClick={() => confirmDelete(rowData)} />
            </div>
        );
    };
    // #endregion

    const deleteDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={hideDeleteDialog} />
            <Button label="Delete" className="btn-primary" onClick={deleteUser} />
        </>
    );
    const userDialogEventFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={userDialogHide} />
            <Button label="Register" className="btn-primary" onClick={saveUser} />
        </>
    );

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="p-grid p-ai-center">
                <h2 className="p-m-0 p-col-6 fs-x-normal">Users</h2>
                <div className="p-m-0 p-col-6 p-d-flex p-jc-end">
                    <Button label="New user" icon="pi pi-plus" className="btn-primary " onClick={openNewUser} />
                </div>
            </div>

            <DataTable
                //ref={dt}
                value={users}
                //selection={selectedClubs}
                //onSelectionChange={(e) => setSelectedUsers(e.value)}
                dataKey="useId"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive table-layout-auto"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                //globalFilter={globalFilter}
                emptyMessage="No users found."
                //header={header}
            >
                {/* <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column> */}
                {true && <Column field="useName" header="NAME" sortable body={nameBodyTemplate} />}
                <Column field="Rol" header="ROLES" sortable body={rolBodyTemplate} />
                <Column field="useEmail" header="EMAIL" body={emailBodyTemplate} sortable />
                <Column field="State" header="STATUS" body={statusBodyTemplate} sortable />

                <Column body={actionBodyTemplate} className="is-last" />
            </DataTable>

            <Dialog visible={userDialogVisible} style={{ width: "450px" }} header="User Details" modal className="p-fluid" footer={userDialogEventFooter} onHide={userDialogHide}>
                <div className="p-field">
                    <label htmlFor="firstname">First Name</label>
                    <InputText id="firstname" maxLength="100" value={user.useName} onChange={(e) => onInputChange(e, "useName")} required="true" autoFocus className={classNames({ "p-invalid": submitted && !user.useName })} />
                    {submitted && !user.useName && <small className="p-invalid">First Name is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="lastname">Last Name</label>
                    <InputText id="lastname" maxLength="100" value={user.useSurname} onChange={(e) => onInputChange(e, "useSurname")} required className={classNames({ "p-invalid": submitted && !user.useSurname })} />
                    {submitted && !user.useSurname && <small className="p-invalid">Last Name is required.</small>}
                </div>

                <div className="p-field">
                    <label htmlFor="email">Email</label>
                    <InputText id="email" maxLength="50" value={user.useEmail} onChange={(e) => onInputChange(e, "useEmail")} required="true" className={classNames({ "p-invalid": submitted && !user.useEmail })} />
                    {submitted && !user.useEmail && <small className="p-invalid">Email is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="phone">Phone</label>
                    <InputText id="phone" maxLength="20" value={user.usePhone} onChange={(e) => onInputChange(e, "usePhone")} required className={classNames({ "p-invalid": submitted && !user.usePhone })} />
                    {submitted && !user.usePhone && <small className="p-invalid">Phone is required.</small>}
                </div>

                {/* <div className="p-field">
                            <label htmlFor="rol">Rol</label>
                            <Dropdown id="rol" value={user.rolId} optionLabel="Description" optionValue="Id" options={roles} onChange={(e) => onInputChange(e, "rolId")}
                            required className={classNames({ "p-invalid": submitted && !user.rolId })} />
                            {submitted && !user.rolId && <small className="p-invalid">Rol is required.</small>}
                        </div> */}

                <h5>Roles</h5>
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <div className="p-field-radiobutton">
                            <Checkbox inputId="optionPlanner" name="option" value={user.isPlanner} checked={checkedVal(user.isPlanner)} onChange={(e) => onCheckboxChange(e, "isPlanner")} className={classNames({ "p-invalid": submitted && !user.isPlanner && !user.isViewer && !user.isDirector })} />
                            <label htmlFor="optionPlanner">Event organizer</label>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <div className="p-field-radiobutton">
                            <Checkbox inputId="optionViewer" name="option" value={user.isViewer} checked={checkedVal(user.isViewer)} onChange={(e) => onCheckboxChange(e, "isViewer")} className={classNames({ "p-invalid": submitted && !user.isPlanner && !user.isViewer && !user.isDirector })} />
                            <label htmlFor="optionViewer">Event official</label>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <div className="p-field-radiobutton">
                            <Checkbox
                                inputId="optionDirector"
                                name="option"
                                value={user.isDirector}
                                checked={checkedVal(user.isDirector)}
                                onChange={(e) => onCheckboxChange(e, "isDirector")}
                                className={classNames({ "p-invalid": submitted && !user.isPlanner && !user.isViewer && !user.isDirector })}
                            />
                            <label htmlFor="optionDirector">Clerk of course</label>
                        </div>
                    </div>
                    {submitted && !user.isPlanner && !user.isViewer && !user.isDirector && <small className="p-invalid">Select at least one role is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteDialog} className="p-dialog-small" header="Delete user" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                <div className="confirmation-content">
                    {user && (
                        <span>
                            Are you sure you want to delete <b>{user.useName}</b>?<br />
                            They will no longer be able to access the platform
                        </span>
                    )}
                </div>
            </Dialog>

            {/* <Dialog visible={errorDialog} style={{ width: "450px" }} header="Atention" modal footer={errorDialogFooter} onHide={hideErrorDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            <span>{(errorMessage === "" ? "An error has occurred. Please check error log." : errorMessage)}</span>
                        </div>
                    </Dialog>  */}
            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
    );
};

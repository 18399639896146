import { KEY_ESCAPE } from "keycode-js";
import React from "react";
import { Button } from "primereact/button";
import { Polyline, InfoWindow } from "@react-google-maps/api";

const POLYLINE = "polyline";

export class NewPolyline {
    eventId;
    mapService;
    setPolylineTags;
    setInfoWindowsTags;
    setPoiTags;
    setPoiValues;
    setNewPolylineModal;
    setDetailsNewPolyline;
    detailsNewPolyline;
    setDrawingMode;
    funBack;
    setShape;
    shape;
    polylineStages;
    setErroMessage;
    google;
    showToast;

    polylineFooter = (
        <div>
            <Button label="Cancel" className="btn-secondary" onClick={() => this.setNewPolylineModal(false)} />
            <Button label="Save" className="btn-primary " onClick={() => this.updateDeatilsPolyline()} />
        </div>
    );

    constructor(eventId, mapService, setPolylineTags, setInfoWindowsTags, setPoiTags, setPoiValues, setNewPolylineModal, setDetailsNewPolyline, detailsNewPolyline, setDrawingMode, setShape, shape, polylineStages, setErroMessage, showToast, back) {
        this.eventId = eventId;
        this.mapService = mapService;
        this.setPolylineTags = setPolylineTags;
        this.setInfoWindowsTags = setInfoWindowsTags;
        this.setPoiTags = setPoiTags;
        this.setPoiValues = setPoiValues;
        this.setNewPolylineModal = setNewPolylineModal;
        this.setDetailsNewPolyline = setDetailsNewPolyline;
        this.detailsNewPolyline = detailsNewPolyline;
        this.setDrawingMode = setDrawingMode;
        this.funBack = back;
        this.setShape = setShape;
        this.shape = shape;
        this.polylineStages = polylineStages;
        this.setErroMessage = setErroMessage;
        this.showToast = showToast;
    }

    setGoogle(google) {
        this.google = google;
    }

    initializeNewPolyline() {
        this.setErroMessage("");
        this.insert();
    }

    async updateDeatilsPolyline() {
        this.setDetailsNewPolyline({
            ...this.detailsNewPolyline,
            circuitName: this.detailsNewPolyline.inputPolylineName.current.value,
            flagPosition: this.detailsNewPolyline.flagPosition,
        });

        const paths = this.shape
            .getPath()
            .getArray()
            .map((latLng) => {
                return { lat: latLng.lat(), lng: latLng.lng() };
            });

        var firstPosition;
        var positions = paths.map((pos, i) => {
            if (i == 0) {
                firstPosition = { lat: parseFloat(pos.lat), lng: parseFloat(pos.lng) };
            }
            return new window.google.maps.LatLng(parseFloat(pos.lat), parseFloat(pos.lng));
        });

        const poly = new window.google.maps.Polyline({
            path: positions,
        });

        var lengthInKm = window.google.maps.geometry.spherical.computeLength(poly.getPath()) / 1000;
        var kmWithTwoDecimals = Math.round(lengthInKm * 100) / 100;


        var request = {
            eventId: this.eventId,
            points: paths,
            circuitName: this.detailsNewPolyline.inputPolylineName.current.value,
            stages: this.polylineStages,
            flagPosition: this.detailsNewPolyline.flagPosition,
            kms: kmWithTwoDecimals,
        };

        try {
            this.setErroMessage("");
            var res = await this.mapService.createCircuit(request);
            this.cancel();
            this.funBack();
            this.setNewPolylineModal(false);
            this.showToast(`NEW CIRCUIT`, "The circuit has been created", "success" );

        } catch (error) {
            var message = error.response?.data?.message || "A error has occured";
            this.setErroMessage(message);
            this.setNewPolylineModal(true);
            this.showToast(``, message, "error" );
        }
    }

    back() {
        this.cancel();
    }

    importPolyline(files) {
        try {
            this.setErroMessage("");
            var currFile = files[0];
            this.mapService.importPolyline(currFile, this.eventId);

            //toast.current.show({ severity: "success", summary: "Successful", detail: "The polilyne was imported", life: 3000 });
            //refreshGrid(); //refresco la grilla
        } catch (error) {
            this.setErroMessage("A error has ocurred");
        }
    }

    insert() {
        if (this.shape) this.shape.setMap(null);
        this.setDrawingMode(POLYLINE);
    }

    select() {
        this.setDrawingMode(null);
    }

    cancel() {
        if (this.shape) {
            this.shape.setMap(null);
            this.setDrawingMode(POLYLINE);
            this.shape = null;
        }
    }

    openModal() {
        if (!this.shape?.map) {
            this.showToast(``, "There isn't a polyline", "error" );
            return;
        }
        this.setNewPolylineModal(true);
    }

    handlerClickEvent(e) {}

    handleEscapeKeyDown(e) {
        if (e.keyCode === KEY_ESCAPE) {
            this.setDrawingMode(null);
        }
    }

    drawPolyline(coorninates, circuitName, stagesList, map, setPolylineStages){
       
        let positions = coorninates.map((position, i) => {
            return new window.google.maps.LatLng(parseFloat(position[1]), parseFloat(position[0]));
        });

        const poly = new window.google.maps.Polyline({
            path: positions,
            map: map,
            editable: true,
            draggable: true,
            geodesic: true,
            clickable: true,
            strokeColor: "#d6ff22",
            strokeWeight: 4
            
        });
        this.setDrawingMode(null);
        this.setShape(poly);
        this.setDetailsNewPolyline({
            ...this.detailsNewPolyline,
            circuitName: circuitName,
        })

        let stages = stagesList.map(ss =>  {
            return {
                Name : ss,
                StartDate: null,
                StartTime: null
            }
        });

        setPolylineStages(stages);
    }

    drawingOverlayComplete(e) {
        if (this.shape) this.shape.setMap(null);

        var newShape = e.overlay;
        this.setShape(newShape);

        window.google.maps.event.addListener(newShape, "click", function (e) {
            if (e.vertex !== undefined) {
                var path = newShape.getPath();
                if (path.length > 2) {
                    path.removeAt(e.vertex);
                }
            }
        });
    }
}

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

export const AppProfile = (props) => {
    const [expanded, setExpanded] = useState(false);
    const history = useHistory();

    const onClick = (event) => {

        history.push("/personaldetails");

        setExpanded((prevState) => !prevState);
        event.preventDefault();
    };

    const getInitials = (name) => {
        let initials = name.split(" ");

        if (initials.length > 1) {
            initials = initials.shift().charAt(0) + initials.pop().charAt(0);
        } else {
            initials = name.substring(0, 2);
        }

        return initials.toUpperCase();
    };

    return (
        <div className="layout-profile">
            {(props.avatar === null) && <div className="img-profile">{getInitials(props.userName)}</div>}
            {(props.avatar !== null) && <div className="img-profile"><img src={apiUrl + '/' + props.avatar}></img></div>}
            <button className="p-link layout-profile-link" onClick={onClick}>
                <span className="username ">{props.userName}</span>
                <i className="pi pi-fw pi-cog" />
                <span className="userrol txt-uppercase">{props.rolDescription}</span>
                <span className="userrol txt-uppercase">{props.clubName || "Sirius"}</span>
            </button>
            {/* <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Account</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-power-off" onClick={logout} /><span>Logout</span></button></li>
                </ul>
            </CSSTransition> */}
        </div>
    );
};

import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { $ } from "react-jquery-plugin";

import { ClubService } from "../service/ClubService";
import { EventPlannersService } from "../service/EventPlannersService";
import { CountryService } from "../service/CountryService";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogNoAuthorizedSet, ErrorDialogSetResponse } from "../dialogs/ErrorDialog";

export const ClubDialog = (props) => {
    let emptyClub = {
        cluId: null,
        couId: null,
        cluName: "",
        cluAddress: "",
        cluMail: "",
        cluWeb: "",
        cluSocialNetwork: "",
        cluFacebook: "",
        cluInstagram: "",
        cluLatitude:"",
        cluLongitude:"",
        RepeatEmail: ""
    };
    let emptyPlanner = {
        FirstName: "",
        LastName: "",
        Phone: "",
        Email: "",
        RepeatEmail: ""
    };

    const [visible, setVisible] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [nextView, setNextView] = useState(false);

    const [club, setClub] = useState(emptyClub);
    const [countries, setCountries] = useState(null);
    const [planner, setPlanner] = useState(emptyPlanner);

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const [loading, setLoading] = useState(true);

    const [repearMailError, setRepearMailError] = useState("");
    const [coordenadasError, setCoordenadasError] = useState("");

    useEffect(() => {
        try {
            const load = async () => {
                setLoading(true);
                setSubmitted(false);
                setNextView(false);

                setVisible(props.visibleDialog);

                const countryService = new CountryService();
                const resCountries = await countryService.getCountries();
                setCountries(resCountries);

                let _club = { ...props.club };
                setClub(_club);
                setPlanner(emptyPlanner);
                setLoading(false);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
        }
    }, [props.visibleDialog]);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _club = { ...club };
        _club[`${name}`] = val;

        setClub(_club);
    };
    const onInputChangePlanner = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _planner = { ...planner };
        _planner[`${name}`] = val;

        setPlanner(_planner);
    };

    const onRepeatPlannerEmailBlur = (e, name) => {
        const val = (e.target && e.target.value) || "";
        planner.RepeatEmail = val;
        if (planner.Email == val) {
            setRepearMailError("");
        } else {
            setRepearMailError("This field has to be the same as the Email field.");
        }
    }

    const validateRequired = () => {
        for (let k = 0; k < $("[Required]").length; k++) {
            if (!$("[Required]")[k].value) return false;
        }
        
        if (planner.Email != planner.RepeatEmail) {
            setRepearMailError("This field has to be the same as the Email field.");
            return false;
        }
        return true;
    };
    const validateRequiredFirstView = () => {
        //valido todos los requeridos menos los 4 ultimos, que corresponden a la 2 vista
        for (let k = 0; k < $("[Required]").length - 5; k++) {
            if (!$("[Required]")[k].value) return false;
        }

        if (club.cluLatitude != "" && club.cluLatitude) {
            if (!validateDecimal(club.cluLatitude)) {
                setCoordenadasError("Latitude is not in the correct format. Example format: 34,555667");
                return false;
            }       
            
        if (club.cluLongitude != "" && club.cluLongitude) {
            if (!validateDecimal(club.cluLongitude)) {
                setCoordenadasError("Longitude is not in the correct format. Example format: 34,555667");
                    return false;
                }            
            }
        }
        return true;
    };

    function validateDecimal(valor) {
        var RE = /^-{0,1}\d{1,2}\,[\d]+$/;
        if (RE.test(valor)) {
            return true;
        } else {
            return false;
        }
    }

    const closeCancel = () => {
        setSubmitted(false);
        props.onClose(null);
    };
    const closeOk = (_club) => {
        setSubmitted(false);
        props.onClose(_club);
    };
    const cancel = () => {
        closeCancel();
    };

    const saveClub = async () => {
        setSubmitted(true);

        try {
            let _club = { ...club };
            let valid = (_club.cluId && validateRequiredFirstView()) || (!_club.cluId && validateRequired());

            if (valid) {
                if (_club.cluId) {
                    let clubService = new ClubService();
                    clubService
                        .updateClub(_club)
                        .then(() => {
                            closeOk(_club);
                        })
                        .catch((res) => {
                            setStateErrorDialog(ErrorDialogSetResponse(res));
                        });
                } else {
                    let clubService = new ClubService();

                    clubService
                        .createClub(_club, planner)
                        .then((data) => {
                            _club["cluId"] = data.cluId;
                            _club["Planners"] = planner.FirstName + " " + planner.LastName;
                            //setClub(_club);
                            closeOk(_club);
                        })
                        .catch((res) => {
                            setStateErrorDialog(ErrorDialogSetResponse(res));
                        });
                }
            } else {
                if (!validateRequiredFirstView()) setNextView(false);
            }
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };
    const next = () => {
        setNextView(true);
    };
    const previous = () => {
        setNextView(false);
    };

    const eventDialogFooter = (
        <>
            {!club.cluId && nextView && <Button label="Previous" className="btn-secondary " onClick={previous} />}
            {!nextView && <Button label="Cancel" className="btn-secondary " onClick={cancel} />}

            {!club.cluId && !nextView && <Button label="Next" className="btn-primary " onClick={next} />}
            {!club.cluId && nextView && <Button label="Finish" className="btn-primary " onClick={saveClub} />}
            {club.cluId && <Button label="Save" className="btn-primary " onClick={saveClub} />}
        </>
    );
    return (
        <>
            <Dialog visible={visible} header={!club.cluId ? "Register new institution" : "Edit institution details "} modal className="p-fluid" footer={eventDialogFooter} onHide={closeCancel}>
                <React.Fragment>
                    {!nextView && <p className="txt-clrDark850 step-dialog">Step 1 of 2</p>}
                    {nextView && <p className="txt-clrDark850 step-dialog">Step 2 of 2</p>}
                    <div style={nextView ? { display: "none" } : { display: "block" }}>
                        <div className="p-field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={club.cluName} maxLength="100" onChange={(e) => onInputChange(e, "cluName")} autoFocus required className={classNames({ "p-invalid": submitted && !club.cluName })} />
                            {submitted && !club.cluName && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="country">Country</label>
                                <Dropdown id="country" value={club.couId} optionLabel="couName" optionValue="couId" options={countries} onChange={(e) => onInputChange(e, "couId")} required className={classNames({ "p-invalid": submitted && !club.couId })} />
                                {submitted && !club.couId && <small className="p-invalid">Country is required.</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="state">State/Province</label>
                                <InputText id="state" value={club.cluState} maxLength="100" onChange={(e) => onInputChange(e, "cluState")} required className={classNames({ "p-invalid": submitted && !club.cluState })} />
                                {submitted && !club.cluState && <small className="p-invalid">State is required.</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="city">City</label>
                                <InputText id="city" value={club.cluCity} maxLength="100" onChange={(e) => onInputChange(e, "cluCity")} required className={classNames({ "p-invalid": submitted && !club.cluCity })} />
                                {submitted && !club.cluCity && <small className="p-invalid">City is required.</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="Address">Address</label>
                                <InputText id="cluAddress" value={club.cluAddress} maxLength="100" onChange={(e) => onInputChange(e, "cluAddress")} required className={classNames({ "p-invalid": submitted && !club.cluAddress })} />
                                {submitted && !club.cluAddress && <small className="p-invalid">Address is required.</small>}
                            </div>
                        </div>
                        <div className="p-field">
                            <label htmlFor="cluMail">Mail</label>
                            <InputText id="cluMail" value={club.cluMail} maxLength="100" onChange={(e) => onInputChange(e, "cluMail")} required className={classNames({ "p-invalid": submitted && !club.cluMail })} />
                            {submitted && !club.cluMail && <small className="p-invalid">Mail is required.</small>}
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="web">Web</label>
                                <InputText id="web" value={club.cluWeb} maxLength="100" onChange={(e) => onInputChange(e, "cluWeb")} />
                            </div>

                            <div className="p-field p-col">
                                <label htmlFor="socialNetwork">Social Network</label>
                                <InputText id="socialNetwork" value={club.cluSocialNetwork} maxLength="100" onChange={(e) => onInputChange(e, "cluSocialNetwork")} />
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="facebook">facebook</label>
                                <InputText id="facebook" value={club.cluFacebook} maxLength="100" onChange={(e) => onInputChange(e, "cluFacebook")} />
                            </div>

                            <div className="p-field p-col">
                                <label htmlFor="instragram">Instagram</label>
                                <InputText id="instragram" value={club.cluInstagram} maxLength="100" onChange={(e) => onInputChange(e, "cluInstagram")} />
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="latitude">Default map latitude</label>
                                <InputText id="latitude" value={club.cluLatitude} maxLength="100" onChange={(e) => onInputChange(e, "cluLatitude")}  />
                            </div>

                            <div className="p-field p-col">
                                <label htmlFor="longitude">Default map longitude</label>
                                <InputText id="longitude" value={club.cluLongitude} maxLength="100" onChange={(e) => onInputChange(e, "cluLongitude")}/>
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <small className="p-invalid">{coordenadasError}</small>
                        </div>
                    </div>
                </React.Fragment>

                <React.Fragment>
                    <div style={!nextView ? { display: "none" } : { display: "block" }}>
                        <p className="fs-medium txt-bold p-mb-2">Contact person details</p>
                        <p className="fs-medium p-mb-5">This person is going to be the event organizer and will be able to login with the given email address</p>

                        <div className="p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="contactName">First Name</label>
                                <InputText id="contactName" value={planner.FirstName} maxLength="100" onChange={(e) => onInputChangePlanner(e, "FirstName")} required className={classNames({ "p-invalid": submitted && !planner.FirstName })} />
                                {submitted && !planner.FirstName && <small className="p-invalid">Contact First Name is required.</small>}
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="lastName">Last Name</label>
                                <InputText id="lastName" value={planner.LastName} maxLength="100" onChange={(e) => onInputChangePlanner(e, "LastName")} required className={classNames({ "p-invalid": submitted && !planner.LastName })} />
                                {submitted && !planner.LastName && <small className="p-invalid">Contact Last Name is required.</small>}
                            </div>
                        </div>

                        <div className="p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="contactPhone">Phone</label>
                                <InputText id="contactPhone" value={planner.Phone} maxLength="20" onChange={(e) => onInputChangePlanner(e, "Phone")} required className={classNames({ "p-invalid": submitted && !planner.Phone })} />
                                {submitted && !planner.Phone && <small className="p-invalid">Contact Phone is required.</small>}
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="contactEmail">Email</label>
                                <InputText id="contactEmail" value={planner.Email} maxLength="100" onChange={(e) => onInputChangePlanner(e, "Email")} required className={classNames({ "p-invalid": submitted && !planner.Email })} />
                                {submitted && !planner.Email && <small className="p-invalid">Contact Mail is required.</small>}
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="repeatEmail">Repeat Email</label>
                                <InputText id="repeatEmail"  maxLength="100" onChange={(e) => onRepeatPlannerEmailBlur(e)} required className={classNames({ "p-invalid": submitted && !planner.RepeatEmail })} />
                                <small className="p-invalid">{repearMailError}</small>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Dialog>

            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </>
    );
};

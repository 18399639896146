import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export const ErrorDialogEmpty = {
    visible: false,
    message: [""],
};

export function ErrorDialogSet(visible, message) {
    return {
        visible: visible,
        message: [message],
    };
}

export function ErrorDialogNoAuthorizedSet(visible, error) {
    let message = "";
    if (error.message == "Request failed with status code 401") message = "No Authorized";
    return {
        visible: visible,
        message: [message],
    };
}

export function ErrorDialogSetResponse(res) {
    var message = "";
    if (res.response && res.response.data && res.response.data.statusCode == 418) message = res.response.data.message;

    return {
        visible: true,
        message: [message],
    };
}

export const ErrorDialog = (props) => {
    let empty = {
        visible: false,
        message: [""],
    };

    const [errorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(async () => {
        setErrorDialog(props.stateDialog.visible);
        setErrorMessage(props.stateDialog.message);
    }, [props]);

    const hideErrorDialog = () => {
        setErrorDialog(false);
        props.stateDialog.visible = false;
    };

    const errorDialogFooter = (
        <>
            <Button label="Ok" icon="pi pi-times" className="p-button-text" onClick={hideErrorDialog} />
        </>
    );

    const ListItems = (props) => { 
        let response = props.errorMessage.map((e, index) => {
            return <div key={index}>{e}</div>
        });
        return (<>{response}</>);
    };

    return (
        <Dialog visible={errorDialog} style={{ width: "450px" }} header="Atention" modal footer={errorDialogFooter} onHide={hideErrorDialog}>
            <div className="confirmation-content">
                <div className="p-grid">
                    <div className="p-field p-col-12 p-md-1">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    </div>
                    <div className="p-field p-col-12 p-md-11">
                        <span>
                            {errorMessage[0] == "" &&
                                <>
                                    {"An error has occurred. Please consult with administrator."}
                                </>
                            }
                            {errorMessage[0] != "" &&
                                <ListItems errorMessage={errorMessage} />
                            }
                        </span>                    
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

import { UserService } from "../../service/UserService";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse } from "../../dialogs/ErrorDialog";

export const PasswordRecovery = (props) => {
    const [codePass, setCodePass] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const isValid = () => {
        if (codePass.length !== 4) {
            return false;
        }
        return true;
    };

    const onClickCreateNewPass = () => {
        setSubmitted(true);
        if (!codePass) return null;

        let userService = new UserService();
        userService
            .validatePassCode(props.mail, codePass)
            .then(() => {
                setSubmitted(true);
                if (codePass && isValid()) {
                    props.onHandleCreateNewPass();
                }
            })
            .catch((error) => {
                let errormessage = "Invalid Code";
                if (error.response) {
                    errormessage = error.response.data.message;
                }
                setStateErrorDialog(ErrorDialogSet(true, errormessage));
            });
    };

    const mailAsterisked = () => {
        let str1 = props.mail.split("@")[0];
        str1 = str1.slice(0, str1.length / 2) + "*".repeat(str1.length / 2);

        let str2 = props.mail.split("@")[1];
        str2 = "*".repeat(str2.length / 2) + str2.slice(str2.length / 2, str2.length);

        return str1 + "@" + str2;
    };

    return (
        <div className="login-page">
            <div className="p-m-0 p-col-12 p-md-6 p-d-flex p-jc-center p-ai-center bg-clrLight300">
                <div className="form-login">
                    <h2 className="fs-big p-mb-3">Password recovery</h2>
                    <p className=" p-mb-4 fs-medium">{"Please enter the code that has been sent to " + mailAsterisked() + " in order to restore your password."} </p>

                    <div className="p-field p-mb-6 ">
                        <label htmlFor="codePass" className="p-d-block">
                            Code
                        </label>
                        <InputText type="text" id="codePass" className="p-d-block" placeholder="" maxLength={4} minlength={4} value={codePass} onChange={(e) => setCodePass(e.target.value)} />
                        {/* <InputNumber integeronly id="codePass" className="p-d-block" placeholder="" maxLength={4} minlength={4} value={codePass} onChange={(e) => setCodePass(e.target.value)} /> */}
                        {submitted && !codePass && <small className="p-invalid">Code is required.</small>}
                        {submitted && !isValid() && codePass && <small className="p-invalid">The code is 4 characters long.</small>}
                    </div>

                    <Button label="Restore password" className="btn-primary  login-btn" onClick={onClickCreateNewPass} />
                    <Button label="Resend code" className="btn-primary  login-btn p-mt-3" onClick={() => props.onHandleBackToForgotPassword()} />
                    <Button label="Cancel" className="btn-seconady  login-btn p-mt-3" onClick={() => props.onHandleBackToForgotPassword()} />
                </div>
            </div>
            <div className="p-m-0 p-col-12 p-md-6 bg-clrDark800 bg-login">
                <img src="assets/rally/login-bg.png" className="bg-login-img" alt="Sirius Rally" />
            </div>
            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </div>
    );
};

export class DrawRectangle {
    setDrawingMode

    constructor(setDrawingMode){
        this.setDrawingMode = setDrawingMode;
    }

    setRectangleMode(){
        this.setDrawingMode(window.google.maps.drawing.OverlayType.RECTANGLE);
    }

    drawingOverlayComplete(e,drivers, toggleBounce) {
        var rectangle = e.overlay;

        drivers.forEach(driver => {
            var point = new window.google.maps.LatLng(parseFloat(driver.position.lat), parseFloat(driver.position.lng));
            var isInside = rectangle.getBounds().contains(point);
            if (isInside) {
                toggleBounce(driver,drivers);
            }
        });

        rectangle.setMap(null);
    };

}
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { UserService } from "../../service/UserService";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse } from "../../dialogs/ErrorDialog";

export const ForgotPassword = (props) => {
    const [username, setUsername] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    const isValid = () => {
        if (username.length < 8) {
            return false;
        }
        return true;
    };

    const onClickCodePass = () => {
        let userService = new UserService();
        setSubmitted(true);
        if (!username) return null;
        userService
            .sendPassCodeToEmail(username)
            .then(() => {
                setSubmitted(true);
                if (username && isValid()) {
                    props.onHandleCodePass(username);
                }
            })
            .catch((error) => {
                let errormessage = "Invalid mail";
                if (error.response) {
                    errormessage = error.response.data.message;
                }
                setStateErrorDialog(ErrorDialogSet(true, errormessage));
            });
    };

    return (
        <>
            <div className="login-page">
                <div className="p-m-0 p-col-12 p-md-6 p-d-flex p-jc-center p-ai-center bg-clrDark800">
                    <div className="form-login">
                        <h2 className="fs-big p-mb-3">Password recovery</h2>
                        <p className=" p-mb-3 fs-medium">We will send a code to your email in order to restore your password. </p>

                        <div className="p-field p-mb-6 ">
                            <InputText type="text" id="inputgroup" className="p-d-block" maxLength={50} minlength={5} placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
                            {submitted && !username && <small className="p-invalid">Email is required.</small>}
                            {submitted && !isValid() && username && <small className="p-invalid">Your email must be at least 8 characters.</small>}
                        </div>

                        <Button label="Send code" className="btn-primary login-btn" onClick={onClickCodePass} />
                        <Button label="Cancel" className="btn-secondary login-btn p-mt-3" onClick={() => props.onHandleBackToLogin()} />
                    </div>
                </div>
                <div className="p-m-0 p-col-12 p-md-6 bg-clrDark800 bg-login">
                    <img src="assets/rally/login-bg.png" className="bg-login-img" alt="Sirius Rally" />
                </div>
            </div>
            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </>
    );
};

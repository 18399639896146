import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { ClubService } from "../service/ClubService";
import { LicenseService } from "../service/LicenseService";
import { EventService } from "../service/EventService";

import { EventsCurrents } from "./EventsCurrents";
import { UsersByClub } from "./UsersByClub";
import { ClubDialog } from "../dialogs/ClubDialog";
import { LicensingHistoryDialog } from "../dialogs/LicensingHistoryDialog";
import { LicenseDialog } from "../dialogs/LicenseDialog";
import { TokenAuthService } from "../service/TokenAuthService";

import { HeaderPageDetail } from "../components/headers/HeaderPageDetail";

import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogNoAuthorizedSet, ErrorDialogSetResponse } from "../dialogs/ErrorDialog";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Utils } from "../utilities/Utils";

export const Club = () => {
    let emptyClub = {
        cluId: null,
        couId: null,
        cluName: "",
        cluAddress: "",
        cluContactName: "",
        cluContactPhone: "",
        cluMail: "",
        cluWeb: "",
        cluSocialNetwork: "",
        cluFacebook: "",
        cluInstagram: "",
    };

    let emptyLicense = {
        LicId: null,
        ClubId: null,
        Total: 0,
        Given: 0,
        From: null,
        To: null,
        Events: 0,
    };

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);
    const [loading, setLoading] = useState(true);

    const [club, setClub] = useState(emptyClub);
    const [license, setLicense] = useState(emptyLicense);
    const [events, setEvents] = useState(null);

    const [clubDialog, setClubDialog] = useState(false);
    const [deleteClubDialog, setDeleteClubDialog] = useState(false);
    const [showLicensingHistoryDialog, setShowLicensingHistoryDialog] = useState(false);
    const [licenseDialog, setLicenseDialog] = useState(false);
    const [currentLicenseVisible, setCurrentLicenseVisible] = useState(false);
    const [requestLicensesDialog, setRequestLicensesDialog] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const toast = useRef(null);
    const params = useParams();
    const history = useHistory();

    // Se ejecuta luego del render
    useEffect(() => {
        var res = null;
        try {
            const load = async () => {
                setLoading(true);

                const eventsService = new EventService();
                const resEvents = await eventsService.getByClub(params.id);

                for (let i = 0; i < resEvents.length; i++) {
                    var start = new Date(resEvents[i].Start);
                    var finish = new Date(resEvents[i].Finish);

                    let startDate = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
                    let finishDate = new Date(finish.getUTCFullYear(), finish.getUTCMonth(), finish.getUTCDate());

                    resEvents[i].Start = startDate;
                    resEvents[i].Finish = finishDate;
                }

                setEvents(resEvents);
                res = resEvents;

                const licenseService = new LicenseService();
                const resLicenses = await licenseService.getCurrent(params.id);

                for (let i = 0; i < resLicenses.length; i++) {
                    var start = new Date(resLicenses[i].From);
                    var finish = new Date(resLicenses[i].To);

                    let startDate = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
                    let finishDate = new Date(finish.getUTCFullYear(), finish.getUTCMonth(), finish.getUTCDate());

                    resLicenses[i].From = startDate;
                    resLicenses[i].To = finishDate;
                }

                if (resLicenses && resLicenses.length > 0) {
                    let _license = { ...resLicenses[0] };
                    _license.ClubId = params.id; //revisar si es necesario en algun caso
                    setLicense(_license);
                    setCurrentLicenseVisible(true);
                } else {
                    let _license = { ...emptyLicense };
                    _license.ClubId = params.id; //Esta linea va para cuando todavía no tiene licensias
                    setLicense(_license);
                    setCurrentLicenseVisible(false);
                }

                const auth = new TokenAuthService();
                setIsAdmin(auth.isAdmin());

                const clubService = new ClubService();
                const resClub = await clubService.getClub(params.id);
                setClub(resClub);

                setLoading(false);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
        }
    }, []);

    const setNewLicense = (_license) => {
        _license.ClubId = params.id;
        setLicense(_license);
        hideHistoryDialog();
        openManagingDialogLicenses();
    };

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    const editClub = () => {
        try {
            const load = async () => {
                let clubService = new ClubService();
                let _club = await clubService.getClub(params.id);
                setClub(_club);
                setClubDialog(true);
            };
            load();
        } catch (error) {
            setStateErrorDialog(ErrorDialogNoAuthorizedSet(true, error));
        }
    };

    // #region BORRADO
    const confirmDeleteSelected = () => {
        setDeleteClubDialog(true);
    };

    const deleteClub = async () => {
        hideDeleteClubDialog();
        try {
            let clubService = new ClubService();

            clubService
                .deleteClub(club.cluId)
                .then((data) => {
                    setDeleteClubDialog(false);
                    setClub(emptyClub);
                    history.push("/clubs/" + "Institution Deleted");
                })
                .catch((res) => {
                    setStateErrorDialog(ErrorDialogSetResponse(res));
                });
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };

    const hideDeleteClubDialog = () => {
        setDeleteClubDialog(false);
    };
    // #endregion

    // #region Edicion
    const hideDialog = (_club1) => {
        if (_club1 != null) {
            setClub(_club1);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Institutions Updated", life: 3000 });
        }
        setClubDialog(false);
    };
    // #endregion

    const openHistoryDialog = () => {
        setShowLicensingHistoryDialog(true);
    };

    const hideHistoryDialog = () => {
        setShowLicensingHistoryDialog(false);
    };

    const hiderequestLicensesDialog = () => {
        setRequestLicensesDialog(false);
    };
    const openRequestLicensesDialogLicenses = () => {
        setRequestLicensesDialog(true);
    };

    const openManagingDialogLicenses = () => {
        if (isAdmin) setLicenseDialog(true);
        else setRequestLicensesDialog(true);
    };
    const hideManagingDialogLicenses = async (_license) => {
        if (_license != null) {
            setLicense(_license);
            if (currentLicenseVisible) toast.current.show({ severity: "success", summary: "Successful", detail: "License Updated", life: 3000 });
            else toast.current.show({ severity: "success", summary: "Successful", detail: "License Created", life: 3000 });

            const licenseService = new LicenseService();
            const resLicenses = await licenseService.getCurrent(params.id);
            const existsActiveLic = resLicenses != null && resLicenses.length > 0;
            if (existsActiveLic) setLicense(_license);
            else {
                let l = emptyLicense;
                l.ClubId = params.id;
                setLicense(l);
            }

            setCurrentLicenseVisible(existsActiveLic);
        }
        setLicenseDialog(false);
    };

    const deleteClubDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary" onClick={hideDeleteClubDialog} />
            <Button label="Delete" className="btn-primary" onClick={deleteClub} />
        </>
    );

    const requestLicensesDialogFooter = (
        <>
            <Button label="Continue" className="btn-secondary" onClick={hiderequestLicensesDialog} />
        </>
    );

    //Header Pages Templates Parts
    const leftColumnTemplate = () => {
        // return <div className="p-m-0 p-col-4">BACK</div>;
        return (
            <div className="p-m-0 p-col-4">
                <Button label="Back" icon="pi pi-chevron-left" onClick={() => history.goBack()} className="p-button-text btn-back " />
            </div>
        );
    };

    const rightColumnTemplate = () => {
        return (
            <div className="p-m-0 p-col-8 p-d-flex p-jc-end">
                <Button label="Delete institution" icon="pi pi-trash" className="btn-secondary p-mr-2" onClick={confirmDeleteSelected}></Button>
                <Button label="Edit details" icon="pi pi-pencil" className="btn-secondary" onClick={editClub}></Button>
            </div>
        );
    };

    const fullColumnTemplate = () => {
        return (
            <>
                <div className="p-mb-3 p-col-12 p-d-flex ">
                    <div className="header-club-img p-mr-3">
                        <img src="assets/rally/club-img.png" alt={club.cluName} />
                    </div>
                    <div>
                        <h1 className="fs-x-big p-mb-2">{club.cluName}</h1>
                        <a href={"https://" + club.cluWeb} target="_blank" className="p-button p-button-text">
                            {club.cluWeb}
                        </a>
                    </div>
                </div>
                <div className="p-m-0 p-col-12">
                    <ul className="header-club-list">
                        <li className="list-item">
                            <i className="pi pi-map-marker"></i> Address: {club.cluAddress}
                        </li>
                    </ul>
                </div>
                <div className="p-m-0 p-col-12">
                    <ul className="header-club-list">
                        <li className="list-item">
                            <i className="icon-arroba"></i> Mail: {club.cluMail}
                        </li>
                        <li className="list-item">
                            <i className="icon-facebook"></i> Facebook: {club.cluFacebook}
                        </li>

                        <li className="list-item">
                            <i className="icon-instagram"></i> Instagram {club.cluInstagram}
                        </li>
                    </ul>
                </div>
            </>
        );
    };

    return (
        <div className="p-grid ">
            <div className="p-col-12">
                <div className="inner-container">
                    <Toast ref={toast} />
                    {isAdmin && <HeaderPageDetail headerStyles="p-mb-5 header-club-detail p-ai-start" leftColumn={leftColumnTemplate()} rightColumn={rightColumnTemplate()} fullColumn={fullColumnTemplate()} />}
                    {!isAdmin && <UsersByClub clubId={params.id}></UsersByClub>} {/* si es admin se ve abajo, esta repetido el componente */}
                    {currentLicenseVisible && (
                        <div className="card">
                            <div className="p-grid">
                                <h2 className="p-m-0 p-col-6 fs-x-normal">Licenses</h2>
                                <div className="p-m-0 p-col-6 p-d-flex p-jc-end">
                                    <Link className=" txt-clrDark800" onClick={openHistoryDialog}></Link>
                                    <Button label="View Licensing History" className="p-button-text p-mr-3" onClick={openHistoryDialog} />
                                    {isAdmin && <Button label="Managing Licenses" icon="pi pi-pencil" className="btn-secondary" onClick={openManagingDialogLicenses} />}
                                    {!isAdmin && <Button label="Request more Licenses" icon="pi pi-pencil" className="btn-secondary" onClick={openRequestLicensesDialogLicenses} />}
                                </div>

                                <div className="p-mt-4 p-col-12 p-d-flex p-jc-around  licences-container">
                                    <div className="licences-box">
                                        <p className="fs-super-big txt-bold txt-clrLight240">
                                            <span className="txt-clrLight200">{license.Given}</span>/{license.Total}
                                        </p>
                                        <p className="fs-medium txt-clrLight270">Total licenses given</p>
                                    </div>

                                    <div className="licences-box">
                                        <p className="fs-x-normal txt-bold">
                                            From {Utils.dateMMMMDoYYYY(license.From)} <br /> to {Utils.dateMMMMDoYYYY(license.To)}{" "}
                                        </p>
                                        {/* <p className="fs-x-normal txt-bold">From February 19th, 2020 to September 19th, 2020</p> */}
                                        <p className="fs-medium txt-clrLight270">Lincensed period</p>
                                    </div>
                                    <div className="licences-box">
                                        <p className="fs-super-big txt-bold txt-clrLight200">{license.Events}</p>
                                        <p className="fs-medium txt-clrLight270">Events created during period</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        // <div className="card" style={{ backgroundColor: "gray" }}>
                        //     <div className="p-grid">
                        //         <h1 className="p-m-0 p-col-8">Licenses</h1>

                        //         <Link className="p-m-0 p-col-2" onClick={openHistoryDialog}>
                        //             View Licensing History
                        //         </Link>
                        //         <Button label="Managing Licenses" icon="pi pi-check" className="p-button-text p-m-0 p-col-2" onClick={openManagingDialogLicenses} />
                        //         <h1 className="p-m-0 p-col-4">
                        //             {license.Given}/{license.Total}
                        //         </h1>

                        //         <h1 className="p-m-0 p-col-4">From {Utils.dateMMMMDoYYYY(license.From) + " to " + Utils.dateMMMMDoYYYY(license.To)}</h1>
                        //         <h1 className="p-m-0 p-col-4">{license.Events}</h1>
                        //         <h6 className="p-m-0 p-col-4">Total licenses given</h6>
                        //         <h6 className="p-m-0 p-col-4">Lincensed period</h6>
                        //         <h6 className="p-m-0 p-col-4">Events created during period..</h6>
                        //     </div>
                        // </div>
                    )}
                    {!currentLicenseVisible && (
                        <div className="licences-container p-mb-3 p-d-flex p-flex-column p-jc-center p-ai-center ">
                            <div className="p-m-0 p-col-12 p-d-flex p-jc-end">
                                <Button label="View Licensing History" className="p-button-text p-mr-3" onClick={openHistoryDialog} />
                            </div>
                            <i className="pi pi-info-circle txt-clrDark500 p-mb-2" style={{ fontSize: "1rem" }}></i>
                            <p className="p-mb-4 fs-small txt-clrDark500">No licenses nor licensing plan has been added!</p>
                            <div className="p-m-0 ">
                                <Button label="Add licensing information" icon="pi pi-plus" className="btn-primary " onClick={openManagingDialogLicenses} />
                            </div>
                        </div>
                    )}
                    {/* <div className="card">
                        <UsersByClub clubId={params.id}></UsersByClub>
                    </div> */}
                    {/* Users */}
                    {isAdmin && <UsersByClub clubId={params.id}></UsersByClub>} {/* si no es admin se ve arriba, esta repetido el componente */}
                    {/* Eventos */}
                    {isAdmin && events.length === 0 && (
                        <>
                            <div className="p-grid p-ai-center">{/* <h2 className="p-m-0 p-col-6 fs-x-normal">Events</h2> */}</div>
                            <div className="licences-container p-mb-3 p-d-flex p-flex-column p-jc-center p-ai-center ">
                                <i className="pi pi-info-circle txt-clrDark850 p-mb-2" style={{ fontSize: "1rem" }}></i>
                                <p className="p-mb-4 fs-small txt-clrDark850">The institution has not created any event yet</p>
                            </div>
                        </>
                    )}
                    {isAdmin && events.length !== 0 && <EventsCurrents events={events}></EventsCurrents>}
                    {/* Modal Edit Details */}
                    <ClubDialog visibleDialog={clubDialog} club={club} onClose={hideDialog}></ClubDialog>
                    {/* Modal Delete Institution */}
                    <Dialog visible={deleteClubDialog} className="p-dialog-small" header="Delete institution" modal footer={deleteClubDialogFooter} onHide={hideDeleteClubDialog}>
                        <div className="confirmation-content">
                            {club && (
                                <span>
                                    {/*  Are you sure you want to delete <b>{club.name}</b>? */}
                                    Are you sure you want to delete this institution? <br />
                                    They will no longer be able to access the platform
                                </span>
                            )}
                        </div>
                    </Dialog>
                    <LicensingHistoryDialog visible={showLicensingHistoryDialog} clubId={club.cluId} onClose={hideHistoryDialog} setLicense={setNewLicense} isAdmin={isAdmin}></LicensingHistoryDialog>
                    <LicenseDialog visibleDialog={licenseDialog} license={license} onClose={hideManagingDialogLicenses}></LicenseDialog>
                    <Dialog visible={requestLicensesDialog} className="p-dialog-small" header="You have reached your licenses limit" modal footer={requestLicensesDialogFooter} onHide={hiderequestLicensesDialog}>
                        Please contact info@spectra-tec.com to request more licences.
                    </Dialog>
                    <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
                </div>
            </div>
        </div>
    );
};

import { Dialog } from "primereact/dialog";
//import Select from "react-select";
import Select, { components } from "react-select";
import { POI_VALUES, SVG } from "../../consts/icons";

export const NewPoiModal = (props) => {
    const options = [
        { value: POI_VALUES.LOCATION, label: "Common", icon: "icon-location-on" },
        { value: POI_VALUES.GAS_STATION, label: "Refueling", icon: "icon-gas-station" },
        { value: POI_VALUES.FIRE, label: "Service park", icon: "icon-service-park" },
        { value: POI_VALUES.RADIO, label: "Radio", icon: "icon-radio" },
        { value: POI_VALUES.HOSPITAL, label: "Medic", icon: "icon-medic" },
        { value: POI_VALUES.FLAG, label: "Start Flag", icon: "icon-start" },
        { value: POI_VALUES.TIME, label: "Start Time", icon: "icon-time" },
        { value: POI_VALUES.FINISH, label: "Finish Flag", icon: "icon-finish" },
    ];

    const changeIcon = (value, poiDetails, setPoiDetails) => {
        setPoiDetails({ ...props.poiDetails, icon: value });
    };

    //Icons en Options
    const { Option } = components;

    const IconOption = (props) => {
        if (props.data.value === POI_VALUES.HOSPITAL || props.data.value === POI_VALUES.RADIO){
            let svg = SVG.find(item => item.Description === props.data.value)
            if(!svg) return null
            return (
                <Option {...props}>
                    <object data={svg.Path} alt="Sirius Rally" style={{margin:'0px 7px -7px -4px', scale:'90%'}} />
                    {props.data.label}
                </Option>
            );
        }
        return <Option {...props}>
            <i className={props.data.icon} style={{ marginRight: "10px"}}></i>
            {props.data.label}
        </Option>;
    };

    return (
        <Dialog header="POI details" className="p-fluid" contentClassName="overflow-y-visible" visible={props.poiModalState} footer={props.newPoi.poiFooter} onHide={() => props.setPoiModalState(false)}>
            <div className="p-grid" style={{ paddingBottom: 10 }}>
                <div className="p-field p-col-6">
                    <label htmlFor="segment" className="p-d-block">
                        POI Name
                    </label>
                    <input type="text" id="segment" className="p-inputtext p-component" placeholder="POI Name" defaultValue={props.poiDetails.description} ref={props.poiDetails.descriptionInput} />
                </div>

                <div className="p-field p-col-6">
                    <label htmlFor="icon" className="p-d-block">
                        Icon
                    </label>

                    <Select
                        id="icon"
                        options={options}
                        //value={dateOptions.find(x => x.value == stage.StartDate)}
                        components={{ Option: IconOption }}
                        onChange={(e) => {
                            changeIcon(e.value, props.poiDetails, props.setPoiDetails);
                        }}
                        className={"pois-icons"}
                    />
                </div>

                <div className="p-field p-col-6">
                    <label htmlFor="longitude" className="p-d-block">
                        Longitude
                    </label>
                    <input type="text" id="longitude" className="p-inputtext p-component" placeholder="Latitud" defaultValue={props.poiDetails.lat} ref={props.poiDetails.latInput} />
                </div>
                <div className="p-field p-col-6">
                    <label htmlFor="latitude" className="p-d-block">
                        Latitude
                    </label>
                    <input type="text" id="latitude" className="p-inputtext p-component" placeholder="Longitud" defaultValue={props.poiDetails.lng} ref={props.poiDetails.lngInput} />
                </div>
            </div>
        </Dialog>
    );
};

import React, { createRef, useRef, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Select from "react-select";
import { Flag_Codes } from "../../utilities/const";

var messageRef = createRef();

export const SendMessageDriverModal = (props) => {
    // const [enableCustomMessage, setEnableCustomMessage] = useState(false);
    // const [selectedMesssageId, setSelectedMesssageId] = useState(0);

    // const options = [
    //     { value: "800", label: "RED FLAG" },
    //     { value: "801", label: "GREEN FLAG" },
    //     { value: "802", label: "CUSTOM MESSAGE" },
    // ];
    const CUSTOM_FLAG_CODE = Flag_Codes["CUSTOM_FLAG"];

    var footer = (
        <div>
            <Button label="Cancel" className="btn-secondary" onClick={() => props.setModalState(false)} />
            <Button label="Send" className="btn-primary " onClick={(e) => sendMessage()} />
        </div>
    );

    const sendMessage = () => {
        var message = messageRef.current?.value || "";

        /*if (message == "") {
            alert("You must enter a message");
            return;
        }*/

        // if (selectedMesssageId == 0) {
        //     alert("You must choose a type of message.");
        //     return;
        // }

        props.sendMessage(CUSTOM_FLAG_CODE, message);
    };

    // const changeMessage = (value) => {
    //     if (value == "802") {
    //         setEnableCustomMessage(true);
    //     } else {
    //         setEnableCustomMessage(false);
    //     }
    //     setSelectedMesssageId(value);
    // };

    return (
        <Dialog header="Send a Message" className="p-fluid" visible={props.modalState} contentClassName="overflow-y-visible" footer={footer} onHide={() => props.setModalState(false)}>
            <div className="p-grid">
                {/* <div className="p-field p-col-12">
                    <label>Select Message</label>
                    <Select
                        id="message"
                        options={options}
                        onChange={(e) => {
                            changeMessage(e.value);
                        }}
                    />
                </div> */}

                <div className="p-field p-col-12">
                    <input type="text" id="custoMessage" placeholder="Write a message" ref={messageRef} maxLength={100} defaultValue={""} className="p-inputtext p-component" />
                </div>
            </div>
        </Dialog>
    );
};

import axios from "../utilities/api-axios"

const apiUrl = process.env.REACT_APP_API_URL;

export class LicenseService {

    async getLicenseByDate(license){
        let from = license.From ? license.From.valueOf():"";
        let to = license.To ? license.To.valueOf():"";
        return axios.get(`${apiUrl}/licenses/byDate/${license.ClubId}?from=${from}&to=${to}`).then((res) => res.data);
    }

    getById(id) {
        return axios.get(`${apiUrl}/licenses/`+ id).then((res) => res.data);
    }

    async getAll() {
        return axios.get(`${apiUrl}/licenses/`).then((res) => res.data);
    }

    getByClub(clubId) {
        return axios.get(`${apiUrl}/licenses/byClub/`+ clubId).then((res) => res.data);
    }

    getCurrent(clubId) {
        return axios.get(`${apiUrl}/licenses/Current/`+ clubId).then((res) => res.data);
    }

    updateLicense(license) {
        const updItem = {
                "ClubId": license.ClubId,
                "Total": license.Total,
                "Given": license.Given,
                "From": license.From,
                "To": license.To
        }
        return axios.put(`${apiUrl}/licenses/` + license.LicId, updItem).then((res) => res.data);
    }

    createLicense(license) {

        const newLicense = {
            "ClubId": license.ClubId,
            "Total": license.Total,
            "Given": license.Given,
            "From": license.From,
            "To": license.To
        }
        return axios.post(`${apiUrl}/licenses/`, newLicense).then((res) => {
            return res.data;
        });
    }

    // deleteEvent(id) {
    //     return axios.delete(`${apiUrl}/events/` + id, { _method: "DELETE" }).then((res) => {
    //         return res.data;
    //     });
    // }


}

import { Button } from "primereact/button";
import moment, { min } from "moment";
import React, { useEffect, useState, useRef } from "react";

const CLEAR_BTN = "Clear Flags";

export const TrackerMenu = (props) => {
    //const [timer, setTimer] = useState(moment().utc().format("HH:mm:ss"));
    const [timer, setTimer] = useState(moment().format("HH:mm:ss"));
    const [statesText, setStatesText] = useState("");

    const sendMessage = () => {
        props.sendMessage();
    };

    const sendRedFlag = () => {
        props.sendRedFlag();
    };

    useEffect(async () => {
        var stages = "";
        if (props.route.Stages?.length > 0) {
            props.route?.Stages.forEach((item) => {
                stages = `${stages} / SS ${item.Name}`;
            });
            setStatesText(stages.substring(2));
        }
    }, [props.route, setStatesText]);

    useEffect(async () => {
        let gmt = props.gmt;
        if (!gmt) return;
        var id = setInterval(() => {
            //setTimer(moment().utc().add(gmt.GMT, "hours").format("HH:mm:ss"));
            setTimer(moment().format("HH:mm:ss"));
        }, 1000);
        return () => {
            clearInterval(id);
        };
    }, [setTimer, props.gmt]);

    return (
        <>
            {props.showTrackerMenu && (
                <div className="menu-edit-map p-jc-between">
                    <div className="p-d-flex p-ai-center">
                        <Button icon="pi pi-chevron-left" className="p-button-rounded  p-button-outlined p-button-icon-only p-mr-3" onClick={props.back} />
                        {statesText} &nbsp;
                        <Button label={props.poiHideShowText} className="btn-outline-invert  p-mr-2" onClick={() => props.hidePois()} />
                        {props.isDirector && (
                            <>
                                <Button label={CLEAR_BTN} className="btn-outline-invert  p-mr-2" onClick={() => props.cleanFlags()} />
                                <Button label={""} className="btn-outline-invert" style={{ minWidth: "0rem" }} onClick={() => props.enableRectangle()}>
                                    <img src="select-icon.svg" />
                                </Button>

                                {props.showMenu && (
                                    <>
                                        <Button label={""} className="btn-outline-invert" style={{ minWidth: "0rem", marginLeft: "5px" }} onClick={() => props.clearSelectedDrivers()}>
                                            <div className={"pi pi-fw pi-times"} style={{ cursor: "pointer" }}></div>
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </div>

                    <div className="p-d-flex p-ai-center">
                        {props.isDirector && (
                            <>
                                <Button
                                    label="Send Message"
                                    className="btn-outline-invert p-mr-2"
                                    onClick={() => {
                                        sendMessage();
                                    }}
                                />
                                <Button
                                    label="Red Flag"
                                    className="btn-outline-invert p-mr-2"
                                    onClick={() => {
                                        sendRedFlag();
                                    }}
                                />
                            </>
                        )}
                        <span className="timer">{timer}</span>
                    </div>
                </div>
            )}
        </>
    );
};
